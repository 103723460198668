import React, { useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { useTranslation } from 'react-i18next';

import general from '../styles/general.module.scss';

type ImageUploadModalProps = {
    isVisible: boolean;
    onClose: () => void;
    onUpload: (url: string, width: string, height: string) => void;
};

const ImageUploadModal: React.FC<ImageUploadModalProps> = ({ isVisible, onClose, onUpload }) => {
    const { t } = useTranslation();
    const [width, setWidth] = useState(30);
    const [height, setHeight] = useState(30);

    const handleFileUpload = (event: any) => {
        const file = event.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            const url = reader.result as string;
            onUpload(url, `${width}vw`, `${height}vw`);
            handleClose();
        };
        reader.readAsDataURL(file);
    };

    if (!isVisible) {
        return null;
    }

    const handleClose = () => {
        setWidth(30);
        setHeight(30);
        onClose();
    };

    const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = Number(e.target.value);

        if (value >= 1 && value <= 100) {
            setWidth(+e.target.value);
        } else if (value < 1) {
            setWidth(1);
        } else setWidth(100);
    };

    const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = Number(e.target.value);

        if (value >= 1 && value <= 100) {
            setHeight(+e.target.value);
        } else if (value < 1) {
            setHeight(1);
        } else setHeight(100);
    };

    return (
        <div className={general.modal}>
            <div className={general.modal_content}>
                <span className={general.close_button} onClick={handleClose}>
                    &times;
                </span>
                <h2>{t('editor.imageModal.title')}</h2>
                <div className={general.FileUploadBlock}>
                    <FileUpload
                        name="image"
                        accept="image/*"
                        customUpload
                        uploadHandler={handleFileUpload}
                        chooseLabel={t('editor.fileUpload.button')}
                        mode={'advanced'}
                        emptyTemplate={<div className={general.drag_and_drop}>{t('editor.fileUpload.DD')}</div>}
                        auto
                    />
                </div>
                <div className={general.size_options}>
                    <label>
                        {t('editor.width')}:
                        <input type="number" value={width} onChange={handleWidthChange} />
                    </label>
                    <label>
                        {t('editor.height')}:
                        <input type="number" value={height} onChange={handleHeightChange} />
                    </label>
                </div>
            </div>
        </div>
    );
};

export default ImageUploadModal;
