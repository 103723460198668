import classNames from 'classnames';
import { useUserDetail } from '@modules/AdminPage/Users/UserDetail/useUserDetail';
import { InputText } from 'primereact/inputtext';
import { userFields } from '@modules/AdminPage/Users/const';
import { format } from 'date-fns';
import { PageLoader } from '@libs/components/loader/PageLoader';
import { DataTable } from 'primereact/datatable';
import { Column, type ColumnBodyOptions } from 'primereact/column';
import { ConfirmModal, PaginationController } from '@libs/components';
import { subscriptionColumns } from '@modules/AdminPage/Users/UserDetail/const';
import { type SubscriptionInfo } from '@modules/AdminPage/Subscriptions/services/types';
import { type UserInfo, UserStatus } from '@modules/AdminPage/Users/services/type';
import { HasAccess } from '@libs/components/access';
import { usePermissions } from '@libs/hooks';
import { Dropdown } from 'primereact/dropdown';
import { BackArrow } from '@libs/components/pageBlocks/Navigation/BackArrow';

import styles from './styles.module.scss';

const getDate = (date: string) => date && format(new Date(date), 'dd.MM.yyyy');

const bodyTemplate = (data: SubscriptionInfo, options: ColumnBodyOptions) => {
    switch (options.field) {
        case 'createdDate':
            return data.createdDate && format(new Date(data.createdDate), 'dd.MM.yyyy');
        case 'startDate':
            return data.startDate && format(new Date(data.startDate), 'dd.MM.yyyy');
        case 'endDate':
            return data.endDate && format(new Date(data.endDate), 'dd.MM.yyyy');
        case 'flagManual':
            return data.flagManual ? 'Да' : 'Нет';
        default:
            return data[options.field as keyof SubscriptionInfo];
    }
};

const actions = [
    {
        label: 'Активировать',
        code: 'ACTIVE',
    },
    {
        label: 'Деактивировать',
        code: 'FROZEN',
    },
];

export const UserDetail = () => {
    const {
        user,
        loader,
        setCurrentPage,
        handleTableRowClick,
        totalPages,
        subs,
        currentPage,
        updateUserStatusHandler,
        isOpen,
        openModal,
        closeModal,
        setSelectedStatus,
        selectedStatus,
    } = useUserDetail();
    const { rootAdminPermissions } = usePermissions();

    if (loader) {
        return <PageLoader />;
    }

    return (
        <div className={classNames(styles.form, 'flex', 'flex-column')}>
            <div className={classNames('flex', 'justify-content-between', 'align-items-center', styles.formFields)}>
                <span className={classNames(styles.title)}>
                    <BackArrow />
                    Пользователь {user ? `${user?.fullName}` : ''}
                </span>
                <HasAccess permissions={rootAdminPermissions}>
                    <Dropdown
                        onChange={(e) => {
                            setSelectedStatus(actions.find((item) => e.value === item.code));
                            openModal();
                        }}
                        options={actions}
                        optionLabel="label"
                        placeholder="Выбрать действие"
                        optionValue="code"
                        className={classNames(styles.filter)}
                    />
                </HasAccess>
            </div>
            <div>
                <div className={classNames('flex', 'flex-column', styles.formFields)}>
                    {user &&
                        Object.keys(userFields).map((keyName) => {
                            return (
                                <div className={styles.field} key={keyName}>
                                    <label htmlFor={keyName}>{userFields[keyName as keyof typeof userFields]}</label>
                                    <div>
                                        <InputText
                                            style={{ width: 400 }}
                                            id={userFields[keyName as keyof typeof userFields]}
                                            disabled
                                            value={
                                                keyName === 'createdAt' || keyName === 'updatedAt'
                                                    ? getDate(user[keyName])
                                                    : user[keyName as keyof UserInfo]
                                            }
                                        />
                                    </div>
                                </div>
                            );
                        })}
                </div>

                <div style={{ padding: '0 40px' }}>
                    <h2 className={styles.title}>Подписки</h2>
                </div>

                <DataTable
                    value={subs}
                    rows={25}
                    className={classNames(styles.table)}
                    onRowClick={handleTableRowClick}
                    emptyMessage={'Подписки отсутствуют'}
                >
                    {subscriptionColumns.map((column) => (
                        <Column
                            key={column.field}
                            field={column.field}
                            header={column.header}
                            alignHeader="center"
                            className={classNames('text-center')}
                            body={bodyTemplate as any}
                            sortable
                        />
                    ))}
                </DataTable>

                <div className={classNames('flex', 'justify-content-center')}>
                    <PaginationController
                        total={totalPages}
                        current={currentPage}
                        setAsyncCurrent={(e) => setCurrentPage(e)}
                    />
                </div>
            </div>

            <ConfirmModal
                header={'Подтвердить действие'}
                confirmButtonText={'Подтвердить'}
                onConfirm={updateUserStatusHandler}
                onClose={closeModal}
                isVisible={isOpen}
                children={
                    <p>
                        Вы уверены что хотите перевести пользователя в статус{' '}
                        {selectedStatus?.code === 'ACTIVE' ? UserStatus.ACTIVE : UserStatus.FROZEN}?
                    </p>
                }
            />
        </div>
    );
};
