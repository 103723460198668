import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';
import { ChangeSubscriptionRequestType, GetSubscriptionsRequestType, PostSubscriptionRequestType, ProfileRequestType, ProfileResType, SubscriptionItemRequestType, SubscriptionsRequestType } from './types';
import { PageableType } from '@libs/types';

export const SUBSCRIPTIONS_URL = '/moex-datashop-datashopservice/api/admin/v1/subscriptions';

export const PROFILE_URL = '/moex-datashop-profileservice/api/admin/userprofile/v1/search';

export const getSubscriptions = async (
    requestData: GetSubscriptionsRequestType,
): Promise<AxiosResponse<SubscriptionsRequestType>> =>
    await httpClient.post(`${SUBSCRIPTIONS_URL}/search`, requestData);

export const getSubscriptionById = async (
    subscription_id: string,
): Promise<AxiosResponse<SubscriptionItemRequestType>> =>
    await httpClient.get(`${SUBSCRIPTIONS_URL}/search/${subscription_id}`);

export const exportSubscriptions = async (
  requestData: GetSubscriptionsRequestType,
): Promise<AxiosResponse<Blob>> =>
    await httpClient.post(`${SUBSCRIPTIONS_URL}/export`, { responseType: 'blob', ...requestData });

export const createSubscription = async (requestData: PostSubscriptionRequestType): Promise<AxiosResponse<SubscriptionItemRequestType>> =>
    await httpClient.post(`${SUBSCRIPTIONS_URL}`, requestData);

export const updateSubscription = async (requestData: ChangeSubscriptionRequestType): Promise<AxiosResponse<SubscriptionsRequestType>> =>
    await httpClient.post(`${SUBSCRIPTIONS_URL}/${requestData.subscriptionId}`, requestData);

export const getUsers = async (requestData: { fullName: string, pageable: PageableType }): Promise<AxiosResponse<ProfileResType>> =>
    await httpClient.post(`${PROFILE_URL}`, requestData);
