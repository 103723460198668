import { useFieldArray, Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { getPermissions, updatePermissions } from '@modules/AdminPage/services';
import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { type PermissionResType } from '@modules/AdminPage/services/types';
import { Checkbox } from 'primereact/checkbox';
import { usePermissions } from '@libs/hooks';
import { HasAccess } from '@libs/components/access';

import styles from './styles.module.scss';

type PermissionModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    productGuid?: string;
    tariffGuid?: string;
};

export const PermissionModal = (props: PermissionModalProps) => {
    const { isOpen, closeModal, tariffGuid, productGuid } = props;
    const { isAdmin, rootAdminPermissions } = usePermissions();

    const {
        reset,
        control,
        handleSubmit,
        formState: { isDirty },
    } = useForm<{
        permissions: PermissionResType[];
    }>({
        mode: 'onBlur',
    });

    const { fields } = useFieldArray({
        control,
        name: 'permissions',
    });

    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                if (tariffGuid && productGuid && isOpen) {
                    await getPermissions({ productGuid, tariffGuid }).then((data) => {
                        reset({
                            permissions: data.data.data,
                        });
                    });
                }
            } catch (error) {
                console.log('error: ', error);
            }
        };

        fetchPermissions();
    }, [isOpen]);

    const onSubmit = async (data: { permissions: PermissionResType[] }) => {
        try {
            if (tariffGuid) {
                await updatePermissions(tariffGuid, {
                    permissions: data.permissions.map((el) => {
                        return { permissionId: el.permissionId, isEnabled: el.isEnabled };
                    }),
                }).then(() => closeModal());
            }
        } catch (error) {
            console.log('error: ', error);
        }
    };

    return (
        <Dialog header={'Управление доступами'} onHide={closeModal} visible={isOpen}>
            <div className={classNames(styles.form)}>
                {fields
? (
                    fields.map((el, index) => {
                        return (
                            <Controller
                                key={el.id}
                                control={control}
                                name={`permissions.${index}.isEnabled`}
                                render={({ field }) => (
                                    <div className={classNames('flex', 'gap-2', 'align-items-center')}>
                                        <label htmlFor="isEnabled">{el.title}</label>
                                        <Checkbox checked={field.value} onChange={field.onChange} disabled={!isAdmin} />
                                    </div>
                                )}
                            />
                        );
                    })
                )
: (
                    <span>Доступы отсутствуют</span>
                )}
            </div>
            <HasAccess permissions={rootAdminPermissions}>
                <Button
severity="secondary" outlined onClick={handleSubmit(onSubmit)}
disabled={!isDirty}>
                    Изменить
                </Button>
            </HasAccess>
        </Dialog>
    );
};
