import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Dropdown, type DropdownChangeEvent } from 'primereact/dropdown';
import { DataTable, type SortOrder } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import { type Product } from '@libs/types';
import { Button } from 'primereact/button';
import { PaginationController } from '@libs/components';
import { HasAccess } from '@libs/components/access';
import { usePermissions } from '@libs/hooks';

import { getReferences, getProducts } from '../../services';
import { type Reference } from '../../services/types';
import styles from '../styles/styles.module.scss';

type FiltersParams = {
    product?: string;
    status?: string;
};

export const References = () => {
    const navigate = useNavigate();
    const [references, setReferences] = useState<Reference[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [filters, setFilters] = useState<FiltersParams>();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState(0);
    const { rootAdminAndMarket } = usePermissions();
    const [sortField, setSortField] = useState<string>('id');
    const [sortOrder, setSortOrder] = useState<SortOrder>(1);

    const statuses = [
        { name: 'Черновик', value: 'DRAFT' },
        { name: 'Опубликовано', value: 'PUBLISHED' },
        { name: 'Архив', value: 'ARCHIVED' },
    ];

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await getProducts({ pageable: {} });
                setProducts(response.data.data.rows);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const fetchReferences = async () => {
        setLoading(true);
        try {
            const response = await getReferences({
                status: filters?.status,
                productGuid: filters?.product,
                pageable: {
                    pageNumber: currentPage,
                    pageSize: 25,
                    sortField,
                    sortOrder: sortOrder === 1 ? 'desc' : 'asc',
                },
            });
            setTotalPages(response.data.data.totalPages);
            setReferences(response.data.data.rows);
        } catch (error) {
            console.error('Error fetching references:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSort = (e: any) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    };

    useEffect(() => {
        fetchReferences();
    }, [filters, currentPage, sortField, sortOrder]);

    const handleChangeFilter = (value: string, key: string) => {
        setFilters((prev) => ({ ...prev, [key]: value }));
    };

    const productOptions = products.filter((el) => !!el.name).map((p) => ({ label: p.name, value: p.productGuid }));

    if (loading) {
        return <div />;
    }

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>Справки</span>
                    <div className={classNames('flex', 'align-items-center', styles.btnGroup)}>
                        <Dropdown
                            value={filters?.status}
                            options={statuses}
                            onChange={(e: DropdownChangeEvent) => handleChangeFilter(e.value, 'status')}
                            optionLabel="name"
                            placeholder="Статус"
                            className={styles.dropdown}
                        />
                        <Dropdown
                            value={filters?.product}
                            options={productOptions}
                            onChange={(e: DropdownChangeEvent) => handleChangeFilter(e.value, 'product')}
                            optionLabel="label"
                            placeholder="Продукт"
                            className={styles.dropdown}
                        />
                        <HasAccess permissions={rootAdminAndMarket}>
                            <Button
                                label="Создать"
                                severity="secondary"
                                outlined
                                onClick={() => navigate('/references/create')}
                            />
                        </HasAccess>
                    </div>
                </div>
                <DataTable
                    value={references}
                    loading={loading}
                    className={classNames(styles.table)}
                    onRowClick={(e) => navigate(`/references/${e.data.id}`)}
                    emptyMessage={'Справки отсутствуют'}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={handleSort}
                    lazy
                >
                    <Column field="id" header="ID" sortable />
                    <Column field="title" header="Название" sortable />
                    <Column
                        field="productGuid"
                        header="Продукт"
                        sortable
                        body={(rowData) => {
                            const product = products.find((p) => p.productGuid === rowData.productGuid);

                            return product ? product.name : rowData.productGuid;
                        }}
                    />
                    <Column field="status" header="Статус" sortable />
                    <Column field="priority" header="Приоритет" sortable />
                </DataTable>
                <div className={classNames('flex', 'justify-content-center')}>
                    <PaginationController
                        total={totalPages}
                        current={currentPage}
                        setAsyncCurrent={(e) => setCurrentPage(e)}
                    />
                </div>
            </div>
        </div>
    );
};
