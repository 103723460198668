import { ReactComponent as MessageIcon } from '@shared/images/svg/Message.svg';
import { Button } from 'primereact/button';
import { useResize, useModal } from '@libs/utils';
import classNames from 'classnames';
import { ModalWindow, SendQuestionModal, RequestForm } from '@libs/components';
import React, { useState } from 'react';

import styles from './styles.module.scss';

export const Feedback = ({ isAbsolute }: { isAbsolute?: boolean }) => {
    // todo: remove if Feedback is needed
    return <></>;

    const [showRequestModal, setShowRequestModal] = useState<boolean>(false);
    const { isScreenSm, isScreenLessSm } = useResize();
    const { closeModal, openModal, isOpen } = useModal();
    const [requestTitle, setRequestTitle] = useState<string>('');
    const [requestMessage, setRequestMessage] = useState<string>('');

    const openRequestForm = (isSuccess: boolean) => {
        setRequestTitle(isSuccess ? 'Вопрос отправлен' : 'Произошла ошибка');
        setRequestMessage(
            isSuccess
                ? 'Спасибо за интерес к нашему сервису. Наши специалисты ответят вам на email в самое ближайшее время.'
                : 'Повторите попытку позже',
        );
        setShowRequestModal(true);
    };

    const closeFeedbackModal = () => {
        closeModal();
        setShowRequestModal(false);
    };

    return (
        <>
            <div
                className={classNames(styles.feedback, isAbsolute && styles.absolute)}
                style={{ display: isScreenSm || isScreenLessSm ? 'none' : 'block' }}
            >
                <Button onClick={openModal}>
                    <div className={styles.buttonContent}>
                        <MessageIcon />
                        <p>Задать вопрос</p>
                    </div>
                </Button>
            </div>
            <div style={{ clear: 'both' }} />
            <ModalWindow isVisible={isOpen} closeModal={closeFeedbackModal}>
                {showRequestModal
? (
                    <RequestForm title={requestTitle} message={requestMessage} onClick={closeFeedbackModal} />
                )
: (
                    <SendQuestionModal onClick={openRequestForm} />
                )}
            </ModalWindow>
        </>
    );
};
