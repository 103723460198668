import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { PaginationController } from '@libs/components';
import CustomAutoComplete from '@libs/components/data/CustomAutoComplete';
import { HasAccess } from '@libs/components/access';
import { usePermissions } from '@libs/hooks';

import styles from './styles.module.scss';
import { filterOptions, flagManualOptions, tableColumns } from './constants';
import { SubscriptionStatus } from '../services/types';
import { useSubscriptionsListFacade } from './useSubscriptionsListFacade';

export const statusesMocks = [
    SubscriptionStatus.ACTIVE,
    SubscriptionStatus.CREATED,
    SubscriptionStatus.CANCELLED,
    SubscriptionStatus.EXPIRED,
];

export const SubscriptionsList = () => {
    const {
        fields,
        control,
        handleSelectFilter,
        products,
        tariffs,
        currentPage,
        setCurrentPage,
        subscriptions,
        inputRef,
        handleChangeFilter,
        handleCreateClick,
        handleExportClick,
        handleTableRowClick,
        totalPages,
        bodyTemplate,
        fetchTariffs,
        handleSelectUserFilter,
        sortField,
        sortOrder,
        handleSort,
    } = useSubscriptionsListFacade();

    const { rootAdminPermissions } = usePermissions();

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    {!fields.length && <span className={classNames(styles.title)}>Подписки</span>}
                    <div className={classNames('flex', 'align-items-center', styles.filtersGroup)}>
                        {fields.find((el) => el.field === 'userId') && (
                            <Controller
                                control={control}
                                key={fields.find((el) => el.field === 'userId')?.id}
                                name={`filters.${fields?.findIndex((el) => el.field === 'userId')}.value`}
                                render={({ field: { value } }) => (
                                    <div className={classNames('flex flex-column gap-2', styles.userFilter)}>
                                        <label htmlFor="userId">Пользователь</label>
                                        <CustomAutoComplete
                                            value={
                                                value
                                                    ? {
                                                          code: value as string,
                                                          name: fields.find((el) => el.field === 'userId')
                                                              ?.value as string,
                                                      }
                                                    : undefined
                                            }
                                            onChange={handleSelectUserFilter}
                                        />
                                    </div>
                                )}
                            />
                        )}
                        {fields.find((el) => el.field === 'productGuid') && products?.length && (
                            <Controller
                                control={control}
                                key={fields.find((el) => el.field === 'productGuid')?.id}
                                name={`filters.${fields?.findIndex((el) => el.field === 'productGuid')}.value`}
                                render={({ field: { value } }) => (
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="productGuid">Продукт</label>
                                        <Dropdown
                                            onChange={(e) => {
                                                handleSelectFilter(e, 'productGuid');
                                                fetchTariffs(e.value);
                                            }}
                                            value={value}
                                            options={
                                                products
                                                    ?.filter((el) => !!el.name)
                                                    .map((el) => {
                                                        return {
                                                            label: el.name,
                                                            value: el.productGuid,
                                                        };
                                                    }) ?? []
                                            }
                                            optionLabel="label"
                                            placeholder="Выберите продукт"
                                            className={classNames(styles.filter)}
                                        />
                                    </div>
                                )}
                            />
                        )}
                        {fields.find((el) => el.field === 'tariffId') && (
                            <Controller
                                control={control}
                                name={`filters.${fields?.findIndex((el) => el.field === 'tariffId')}.value`}
                                render={({ field: { value } }) => (
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="tariffId">Тариф</label>
                                        <Dropdown
                                            onChange={(e) => handleSelectFilter(e, 'tariffId')}
                                            value={value}
                                            options={
                                                tariffs
                                                    ?.filter(
                                                        (el) => !!el.name &&
                                                            el.productGuid ===
                                                                fields.find((el) => el.field === 'productGuid')?.value,
                                                    )
                                                    .map((el) => {
                                                        return {
                                                            label: el.name,
                                                            value: el.guid,
                                                        };
                                                    }) ?? []
                                            }
                                            optionLabel="label"
                                            placeholder="Выберите тариф"
                                            className={classNames(styles.filter)}
                                        />
                                    </div>
                                )}
                            />
                        )}
                        {fields.find((el) => el.field === 'periodStart') && (
                            <div className="flex flex-column gap-2">
                                <label htmlFor="periodStart">Создана с</label>
                                <Calendar
                                    onChange={(e) => handleSelectFilter(e, 'periodStart')}
                                    value={fields.find((el) => el.field === 'periodStart')?.value as Date[]}
                                    dateFormat="dd.mm.yy"
                                    locale="ru"
                                    selectionMode="range"
                                    placeholder="Выберите период"
                                    className={classNames(styles.filter)}
                                    hideOnRangeSelection
                                    onFocus={(e) => e.preventDefault()}
                                />
                            </div>
                        )}
                        {fields.find((el) => el.field === 'flagManual') && (
                            <Controller
                                control={control}
                                name={`filters.${fields?.findIndex((el) => el.field === 'flagManual')}.value`}
                                defaultValue={false}
                                render={({ field: { value } }) => (
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="flag_manual">Признак ручной правки</label>
                                        <Dropdown
                                            onChange={(e) => handleSelectFilter(e, 'flagManual')}
                                            value={value}
                                            options={flagManualOptions}
                                            optionLabel="label"
                                            className={classNames(styles.filter)}
                                        />
                                    </div>
                                )}
                            />
                        )}
                        {fields.find((el) => el.field === 'statuses') && (
                            <Controller
                                key={fields.find((el) => el.field === 'statuses')?.id}
                                control={control}
                                name={`filters.${fields?.findIndex((el) => el.field === 'statuses')}.value.0`}
                                render={({ field: { value } }) => (
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="statuses">Статус</label>
                                        <Dropdown
                                            onChange={(e) => handleSelectFilter(e, 'statuses')}
                                            value={value}
                                            options={
                                                statusesMocks?.map((el) => {
                                                    return {
                                                        label: el,
                                                        value: el,
                                                    };
                                                }) ?? []
                                            }
                                            optionLabel="label"
                                            placeholder="Выберите статус"
                                            className={classNames(styles.filter)}
                                        />
                                    </div>
                                )}
                            />
                        )}
                        {fields.find((el) => el.field === 'orderNumbers') && (
                            <Controller
                                control={control}
                                name={`filters.${fields?.findIndex((el) => el.field === 'orderNumbers')}.value`}
                                rules={{ required: true }}
                                render={({ field, fieldState }) => (
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="orderNumbers">Номер заказа</label>
                                        <InputText
                                            {...field}
                                            placeholder="Введите номер заказа"
                                            value={field.value as string}
                                            onBlur={() => handleSelectFilter(inputRef.current, 'orderNumbers')}
                                            className={classNames(styles.filter)}
                                            ref={inputRef}
                                        />
                                    </div>
                                )}
                            />
                        )}
                    </div>
                    <div className={classNames('flex', 'align-items-center', styles.btnGroup)}>
                        <div className="flex flex-column gap-2">
                            <label htmlFor="filters">Фильтры</label>
                            <MultiSelect
                                onChange={handleChangeFilter}
                                value={fields.filter((el) => el.field !== 'userName').map((el) => el.field) ?? []}
                                options={filterOptions.filter((el) => fields.find((el) => el.field === 'productGuid')?.value
                                        ? true
                                        : el.code !== 'tariffId',
                                )}
                                optionLabel="name"
                                optionValue="code"
                                placeholder="Добавить фильтр"
                                pt={{
                                    header: { style: { display: 'none' } },
                                    item: { className: styles.multiSelectItem },
                                    root: { className: styles.multi },
                                    wrapper: { className: styles.multi },
                                }}
                            />
                        </div>
                        <HasAccess permissions={rootAdminPermissions}>
                            <Button
                                severity="secondary"
                                outlined
                                className={classNames(styles.button)}
                                onClick={handleCreateClick}
                            >
                                Создать
                            </Button>
                        </HasAccess>

                        <Button
                            severity="secondary"
                            outlined
                            className={classNames(styles.button)}
                            onClick={handleExportClick}
                        >
                            Экспорт
                        </Button>
                    </div>
                </div>
                <DataTable
                    value={subscriptions}
                    rows={25}
                    className={classNames(styles.table)}
                    onRowClick={handleTableRowClick}
                    emptyMessage={'Подписки отсутствуют'}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={handleSort}
                    lazy
                >
                    {tableColumns.map((column) => (
                        <Column
                            key={column.field}
                            field={column.field}
                            header={column.header}
                            alignHeader="center"
                            className={classNames('text-center')}
                            body={bodyTemplate}
                            sortable
                        />
                    ))}
                </DataTable>

                <div className={classNames('flex', 'justify-content-center')}>
                    <PaginationController
                        total={totalPages ?? 0}
                        current={currentPage}
                        setAsyncCurrent={(e) => setCurrentPage(e)}
                    />
                </div>
            </div>
        </div>
    );
};
