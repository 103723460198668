export const filterOptions = [
    { name: '№ заказа', code: 'orderNumber' },
    { name: 'Id заказа внутренний', code: 'orderId' },
    { name: 'Id заказа внешний', code: 'orderExternalId' },
    { name: 'Пользователь', code: 'userGuid' },
    { name: 'Статус', code: 'statuses' },
    { name: 'Дата заказа', code: 'period' },
];

export const tableColumns = [
    { field: 'orderNumber', header: '№ заказа' },
    { field: 'id', header: 'Id заказа внутренний' },
    { field: 'orderExternalId', header: 'Id заказа внешний' },
    { field: 'userName', header: 'Пользователь' },
    { field: 'status', header: 'Статус заказа' },
    { field: 'createdDate', header: 'Заказ создан' },
    { field: 'vat', header: 'Количество продуктов в заказе' },
    { field: 'price', header: 'Стоимость заказа' },
];

export const UNSORTABLE_COLUMNS = ['vat', 'status', 'price', 'id'];
