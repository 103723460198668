import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { AdminPermissions } from '@libs/types';

export const usePermissions = () => {
    const { permissions } = useSelector((state: RootState) => state.user)

    const isRootAdmin = permissions.includes(AdminPermissions.DS_ROOT);
    const isDsAdmin = permissions.includes(AdminPermissions.DS_ADMIN);
    const isViewAdmin = permissions.includes(AdminPermissions.DS_ADMIN_VIEW);
    const isMarketAdmin = permissions.includes(AdminPermissions.DS_MARKET_ADMIN);
    const isAdmin = [isRootAdmin, isDsAdmin].some(Boolean);
    const isAdminOrMarket = [isRootAdmin, isDsAdmin, isMarketAdmin].some(Boolean);
    const rootAdminPermissions = [AdminPermissions.DS_ROOT, AdminPermissions.DS_ADMIN];
    const rootAdminAndMarket = [...rootAdminPermissions, AdminPermissions.DS_MARKET_ADMIN];
    const anyAccess = [isRootAdmin, isDsAdmin, isViewAdmin, isMarketAdmin].some(Boolean);

    return {
        isAdmin,
        isViewAdmin,
        isMarketAdmin,
        isAdminOrMarket,
        rootAdminAndMarket,
        rootAdminPermissions,
        anyAccess,
    };
};
