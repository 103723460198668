import { Button } from 'primereact/button';
import { useDataMock } from '@modules/PersonalAccount/components/MainContent/components/AccountCard/components/GetUserDataForm/mocks';
import classNames from 'classnames';
import { ConfirmModal, NoticeModal } from '@libs/components';
import { useModal } from '@libs/utils';

import styles from './styles.module.scss';

export const GetUserDataForm = ({ closeOuterModal }: { closeOuterModal: () => void }) => {
    const { isOpen, openModal, closeModal } = useModal();

    const openInfoHandler = () => {
        closeModal();
        window.location.href = '/docs/Инструкция по удалению аккаунта в Datashop и отзыв согласия на обработку.docx';
    };

    return (
        <>
            <div className={classNames(styles.userDataWrapper)}>
                <h2>Личные данные</h2>
                <div className={classNames(styles.userData)}>
                    {useDataMock.map(({ name, value }) => {
                        return (
                            <div
                                key={value}
                                className={classNames('flex', 'justify-content-between', 'align-items-top')}
                            >
                                <span>{name}:</span>
                                <span>{value}</span>
                            </div>
                        );
                    })}
                </div>
                <div className={classNames('flex', 'align-items-center')}>
                    <Button onClick={closeOuterModal} label="Закрыть" />
                    <span onClick={openModal} role={'button'}>
                        Удалить персональные данные
                    </span>
                </div>
            </div>
            <ConfirmModal
                isVisible={isOpen}
                onClose={closeModal}
                onConfirm={openInfoHandler}
                header={'Удаление данных'}
                confirmButtonText={'Подтвердить'}
                backButtonName={'Отказаться'}
                className={styles.removeDataModal}
            >
                <p className={styles.confirmDeviceName}>
                    На указанный Вами адрес электронной почты было оправлено письмо со ссылкой для подтверждения.
                </p>
            </ConfirmModal>
        </>
    );
};
