import classNames from 'classnames';
import { type MenuItem } from 'primereact/menuitem';
import { useNavigate } from 'react-router-dom';
import { PanelMenu } from 'primereact/panelmenu';
import { useEffect, useState } from 'react';
import { usePermissions } from '@libs/hooks';

import styles from './styles.module.scss';

const isExpand = (url?: string) => url === 'articles' || url === 'references';

export const SideBar = () => {
    const navigate = useNavigate();

    const itemRenderer = (item: MenuItem) => (
        <div
            className={classNames(
                'p-menuitem-content',
                styles.item,
                item.url && window.location.href.includes(item.url) && styles.selectedItem,
                isExpand(item.url) && styles.expanded,
            )}
            onClick={() => item.url && navigate(item.url)}
        >
            <span>{item.label}</span>
        </div>
    );

    const [expandedKeys, setExpandedKeys] = useState({});
    const { isAdmin } = usePermissions();

    const items = [
        {
            label: 'Уведомления',
            key: '0',
            template: itemRenderer,
            url: 'notifications',
        },
        {
            label: 'Продукты',
            key: '1',
            template: itemRenderer,
            url: 'products',
        },
        {
            label: 'Подписки',
            key: '2',
            template: itemRenderer,
            url: 'subscriptions',
        },
        {
            label: 'Заказы',
            template: itemRenderer,
            key: '3',
            url: 'orders',
        },
        {
            label: 'Пользователи',
            template: itemRenderer,
            key: '4',
            url: 'users',
        },
        {
            label: 'Управление контентом',
            template: itemRenderer,
            key: '5',
            items: [
                {
                    label: 'Статьи',
                    template: itemRenderer,
                    url: 'articles',
                    key: '5-0',
                },
                {
                    label: 'Справки',
                    template: itemRenderer,
                    url: 'references',
                    key: '5-1',
                },
            ],
        },
        ...(isAdmin
            ? [
                  {
                      label: 'Отчеты',
                      template: itemRenderer,
                      key: '6',
                      url: 'reports',
                  },
                  {
                      label: 'Разрешения',
                      template: itemRenderer,
                      key: '7',
                      url: 'permissions',
                  },
              ]
            : []),
    ];

    useEffect(() => {
        const url = window.location.href;

        if (url.includes('articles') || url.includes('references')) {
            setExpandedKeys({ 5: true });
        }
    }, []);

    return (
        <PanelMenu
            model={items}
            expandedKeys={expandedKeys}
            onExpandedKeysChange={setExpandedKeys}
            className={classNames(styles.sidebar)}
        />
    );
};
