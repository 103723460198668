import { useEffect, useState } from 'react';
import { useEditor, EditorContent, BubbleMenu, type JSONContent } from '@tiptap/react';
import classNames from 'classnames';
import { type Level } from '@tiptap/extension-heading';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '@libs/hooks';

import ImageUploadModal from '../ImageUploadModal/ImageUploadModal';
import CarouselUploadModal from '../Carousel/CarouselUploadModal';
import BigCarouselUploadModal from '../BigCarousel/BigCarouselUploadModal';
import ColorPickerModal from '../ColorPickerModal/ColorPickerModal';
import LinkModal from '../LinkModal/LinkModal';
import ButtonModal from '../Button/ButtonModal';
import { extensions } from '../extensions';
import { RegularButton } from '../components/RegularButton/Button';
import { type ExtendedImageAttributes } from '../extensions/ExtendedImage';
import styles from './styles.module.scss';
import './Tiptap.scss';

type ButtonData = {
    buttonText: string;
    buttonLink: string;
    backgroundColor: string;
    textColor: string;
};

type TiptapProps = {
    content: JSONContent;
    shouldSave: boolean;
    onSave: (content: JSONContent) => void;
};

const Tiptap: React.FC<TiptapProps> = ({ content, shouldSave, onSave }: TiptapProps) => {
    const { t } = useTranslation();
    const [isImageModalVisible, setIsImageModalVisible] = useState(false);
    const [isCarouselModalVisible, setIsCarouselModalVisible] = useState(false);
    const [isBigCarouselModalVisible, setIsBigCarouselModalVisible] = useState(false);
    const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
    const [isLinkModalVisible, setIsLinkModalVisible] = useState(false);
    const [isButtonModalVisible, setIsButtonModalVisible] = useState(false);
    const { isAdminOrMarket } = usePermissions();

    const editor = useEditor({
        extensions,
        content,
        editable: isAdminOrMarket,
    });

    useEffect(() => {
        if (editor) {
            editor.commands.setContent(content);
        }
    }, [content, editor]);

    useEffect(() => {
        if (shouldSave && editor) {
            onSave(editor.getJSON());
        }
    }, [shouldSave, editor, onSave]);

    if (!editor) {
        return null;
    }

    const addImage = (url: string, width: string, height: string) => {
        editor
            .chain()
            .focus()
            .setImage({ src: url, width, height } as ExtendedImageAttributes)
            .run();
    };

    const addImagesToCarousel = ({
        urls,
        position,
        width,
        height,
    }: {
        urls: string[];
        position: 'left' | 'center' | 'right';
        width: string;
        height: string;
    }) => {
        editor
            .chain()
            .focus()
            .insertContent({
                type: 'carousel',
                attrs: { images: urls, position, width, height },
            })
            .run();
    };

    const addImagesAndTextsToBigCarousel = (
        images: string[],
        texts: string[][],
        position: 'left' | 'center' | 'right',
        width: number,
        height: number,
    ) => {
        editor
            .chain()
            .focus()
            .insertContent({
                type: 'bigCarousel',
                attrs: { images, texts, position, width, height },
            })
            .run();
    };

    const handleSetLink = (url: string) => {
        if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink().run();

            return;
        }
        editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    };

    const toggleHeading = (level: Level) => {
        editor.chain().focus().toggleHeading({ level }).run();
    };

    const setTextColor = (color: string) => {
        editor.chain().focus().setColor(color).run();
    };

    const handleDelete = () => {
        editor.chain().focus().deleteSelection().run();
    };

    const insertTable = () => {
        editor.chain().focus().insertTable({ rows: 1, cols: 2, withHeaderRow: false }).run();
    };

    const addRowBefore = () => {
        editor.chain().focus().addRowBefore().run();
    };

    const addRowAfter = () => {
        editor.chain().focus().addRowAfter().run();
    };

    const deleteRow = () => {
        editor.chain().focus().deleteRow().run();
    };

    const addColumnBefore = () => {
        editor.chain().focus().addColumnBefore().run();
    };

    const addColumnAfter = () => {
        editor.chain().focus().addColumnAfter().run();
    };

    const deleteColumn = () => {
        editor.chain().focus().deleteColumn().run();
    };

    const deleteTable = () => {
        editor.chain().focus().deleteTable().run();
    };

    const insertSpecialTable = () => {
        editor.commands.insertTable({ rows: 1, cols: 2, withHeaderRow: true });
    };

    const addButton = ({ buttonText, buttonLink, backgroundColor, textColor }: ButtonData) => {
        editor
            .chain()
            .focus()
            .insertContent({
                type: 'button',
                attrs: { buttonText, buttonLink, backgroundColor, textColor },
            })
            .run();
    };

    return (
        <div className={classNames(styles.editor, 'tiptap_editor')}>
            <div className={styles.menu_bar}>
                <RegularButton
                    label={t('editor.buttons.bold')}
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    active={editor.isActive('bold')}
                />
                <RegularButton
                    label={t('editor.buttons.italic')}
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    active={editor.isActive('italic')}
                />
                <RegularButton
                    label={t('editor.buttons.strike')}
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    active={editor.isActive('strike')}
                />
                <RegularButton
                    label={t('editor.buttons.code')}
                    onClick={() => editor.chain().focus().toggleCode().run()}
                    active={editor.isActive('code')}
                />
                <RegularButton
                    label={t('editor.buttons.codeBlock')}
                    onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                    active={editor.isActive('codeBlock')}
                />
                <RegularButton
                    label={t('editor.buttons.title')}
                    onClick={() => toggleHeading(1)}
                    active={editor.isActive('heading', { level: 1 })}
                />
                <RegularButton
                    label={t('editor.buttons.subtitle')}
                    onClick={() => toggleHeading(2)}
                    active={editor.isActive('heading', { level: 2 })}
                />
                <RegularButton
                    label={t('editor.buttons.bulletList')}
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    active={editor.isActive('bulletList')}
                />
                <RegularButton
                    label={t('editor.buttons.right')}
                    onClick={() => editor.chain().focus().sinkListItem('listItem').run()}
                    disabled={!editor.can().sinkListItem('listItem')}
                />
                <RegularButton
                    label={t('editor.buttons.left')}
                    onClick={() => editor.chain().focus().liftListItem('listItem').run()}
                    disabled={!editor.can().liftListItem('listItem')}
                />
                <RegularButton label={t('editor.buttons.textColor')} onClick={() => setIsColorPickerVisible(true)} />
                <RegularButton label={t('editor.buttons.specialTable')} onClick={insertSpecialTable} />
                <RegularButton label={t('editor.buttons.table')} onClick={insertTable} />
                <RegularButton label={t('editor.buttons.rowBefore')} onClick={addRowBefore} />
                <RegularButton label={t('editor.buttons.rowAfter')} onClick={addRowAfter} />
                <RegularButton label={t('editor.buttons.deleteRow')} onClick={deleteRow} />
                <RegularButton label={t('editor.buttons.columnBefore')} onClick={addColumnBefore} />
                <RegularButton label={t('editor.buttons.columnAfter')} onClick={addColumnAfter} />
                <RegularButton label={t('editor.buttons.deleteColumn')} onClick={deleteColumn} />
                <RegularButton label={t('editor.buttons.deleteTable')} onClick={deleteTable} />
                <RegularButton
                    label={t('editor.buttons.link')}
                    onClick={() => setIsLinkModalVisible(true)}
                    active={editor.isActive('link')}
                />
                <RegularButton label={t('editor.buttons.addButton')} onClick={() => setIsButtonModalVisible(true)} />
                <RegularButton label={t('editor.buttons.addImage')} onClick={() => setIsImageModalVisible(true)} />
                <RegularButton
                    label={t('editor.buttons.addCarousel')}
                    onClick={() => setIsCarouselModalVisible(true)}
                />
                <RegularButton
                    label={t('editor.buttons.addBigCarousel')}
                    onClick={() => setIsBigCarouselModalVisible(true)}
                />
            </div>
            <EditorContent editor={editor} className={styles.editable_tiptap} />
            <BubbleMenu editor={editor} className={styles.bubble_menu}>
                <RegularButton
                    label={t('editor.buttons.bold')}
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    active={editor.isActive('bold')}
                />
                <RegularButton label={t('editor.buttons.textColor')} onClick={() => setIsColorPickerVisible(true)} />
                <RegularButton
                    label={t('editor.buttons.link')}
                    onClick={() => setIsLinkModalVisible(true)}
                    active={editor.isActive('link')}
                />
                <RegularButton label={t('editor.buttons.delete')} onClick={handleDelete} />
            </BubbleMenu>

            <ColorPickerModal
                isVisible={isColorPickerVisible}
                onClose={() => setIsColorPickerVisible(false)}
                onSelectColor={setTextColor}
            />

            <LinkModal
                isVisible={isLinkModalVisible}
                onClose={() => setIsLinkModalVisible(false)}
                onSetLink={handleSetLink}
                previousUrl={editor.getAttributes('link').href}
            />

            <ImageUploadModal
                isVisible={isImageModalVisible}
                onClose={() => setIsImageModalVisible(false)}
                onUpload={addImage}
            />

            <CarouselUploadModal
                isVisible={isCarouselModalVisible}
                onClose={() => setIsCarouselModalVisible(false)}
                onUpload={addImagesToCarousel}
            />

            <BigCarouselUploadModal
                isVisible={isBigCarouselModalVisible}
                onClose={() => setIsBigCarouselModalVisible(false)}
                onUpload={addImagesAndTextsToBigCarousel}
            />

            <ButtonModal
                isVisible={isButtonModalVisible}
                onClose={() => setIsButtonModalVisible(false)}
                onAddButton={addButton}
            />
        </div>
    );
};

export default Tiptap;
