export const userFields = {
    customerId: 'ID',
    firstName: 'Имя',
    lastName: 'Фамилия',
    middleName: 'Отчество',
    fullName: 'Полное имя',
    status: 'Статус',
    email: 'Email',
    phoneNumber: 'Телефон',
    createdAt: 'Дата создания',
    updatedAt: 'Дата обновления',
};

export const userColumns = Object.entries(userFields)
    .filter(([key]) => key !== 'fullName')
    .map(([key, value]) => ({
        field: key,
        header: value,
    }));

// export const userMock: UserInfo = {
//     customerId: '123',
//     createdAt: '2024-06-22T10:28:22.717Z',
//     updatedAt: '2024-06-22T10:28:22.717Z',
//     email: '123@mail.rt',
//     firstName: 'asd',
//     lastName: 'dsa',
//     middleName: 'sgf',
//     phone: '219849102841',
//     status: UserStatus.ACTIVE,
//     fullName: '142124'
// }
