import { type AnyObject } from '@libs/utils';
import { addDays } from 'date-fns';

export const transformArrayToObject = (arr: AnyObject[]): AnyObject => {
    return arr.reduce((acc: AnyObject, { field, value }) => {
        if (value && field !== 'period' && field !== 'userName' && field !== 'userGuid' && field !== 'statuses') {
            acc[field] = value;
        }

        if (value && field === 'userGuid') {
            acc['userId'] = value;
        }

        if (field === 'statuses') {
            acc['statuses'] = value.filter((el: any) => !!el);
        }

        if (value && field === 'period') {
            if (value[0]) {
                acc['dateFrom'] = addDays(new Date(value[0]), 1);
            }

            if (value[1]) {
                acc['dateTo'] = addDays(new Date(value[1]), 1);
            }
        }

        return acc;
    }, {});
};
