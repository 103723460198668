import { Button } from 'primereact/button';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { ModalWindow, type ModalWindowProps } from '../Modal';

type NoticeModalType = ModalWindowProps & {
    headerText: string;
    mainText: string;
    closeButtonText: string;
};

export const NoticeModal = ({
    closeModal,
    closeButtonText,
    headerText,
    mainText,
    isVisible,
    className,
}: NoticeModalType) => {
    return (
        <ModalWindow closeModal={closeModal} isVisible={isVisible} className={className}>
            <div className={classNames(styles.modalWrapper, 'flex', 'flex-column')}>
                <h2>{headerText}</h2>
                <p>{mainText}</p>
                <Button onClick={closeModal} label={closeButtonText} />
            </div>
        </ModalWindow>
    );
};
