export const useDataMock = [
    {
        name: 'ФИО',
        value: 'Иванов Александр Викторович',
    },
    {
        name: 'Дата рождения',
        value: '1960-01-01',
    },
    {
        name: 'Серия и номер паспорта',
        value: '1111 111111',
    },
    {
        name: 'Код подразделения',
        value: '111-111',
    },
    {
        name: 'Кем выдан',
        value: 'ОВД Москвы',
    },
    {
        name: 'Дата выдачи',
        value: '2000-01-01',
    },
    {
        name: 'Место регистрации',
        value: '123456, Москва г, Новая ул,14 корп.2, 48',
    },
];
