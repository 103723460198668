import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useMemo, useState } from 'react';
import { ReactComponent as EditEmailIcon } from '@shared/images/personalAccount/svg/edit_email_icon.svg';
import { ReactComponent as CheckEmailIcon } from '@shared/images/personalAccount/svg/check_email_icon.svg';
import { ReactComponent as CheckDisableEmailIcon } from '@shared/images/personalAccount/svg/check_disable_email_icon.svg';
import { ReactComponent as EmailIcon } from '@shared/images/personalAccount/svg/email_icon.svg';
import { useModal, validateEmail } from '@libs/utils';
import { NoticeModal } from '@libs/components';
import { useDispatch } from 'react-redux';
import { type AppDispatch, confirmEmailThunk, updateEmailThunk } from '@store/store';

import styles from './styles.module.scss';

type Props = {
    isEmailVerified?: boolean;
    email: string;
};

export const ChangeEmailComponent = ({ isEmailVerified, email }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [isDisableEmail, setDisableEmail] = useState<boolean>(!isEmailVerified && email !== '');
    const [newEmail, setNewEmail] = useState<string>(email);
    const [showEmailBtn, setShowEmailBtn] = useState<boolean>(email !== '');

    const isEmailCorrect = useMemo(() => validateEmail(newEmail), [newEmail]);
    const { isOpen, openModal, closeModal } = useModal();

    const handlerClick = () => {
        if (isDisableEmail) {
            setDisableEmail(false);
        } else if (isEmailCorrect) {
            if (newEmail !== email) {
                dispatch(updateEmailThunk({ email: newEmail }))
                    .then((resp) => {
                        dispatch(confirmEmailThunk());
                    })
                    .catch((e) => console.error(e));
            } else {
                dispatch(confirmEmailThunk());
            }
            setDisableEmail(true);
            openModal();
        }
    };

    const confirmEmail = () => {
        dispatch(confirmEmailThunk())
            .then(() => {
                openModal();
            })
            .catch((e) => console.error(e));
    };

    return (
        <>
            <div className={classNames(styles.infoWrapper, styles.notConfirmed)}>
                <div className={classNames('flex', styles.notConfirmedEmailContainer)}>
                    <span className={classNames('p-input-icon-left', styles.inputContainer)}>
                        <i className="pi">
                            <EmailIcon />
                        </i>
                        <InputText
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                            disabled={isDisableEmail}
                            className={styles.emailInput}
                            placeholder="personal@email.ru"
                            onFocus={() => setShowEmailBtn(true)}
                            onBlur={() => {
                                if (email === '' && newEmail === '') {
                                    setShowEmailBtn(false);
                                }
                            }}
                        />
                    </span>
                    {showEmailBtn && (
                        <Button
                            className={classNames(!isEmailCorrect && !isDisableEmail && styles.incorrect)}
                            onClick={handlerClick}
                        >
                            {isDisableEmail
? (
                                <EditEmailIcon />
                            )
: isEmailCorrect
? (
                                <CheckEmailIcon />
                            )
: (
                                <CheckDisableEmailIcon />
                            )}
                        </Button>
                    )}
                </div>
                {!isEmailVerified && email && (
                    <div className={classNames('flex', 'justify-content-between', styles.notConfirmedMessage)}>
                        <p>Email не подтвержден</p>
                        <span onClick={confirmEmail} role={'button'}>
                            Отправить повторно
                        </span>
                    </div>
                )}
            </div>
            <NoticeModal
                className={styles.noticeEmailModal}
                isVisible={isOpen}
                closeModal={closeModal}
                headerText={'Подтверждение почты'}
                mainText={
                    'На указанный Вами адрес электронной почты было оправлено письмо со ссылкой для подтверждения.'
                }
                closeButtonText={'Закрыть'}
            />
        </>
    );
};
