import { PageableType } from '@libs/types';
import { Control } from 'react-hook-form';

export enum NotificationStatus {
    PENDING = 'PENDING',
    ARCHIVE = 'ARCHIVE',
    ACTIVE = 'ACTIVE',
}

export enum NotificationCategory {
    SYSTEM = 'SYSTEM',
    NEWS = 'NEWS',
    MARKETING = 'MARKETING',
}

export const NotificationCategoryMatch = {
    SYSTEM: 'Системное',
    NEWS: 'Новость',
    MARKETING: 'Маркетинг',
};

export type NotificationInfo = {
    id: number;
    status: NotificationStatus;
    publicationDate: string;
    archiveDate: string;
    authorId: string;
    sourceService: string;
    category: NotificationCategory;
    forAnonymousUsers: boolean;
    isUpdated: boolean;
    title: string;
    image:
        | File
        | {
              bucket: string;
              fileId: string;
          };
    buttonUrl: string;
    buttonText: string;
    modalTitle: string;
    modalText: string;
    modalButtonUrl: string;
    modalButtonText: string;
    targetGroups: TargetGroupEditType[];
};

export type GetNotificationsRequestType = {
    status?: NotificationStatus;
    category?: NotificationCategory;
    forAnonymousUsers?: boolean;
    pageable?: PageableType;
};

export interface Status {
    errorCode: string;
    errorMessage: string;
}

export interface Line {
    errorCode: string;
    field: string;
    rejectedValue: string;
    errorMessage: string;
}

export interface Validation {
    lines: Line[];
}

export type NotificationsResType = {
    totalRows: number;
    totalPages: number;
    size: number;
    pageNumber: number;
    rows: NotificationInfo[];
};

export type NotificationsRequestType = {
    createdDate: string;
    status: Status;
    validation: Validation;
    data: NotificationsResType;
};

export type CreateNotificationsRequestType = {
    createdDate: string;
    data: NotificationInfo;
};

export enum FormFieldsTypes {
    SINGLE_SELECT = 'SINGLE_SELECT',
    INPUT = 'INPUT',
    ARCHIVING_DATE = 'ARCHIVING_DATE',
    OTHER = 'OTHER',
    CHECKBOX = 'CHECKBOX',
    IMAGE = 'IMAGE',
    PUBLICATION_DATE = 'PUNBLICATION_DATE',
}

export type FormControlType = {
    control?: Control;
    name: string;
    rules?: any;
    label: string;
    placeHolder: string;
    type: FormFieldsTypes;
    disabled?: boolean;
    options?: DropdownOptions[];
};

export type GetTargetGroupsRequestType = {
    createdDate: string;
    status: Status;
    validation: Validation;
    data: TargetGroupType[];
};

export type TargetGroupType = {
    id: number;
    name: string;
    isProductRequired: boolean;
};

export type TargetGroupFormType = {
    _id?: number;
    productGuid?: string;
    name: string;
};

export type CreateNotificationFormInfo = {
    publicationDate?: string;
    archiveDate?: string;
    category: NotificationCategory;
    targetGroups: TargetGroupFormType[];
    forAnonymousUsers: boolean;
    title: string;
    image?: File;
    buttonUrl?: string;
    buttonText?: string;
    modalTitle?: string;
    modalText?: string;
    modalButtonUrl?: string;
    modalButtonText?: string;
};

export type EditNotificationFormInfo = CreateNotificationFormInfo & {
    status: NotificationStatus;
};

export type NotificationsFiltersParams = {
    status?: NotificationStatus;
    category?: NotificationCategory;
};

export type DropdownOptions = {
    label: string;
    value: string;
};

export type TargetGroupEditType = {
    id: number;
    productGuid: string;
};
