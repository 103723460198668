import { createAsyncThunk } from '@reduxjs/toolkit';
import { downloadFile, uploadTicket } from '@libs/services';
import { ImageObject } from '@libs/types';

export const downloadFileThunk = createAsyncThunk(
    'attachment/downloadFile',
    async ({ bucket, fileId }: Partial<ImageObject>, { rejectWithValue }) => {
        try {
            if (bucket && fileId) {
                const { data } = await downloadFile(bucket, fileId);
                return URL.createObjectURL(data);
            }
        } catch (e) {
            console.error('e', e);
        }
    },
);
