import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { useEffect, useRef } from 'react';
import { getSavedCardsThunk } from '@store/store/thunk/card.thunk';
import { ReactComponent as NewCardIcon } from '@shared/images/svg/cardIcons/NewCard.svg';
import { ReactComponent as ArrowRed } from '@shared/images/personalAccount/svg/arrow_red.svg';
import { useResize } from '@libs/utils';
import { DropdownWrapper } from '@libs/components';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import { mockProducts } from './components/mockData';
import { ProductCard } from './components/ProductCard';
import { EmptyProductCard } from './components/EmptyProductCard';
import { EmptyAllCardsBlock } from './components/EmptyAllCardsBlock';
import { CardComponent } from './components/CardComponent';
import { AccountCard } from './components/AccountCard';
import { ProductCarousel } from './components/ProductCarousel';

export const MainContent = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { width } = useResize();

    const imageAvatarSelector = useSelector((state: RootState) => state.user.userAvatar);
    const userSelector = useSelector((state: RootState) => state.user.user);
    const savedCards = useSelector((state: RootState) => state.card.savedCards);

    useEffect(() => {
        dispatch(getSavedCardsThunk());
    }, []);

    const navigate = useNavigate();
    const productsRef = useRef(null);

    const goToProducts = () => {
        navigate('/', { state: { goToProducts: true, productsRef } });
    };

    return (
        <section className={classNames('flex', width < 1599 && 'flex-column', styles.section)}>
            <div className={classNames('flex-1', styles.accountCardWrapper)}>
                {userSelector && (
                    <AccountCard
                        imageStr={imageAvatarSelector}
                        email={userSelector.email}
                        phoneNumber={userSelector.phoneNumber}
                        fullName={userSelector.fullName}
                        personalDataProvided={userSelector.personalDataProvided}
                        isEmailVerified={userSelector.isEmailVerified}
                    />
                )}
            </div>
            <div className={classNames('flex', 'flex-column', styles.productsContainer)}>
                <DropdownWrapper title={'Ваши продукты'}>
                    <div className={classNames(styles.goToProductWrapper, 'flex', 'flex-column', 'align-items-center')}>
                        <ArrowRed />
                        <p>
                            Больше продуктов, сервисов и данных от Московской Биржи и партнеров можете найти на главной
                            странице Datashop.
                        </p>
                        <Button label={'Выбрать продукты'} onClick={goToProducts} />
                    </div>
                    {/*<div className={classNames(styles.productsWrapper, 'flex', 'flex-wrap')}>*/}
                    {/*    {width < 768 ? (*/}
                    {/*        <ProductCarousel />*/}
                    {/*    ) : (*/}
                    {/*        mockProducts.map(*/}
                    {/*            (*/}
                    {/*                data, // mySubs in future*/}
                    {/*            ) => <ProductCard key={data.title} {...data} />,*/}
                    {/*        )*/}
                    {/*    )}*/}
                    {/*    /!* if only one product *!/*/}
                    {/*    {mockProducts.length === 1 && <EmptyProductCard />}*/}
                    {/*    /!* if no one product has bought yet *!/*/}
                    {/*    {!mockProducts.length && <EmptyAllCardsBlock />}*/}
                    {/*</div>*/}
                </DropdownWrapper>
                <DropdownWrapper title={'Способы оплаты'}>
                    <div className={classNames(styles.cardWrapper, 'inline-flex')}>
                        {savedCards && savedCards.length
? (
                            savedCards.map((data) => <CardComponent key={data.id} {...data} />)
                        )
: (
                            <div className={classNames(styles.noCart, 'flex', 'align-items-center')}>
                                <NewCardIcon />
                                <p>Нет сохраненных карт</p>
                            </div>
                        )}
                    </div>
                </DropdownWrapper>
                {/* <div className={styles.safetyOuterWrapper}> */}
                {/*    <DropdownWrapper title={'Безопасность'}> */}
                {/*        <div className={classNames(styles.safetyWrapper, 'flex', 'justify-content-start')}> */}
                {/*            <ConnectedDevicesBlock devices={mockDevices} /> */}
                {/*            <TwoFactorAuthBlock /> */}
                {/*        </div> */}
                {/*    </DropdownWrapper> */}
                {/* </div> */}
            </div>
        </section>
    );
};
