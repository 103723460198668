import { type FormControlType, FormFieldsTypes } from '../services/types';

export const createNotificationPageFields: FormControlType[] = [
    {
        name: 'publicationDate',
        label: 'Дата публикации',
        placeHolder: 'Введите дату публикации',
        type: FormFieldsTypes.PUBLICATION_DATE,
    },
    {
        name: 'archiveDate',
        label: 'Дата архивации',
        placeHolder: 'Введите дату архивации',
        type: FormFieldsTypes.ARCHIVING_DATE,
    },
    {
        name: 'category',
        label: 'Категория *',
        placeHolder: 'Выберите категорию',
        type: FormFieldsTypes.SINGLE_SELECT,
        rules: { required: true },
    },
    {
        name: 'target',
        label: 'Группы пользователей *',
        placeHolder: 'Выберите группу',
        type: FormFieldsTypes.OTHER,
    },
    {
        name: 'forAnonymousUsers',
        label: 'Для неавторизованных пользователей *',
        placeHolder: 'Для неавторизованных пользователей',
        type: FormFieldsTypes.CHECKBOX,
    },
    {
        name: 'title',
        label: 'Текст *',
        placeHolder: 'Введите текст',
        type: FormFieldsTypes.INPUT,
        rules: { required: true },
    },
    {
        name: 'image',
        label: 'Изображение',
        type: FormFieldsTypes.IMAGE,
        placeHolder: 'Изображение',
    },
    {
        name: 'buttonUrl',
        label: 'URL кнопки',
        placeHolder: 'Введите URL кнопки',
        type: FormFieldsTypes.INPUT,
    },
    {
        name: 'buttonText',
        label: 'Текст кнопки',
        placeHolder: 'Введите текст кнопки',
        type: FormFieldsTypes.INPUT,
    },
    {
        name: 'modalTitle',
        label: 'Заголовок модального окна',
        placeHolder: 'Введите заголовок модального окна',
        type: FormFieldsTypes.INPUT,
    },
    {
        name: 'modalText',
        label: 'Текст модального окна',
        placeHolder: 'Введите текст модального окна',
        type: FormFieldsTypes.INPUT,
    },
    {
        name: 'modalButtonUrl',
        label: 'URL кнопки модального окна',
        placeHolder: 'Введите URL кнопки модального окна',
        type: FormFieldsTypes.INPUT,
    },
    {
        name: 'modalButtonText',
        label: 'Текст кнопки модального окна',
        placeHolder: 'Введите текст кнопки модального окна',
        type: FormFieldsTypes.INPUT,
    },
]
