import { Feedback, Navigation } from '@libs/components';
import { useEffect } from 'react';
import { useAuthorize } from '@libs/utils';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from '@libs/hooks';

import { DatashopTitle } from './components/DatashopTitle';

export const MainPage = () => {
    const { isAuthorized } = useAuthorize();
    const navigate = useNavigate();
    const { anyAccess } = usePermissions();

    useEffect(() => {
        if (window.location.pathname === '/' && isAuthorized && anyAccess) {
            navigate('/notifications');
        }
    }, [isAuthorized, anyAccess]);

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <DatashopTitle accessDenied={isAuthorized} />
                    <Feedback />
                </div>
            </div>
        </>
    );
};
