import classNames from 'classnames';
import emptyAvatar from '@shared/images/personalAccount/svg/empty_avatar.svg';
import { ReactComponent as RemoveIcon } from '@shared/images/personalAccount/svg/remove_icon.svg';
import React, { type ChangeEvent, useRef } from 'react';

import styles from './styles.module.scss';

type ChangeAvatarProps = {
    img?: string;
    changeImageHandler: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
    removeImage: () => void;
};

export const ChangeAvatar = ({ img, changeImageHandler, removeImage }: ChangeAvatarProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div className={classNames('flex', 'align-items-center', styles.avatarContainer)}>
            <img src={img || emptyAvatar} />
            {img && (
                <div className={classNames('flex', 'align-items-end', styles.buttonContainer)}>
                    <RemoveIcon />
                    <p className={classNames(styles.loadButton)} onClick={removeImage}>
                        Удалить
                    </p>
                </div>
            )}
            <input
onChange={changeImageHandler} ref={inputRef} type={'file'}
accept="image/*" />
        </div>
    );
};
