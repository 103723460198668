export const subscriptionValues = {
    productName: 'Наименование продукта',
    tariffId: 'Тариф',
    status: 'Статус',
    startDate: 'Действует с',
    endDate: 'Действует по',
    createdDate: 'Создана',
    cost: 'Цена',
    vatCost: 'НДС',
    orderNumber: 'Номер заказа',
    flagManual: 'Признак ручной правки',
};

export const subscriptionColumns = Object.entries(subscriptionValues).map(([key, value]) => ({
    field: key,
    header: value,
}));
