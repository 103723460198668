import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { RegularButton } from '../components/RegularButton/Button';
import styles from './ButtonModal.module.scss';
import general from '../styles/general.module.scss';

type ButtonModalProps = {
    isVisible: boolean;
    onClose: () => void;
    onAddButton: (buttonData: {
        buttonText: string;
        buttonLink: string;
        backgroundColor: string;
        textColor: string;
    }) => void;
};

const ButtonModal: React.FC<ButtonModalProps> = ({ isVisible, onClose, onAddButton }) => {
    const { t } = useTranslation();
    const [buttonText, setButtonText] = useState<string>('');
    const [buttonLink, setButtonLink] = useState<string>('');
    const [backgroundColor, setBackgroundColor] = useState<string>('#CCCCCC');
    const [textColor, setTextColor] = useState<string>('#000000');

    if (!isVisible) {
        return null;
    }

    const handleAddButton = () => {
        onAddButton({ buttonText, buttonLink, backgroundColor, textColor });
        closeModal();
    };

    const closeModal = () => {
        setButtonText('');
        setButtonLink('');
        setBackgroundColor('#CCCCCC');
        setTextColor('#000000');
        onClose();
    };

    const handleBackgroundColor = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        if (value === '#' || /^#[0-9A-Fa-f]{0,6}$/i.test(value)) {
            setBackgroundColor(value);
        }
    };

    const handleTextColor = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        if (value === '#' || /^#[0-9A-Fa-f]{0,6}$/i.test(value)) {
            setTextColor(value);
        }
    };

    return (
        <div className={classNames(general.modal, styles.button_modal)}>
            <div className={classNames(general.modal_content, styles.modal_content)}>
                <h2>{t('editor.buttonModal.title')}</h2>
                <span className={general.close_button} onClick={closeModal}>
                    &times;
                </span>
                <label>
                    {t('editor.buttonModal.text')}:
                    <input type="text" value={buttonText} onChange={(e) => setButtonText(e.target.value)} />
                </label>
                <label>
                    {t('editor.buttonModal.link')}:
                    <input type="text" value={buttonLink} onChange={(e) => setButtonLink(e.target.value)} />
                </label>
                <label>
                    {t('editor.buttonModal.backgroundColor')}:
                    <div className={styles.inputs}>
                        <input
                            type="color"
                            value={backgroundColor}
                            onChange={(e) => setBackgroundColor(e.target.value)}
                            className={styles.color}
                        />
                        <input
                            type="text"
                            value={backgroundColor}
                            onChange={handleBackgroundColor}
                            maxLength={7}
                            className={general.hex_input}
                        />
                    </div>
                </label>
                <label>
                    {t('editor.buttonModal.color')}:
                    <div className={styles.inputs}>
                        <input
                            type="color"
                            value={textColor}
                            onChange={(e) => setTextColor(e.target.value)}
                            className={styles.color}
                        />
                        <input
                            type="text"
                            value={textColor}
                            onChange={handleTextColor}
                            maxLength={7}
                            className={general.hex_input}
                        />
                    </div>
                </label>
                <div>
                    <RegularButton label={t('editor.buttons.cancel')} onClick={closeModal} />
                    <RegularButton label={t('editor.buttons.add')} onClick={handleAddButton} />
                </div>
            </div>
        </div>
    );
};

export default ButtonModal;
