import { AvatarFileType } from '../userprofile/avatar.type';
import { EmailType } from '../userprofile/email.type';

const adminRolePostfix = process.env.REACT_APP_ADMIN_ROLE_POSTFIX;

export type UserProfileType = {
    createdDate?: string;
    lastUpdateDate?: string;
    fullName: string;
    phoneNumber?: string;
    version: number;
    isEmailVerified?: boolean;
    email?: string;
    photoUrl?: AvatarFileType;
    identificationLevel?: 'NOT_IDENTIFIED' | 'IDENTIFIED';
    personalDataProvided: boolean;
};

export type UserProfileUpdateResponseType = {
    version: number;
    additionalName: string;
    emails: Array<EmailType>;
    photoUrl?: AvatarFileType;
};

export const AdminPermissions = {
    DS_ROOT: adminRolePostfix ? `ds_root_${adminRolePostfix}` : 'ds_root',
    DS_ADMIN: adminRolePostfix ? `ds_admin_${adminRolePostfix}` : 'ds_admin',
    DS_ADMIN_VIEW: adminRolePostfix ? `ds_view_${adminRolePostfix}` : 'ds_view',
    DS_MARKET_ADMIN: adminRolePostfix ? `ds_market_${adminRolePostfix}` : 'ds_market',
}

export type AdminPermissionsResponse = {
    groups: string[];
};
