import { usePermissions } from '@libs/hooks';
import { useAuthorize } from '@libs/utils';
import { type RootState } from '@store/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const PrivateRoute = (props: { component: JSX.Element }) => {
    const { isAuthorized } = useAuthorize();
    const navigate = useNavigate();
    const { anyAccess } = usePermissions();

    const isLoading = useSelector((state: RootState) => state.user.isLoading);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;

        if (!isAuthorized || (!isLoading && !anyAccess)) {
            timeoutId = setTimeout(() => {
                if (!isAuthorized || (!isLoading && !anyAccess)) {
                    navigate('/');
                }
            }, 200);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isAuthorized, isLoading, anyAccess, navigate]);

    if (isAuthorized) {
        return props.component;
    } else {
        return null;
    }
};
