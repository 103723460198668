import React, { useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { RegularButton } from '../components/RegularButton/Button';
import styles from './BigCarouselUploadModal.module.scss';
import general from '../styles/general.module.scss';

type BigCarouselUploadModalProps = {
    isVisible: boolean;
    onClose: () => void;
    onUpload: (
        images: string[],
        texts: string[][],
        position: 'left' | 'center' | 'right',
        width: number,
        height: number,
    ) => void;
};

const BigCarouselUploadModal: React.FC<BigCarouselUploadModalProps> = ({ isVisible, onClose, onUpload }) => {
    const { t } = useTranslation();
    const [images, setImages] = useState<string[]>(['']);
    const [texts, setTexts] = useState<string[][]>([['', '', '']]);
    const [position, setPosition] = useState<'left' | 'center' | 'right'>('left');
    const [width, setWidth] = useState<number>(60);
    const [height, setHeight] = useState<number>(30);

    const handleImageUpload = (event: any, index: number) => {
        const file = event.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const newImages = [...images];
                newImages[index] = reader.result as string;
                setImages(newImages);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>, imgIndex: number, textIndex: number) => {
        const newTexts = [...texts];
        newTexts[imgIndex][textIndex] = event.target.value;
        setTexts(newTexts);
    };

    const handleAddImageTextPair = () => {
        setImages([...images, '']);
        setTexts([...texts, ['', '', '']]);
    };

    const handleUploadClick = () => {
        onUpload(images, texts, position, width, height);
        handleClose();
    };

    const handleClose = () => {
        setImages(['']);
        setTexts([['', '', '']]);
        setWidth(60);
        setHeight(30);
        setPosition('left');
        onClose();
    };

    if (!isVisible) {
        return null;
    }

    const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = Number(e.target.value);

        if (value >= 1 && value <= 100) {
            setWidth(+e.target.value);
        } else if (value < 1) {
            setWidth(1);
        } else setWidth(100);
    };

    const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = Number(e.target.value);

        if (value >= 1 && value <= 100) {
            setHeight(+e.target.value);
        } else if (value < 1) {
            setHeight(1);
        } else setHeight(100);
    };

    return (
        <div className={classNames(general.modal, styles.modal)}>
            <div className={general.modal_content}>
                <span className={general.close_button} onClick={handleClose}>
                    &times;
                </span>
                <h2>{t('editor.bigCarouselModal.title')}</h2>
                <div className={styles.block}>
                    {images.map((image, imgIndex) => (
                        <div className={general.FileUploadBlock} key={imgIndex}>
                            <FileUpload
                                name={`image${imgIndex}`}
                                accept="image/*"
                                customUpload
                                uploadHandler={(event) => handleImageUpload(event, imgIndex)}
                                emptyTemplate={<div className="drag-and-drop">{t('editor.fileUpload.DD')}</div>}
                                auto
                                chooseLabel={t('editor.fileUpload.button')}
                            />
                            <div className={general.texts}>
                                {texts[imgIndex].map((text, textIndex) => (
                                    <textarea
                                        key={textIndex}
                                        value={text}
                                        onChange={(event) => handleTextChange(event, imgIndex, textIndex)}
                                        placeholder={`${t('editor.bigCarouselModal.text')} ${textIndex + 1}`}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.buttons}>
                    <RegularButton label={t('editor.bigCarouselModal.button')} onClick={handleAddImageTextPair} />
                    <div className={styles.options}>
                        <label>
                            <input
                                type="radio"
                                value="left"
                                checked={position === 'left'}
                                onChange={() => setPosition('left')}
                            />
                            {t('editor.left')}
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="center"
                                checked={position === 'center'}
                                onChange={() => setPosition('center')}
                            />
                            {t('editor.center')}
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="right"
                                checked={position === 'right'}
                                onChange={() => setPosition('right')}
                            />
                            {t('editor.right')}
                        </label>
                    </div>
                    <div className={general.size_options}>
                        <label>
                            {t('editor.width')}:
                            <input type="number" value={width} onChange={handleWidthChange} />
                        </label>
                        <label>
                            {t('editor.height')}:
                            <input type="number" value={height} onChange={handleHeightChange} />
                        </label>
                    </div>
                    <RegularButton label={t('editor.buttons.add')} onClick={handleUploadClick} />
                </div>
            </div>
        </div>
    );
};

export default BigCarouselUploadModal;
