import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';
import { 
  GetPermissionsRequestType,
  PermissionData, 
  PermissionByIdResponseType, 
  PermissionActionResponseType, 
  PermissionsResType
} from './types';

export const PERMISSIONS_URL = '/moex-datashop-datashopservice/api/admin/v1/product-permissions';

export const getPermissions = async (
  data: GetPermissionsRequestType
): Promise<AxiosResponse<PermissionsResType>> => await httpClient.post(`${PERMISSIONS_URL}/search`, data);

export const getPermissionById = async (
  id: string
): Promise<AxiosResponse<PermissionByIdResponseType>> => {
  return await httpClient.get(`${PERMISSIONS_URL}/${id}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    data: {},
  },);
};

export const createPermission = async (
  data: PermissionData
): Promise<AxiosResponse<PermissionActionResponseType>> => await httpClient.post(`${PERMISSIONS_URL}`, data);

export const updatePermission = async (
  id: string, data: PermissionData
): Promise<AxiosResponse<PermissionActionResponseType>> => await httpClient.post(`${PERMISSIONS_URL}/${id}`, data);

export const deletePermission = async (
  id: string
): Promise<AxiosResponse<void>> => await httpClient.delete(`${PERMISSIONS_URL}/${id}`, {
  headers: {
    'Content-Type': 'application/json'
  },
  data: {},
});
