export const themes = {
    light: {
        // main
        'ds-main-black': '#18191b',
        'ds-main-white': '#ffffff',
        'ds-main-red': '#ff0508',
        'ds-main-red-orange': '#ff3705',
        'ds-main-gray': '#88919a',
        'ds-main-light-gray': '#d8e1eb',
        'ds-main-dark-gray': '#575e66',
        'ds-main-blue': '#388af7',

        // background
        'ds-bg-light-gray': '#ebf0f5',
        'ds-bg-extra-light-gray': '#f5f7fa',
        'ds-bg-blue-grey': '#B8CFD6',
        'ds-bg-pale-blue': '#edf6fa',
        'ds-bg-green': '#3a9e4a',
        'ds-bg-yellow': '#FFBF00',
        'ds-bg-blue': '#32BBFF',
        'ds-bg-dark-blue': '#2F9CEB',
        'ds-bg-light-blue': '#388af70d',
        'ds-bg-light-red': '#FF05080A',
        'ds-bg-light-green': '#3A9E4A0D',

        // border
        'ds-border-gray': '#d8e1eb',
        'ds-border-dark-gray': '#becdd3',

        //?
        'ds-primary-main-black-200': '#e8e9ec',
        'ds-dtsip-text-black-100': '#14171F',

        //error color
        'ds-error-red': '#FF3300',
        'chart-color': 'linear-gradient(180deg, rgba(255, 5, 8, 0.05) 0.13%, rgba(255, 5, 8, 0) 59.18%)',

        //placeholder
        'ds-placeholder': '#8B909A',
    },
    dark: {
        // main
        'ds-main-black': '#e7e6e4',
        'ds-main-white': '#000000',
        'ds-main-red': '#00faf7',
        'ds-main-red-orange': '#00c8fa',
        'ds-main-gray': '#776e65',
        'ds-main-light-gray': '#271e14',
        'ds-main-dark-gray': '#a8a199',
        'ds-main-blue': '#c77508',

        // background
        'ds-bg-light-gray': '#140f0a',
        'ds-bg-extra-light-gray': '#0a0805',
        'ds-bg-blue-grey': '#473029',
        'ds-bg-pale-blue': '#120905',
        'ds-bg-green': '#c561b5',
        'ds-bg-yellow': '#0040ff',
        'ds-bg-blue': '#cd4400',
        'ds-bg-dark-blue': '#d06314',

        // border
        'ds-border-gray': '#271e14',
        'ds-border-dark-gray': '#41322c',

        //?
        'ds-primary-main-black-200': '#171613',
        'ds-dtsip-text-black-100': '#ebe8e0',

        //error color
        'ds-error-red': '#00ccff',

        //placeholder
        'ds-placeholder': '#E7DECE',
    },
};
