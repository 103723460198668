import classNames from 'classnames';
import { useAuthorize } from '@libs/utils';
import { ReactComponent as LogOutIcon } from '@shared/images/svg/LogOutIcon.svg';

import styles from './styles.module.scss';

export const DropdownUserPanel = () => {
    const { logoutHandler } = useAuthorize();

    const logoutWithUnsub = () => {
        logoutHandler();
    };

    const liClassString = ['flex', 'align-items-center'];

    return (
        <ul className={styles.functionList}>
            <li onClick={logoutWithUnsub} className={classNames(liClassString, styles.logoutWrapper)}>
                <LogOutIcon />
                <p>Выйти</p>
            </li>
        </ul>
    );
};
