import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputTextarea } from 'primereact/inputtextarea';
import CustomFileUpload from '@libs/components/data/CustomFileUpload';
import { Checkbox, PaginationController } from '@libs/components';
import { HasAccess } from '@libs/components/access';
import { usePermissions } from '@libs/hooks';
import { BackArrow } from '@libs/components/pageBlocks/Navigation/BackArrow';

import styles from './styles.module.scss';
import { useProductPageFacade } from './useProductPageFacade';
import { categoryOptions, productStatusOptions, statusOptions, tableColumns } from './constants';

export const ProductPage = () => {
    const {
        control,
        tariffs,
        isDirty,
        isValid,
        handleSubmit,
        handleTableRowClick,
        tariffsFilters,
        setTariffsFilters,
        image,
        cartImage,
        handleClearFile,
        handleUploadFile,
        onSubmit,
        currentPage,
        totalPages,
        setCurrentPage,
        sortField,
        sortOrder,
        handleSort,
    } = useProductPageFacade();
    const { isAdmin, rootAdminPermissions } = usePermissions();

    return (
        <div className={classNames(styles.form, 'flex', 'flex-column')}>
            <div className={classNames('flex', 'flex-column', styles.formFields)}>
                <span className={classNames(styles.title)}>
                    <BackArrow />
                    Редактирование продукта
                </span>
                <Controller
                    control={control}
                    name="id"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="id">ID</label>
                            <InputNumber
                                {...field}
                                className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                value={field.value}
                                disabled
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="mnemoCode"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="mnemoCode">Мнемокод</label>
                            <InputText
                                {...field}
                                className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                value={field.value}
                                disabled
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="status"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="status">Статус</label>
                            <Dropdown
                                {...field}
                                options={productStatusOptions}
                                optionLabel="name"
                                placeholder="Статус"
                                optionValue="code"
                                className={classNames(styles.dropdownInput)}
                                disabled={!isAdmin}
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="name"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="name">Название</label>
                            <InputText
                                {...field}
                                className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                value={field.value}
                                disabled={!isAdmin}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                            )}
                        </div>
                    )}
                />
                <div className="flex flex-column gap-2">
                    <label htmlFor={'cartImage'}>Логотип</label>
                    <CustomFileUpload
                        onUpload={(e) => handleUploadFile(e, 'cartImage')}
                        image={cartImage}
                        onClear={() => handleClearFile('cartImage')}
                        disabled={!isAdmin}
                    />
                </div>

                <div className="flex flex-column gap-2">
                    <label htmlFor={'image'}>Изображение</label>
                    <CustomFileUpload
                        onUpload={(e) => handleUploadFile(e, 'image')}
                        image={image}
                        onClear={() => handleClearFile('image')}
                        disabled={!isAdmin}
                    />
                </div>
                <Controller
                    control={control}
                    name="detail"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="detail">Описание</label>
                            <InputTextarea
                                {...field}
                                id="detail"
                                rows={5}
                                className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                disabled={!isAdmin}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                            )}
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="landingPage"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="landingPage">Адрес страницы лендинга</label>
                            <InputText
                                {...field}
                                className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                value={field.value}
                                disabled={!isAdmin}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                            )}
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="referencePage"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="referencePage">Адрес страницы справки</label>
                            <InputText
                                {...field}
                                className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                value={field.value}
                                disabled={!isAdmin}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                            )}
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="isIdentityRequired"
                    defaultValue={false}
                    render={({ field, fieldState }) => (
                        <div className={classNames('flex', 'gap-2', 'align-items-center')}>
                            <label htmlFor="isIdentityRequired">Требуется индентификация для покупки</label>
                            <Checkbox checked={field.value as boolean} onChange={field.onChange} disabled={!isAdmin} />
                        </div>
                    )}
                />
                <HasAccess permissions={rootAdminPermissions}>
                    <div className={classNames('flex', styles.buttonsContainer)}>
                        <Button severity="secondary" outlined onClick={() => window.location.reload()}>
                            Отмена
                        </Button>
                        <Button
                            severity="secondary"
                            outlined
                            disabled={!isDirty || !isValid}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Сохранить
                        </Button>
                    </div>
                </HasAccess>

                <div className={classNames('flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>Тарифы в продукте</span>
                    <div className={classNames('flex', 'align-items-center', styles.filterGroup)}>
                        <Dropdown
                            onChange={(e) => setTariffsFilters((prevState) => ({
                                    ...prevState,
                                    status: e.value,
                                }))}
                            options={statusOptions}
                            optionLabel="name"
                            placeholder="Статус"
                            optionValue="code"
                            disabled={!isAdmin}
                            value={tariffsFilters?.status}
                            showClear
                        />
                        <Dropdown
                            onChange={(e) => setTariffsFilters((prevState) => ({
                                    ...prevState,
                                    clientCategory: e.value,
                                }))}
                            options={categoryOptions}
                            optionLabel="name"
                            placeholder="Категория клиента"
                            optionValue="code"
                            value={tariffsFilters?.clientCategory}
                            showClear
                        />
                    </div>
                </div>
                <DataTable
                    value={tariffs}
                    className={classNames(styles.table)}
                    onRowClick={handleTableRowClick}
                    emptyMessage={'Тарифы отсутствуют'}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={handleSort}
                >
                    {tableColumns.map((column) => (
                        <Column
                            key={column.field}
                            field={column.field}
                            header={column.header}
                            alignHeader="center"
                            className={classNames('text-center')}
                            sortable={column.field !== 'guid'}
                        />
                    ))}
                </DataTable>
                <div className={classNames('flex', 'justify-content-center')}>
                    <PaginationController
                        total={totalPages}
                        current={currentPage}
                        setAsyncCurrent={(e) => setCurrentPage?.(e)}
                    />
                </div>
            </div>
        </div>
    );
};
