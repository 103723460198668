import React from 'react';
import { Feedback, Footer, Navigation } from '@libs/components';

import { MainImageContainer } from './components/MainImageContainer';
import { MainContent } from './components/MainContent';

export const PersonalAccount = () => {
    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <MainImageContainer />
                    <MainContent />
                    <Feedback />
                </div>
            </div>
            <Footer />
        </>
    );
};
