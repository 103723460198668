export type DynamicConfig = {
    [key: string]: string;
    environment: 'DEV' | 'PROD';
}

export const defaultConfig: DynamicConfig = {
    REACT_APP_TEST: 'test message',
    environment: 'DEV',
};

export const dynamicConfigUrl = 'config.json';
