import React, { useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { RegularButton } from '../components/RegularButton/Button';
import styles from './styles.module.scss';
import general from '../styles/general.module.scss';

type CarouselUploadModalProps = {
    isVisible: boolean;
    onClose: () => void;
    onUpload: (data: { urls: string[]; position: 'left' | 'center' | 'right'; width: string; height: string }) => void;
};

const CarouselUploadModal: React.FC<CarouselUploadModalProps> = ({ isVisible, onClose, onUpload }) => {
    const { t } = useTranslation();
    const [uploadedUrls, setUploadedUrls] = useState<string[]>([]);
    const [position, setPosition] = useState<'left' | 'center' | 'right'>('left');
    const [width, setWidth] = useState(30);
    const [height, setHeight] = useState(30);

    const handleFileUpload = (event: any) => {
        const { files } = event;
        const urls: string[] = [];
        let filesRead = 0;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            reader.onload = () => {
                urls.push(reader.result as string);
                filesRead++;

                if (filesRead === files.length) {
                    setUploadedUrls(urls);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUploadClick = () => {
        if (uploadedUrls.length > 0) {
            onUpload({ urls: uploadedUrls, position, width: `${width}vw`, height: `${height}vw` });
            setUploadedUrls([]);
            handleClose();
        }
    };

    const handleClose = () => {
        setUploadedUrls([]);
        setPosition('left');
        setWidth(30);
        setHeight(30);
        onClose();
    };

    if (!isVisible) {
        return null;
    }

    const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = Number(e.target.value);

        if (value >= 1 && value <= 100) {
            setWidth(+e.target.value);
        } else if (value < 1) {
            setWidth(1);
        } else setWidth(100);
    };

    const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = Number(e.target.value);

        if (value >= 1 && value <= 100) {
            setHeight(+e.target.value);
        } else if (value < 1) {
            setHeight(1);
        } else setHeight(100);
    };

    return (
        <div className={general.modal}>
            <div className={classNames(general.modal_content, styles.content)}>
                <span className={general.close_button} onClick={handleClose}>
                    &times;
                </span>
                <h2>{t('editor.carouselModal.title')}</h2>
                <div className={general.FileUploadBlock}>
                    <FileUpload
                        name="image"
                        accept="image/*"
                        customUpload
                        uploadHandler={handleFileUpload}
                        chooseLabel={t('editor.carouselModal.button')}
                        mode={'advanced'}
                        emptyTemplate={<div className={general.drag_and_drop}>{t('editor.carouselModal.DD')}</div>}
                        auto
                        multiple
                    />
                </div>
                <div className={styles.position_options}>
                    <label>
                        <input
                            type="radio"
                            value="left"
                            checked={position === 'left'}
                            onChange={() => setPosition('left')}
                        />
                        {t('editor.left')}
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="center"
                            checked={position === 'center'}
                            onChange={() => setPosition('center')}
                        />
                        {t('editor.center')}
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="right"
                            checked={position === 'right'}
                            onChange={() => setPosition('right')}
                        />
                        {t('editor.right')}
                    </label>
                </div>
                <div className={general.size_options}>
                    <label>
                        {t('editor.width')}:
                        <input type="number" value={width} onChange={handleWidthChange} />
                    </label>
                    <label>
                        {t('editor.height')}:
                        <input type="number" value={height} onChange={handleHeightChange} />
                    </label>
                </div>
                <RegularButton label={t('editor.buttons.add')} onClick={handleUploadClick} />
            </div>
        </div>
    );
};

export default CarouselUploadModal;
