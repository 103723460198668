import { useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '@shared/images/svg/ArrowLeftGrey.svg';

import styles from './styles.module.scss';

export const BackArrow = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className={styles.wrapper}>
            <button className={styles.inner} onClick={handleBack}>
                <Arrow />
            </button>
        </div>
    );
};
