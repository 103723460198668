import { type RefObject, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { omitNull } from '@libs/utils';
import {
    type Control,
    useForm,
    type FieldValues,
    type UseFormSetValue,
    type UseFormWatch,
    type UseFormTrigger,
    type UseFormHandleSubmit,
} from 'react-hook-form';
import { type DatashopTariff, type Product } from '@libs/types';
import { getProducts, getTariffs } from '@modules/AdminPage/services';
import { format, parseISO } from 'date-fns';

import { createSubscription, getSubscriptionById, updateSubscription } from '../services';
import { SubscriptionInfo, type PostSubscriptionRequestType } from '../services/types';
import { SubscribeInfo } from '@libs/types/subscribe/subscribe.type';
import { useDispatch } from 'react-redux';
import { addCreationSuccessToast, addErrorToast, addToast, addUpdateSuccessToast } from '@store/store/slices/toast.slice';

type UseSubscriptionPageFacadeProps = {
    isEdit: boolean;
};

type UseSubscriptionPageFacadeResult = {
    control: Control<FieldValues, any>;
    products?: Product[];
    tariffs?: DatashopTariff[];
    fetchTariffs: (productGuid: string) => void;
    setValue: UseFormSetValue<FieldValues>;
    watch: UseFormWatch<FieldValues>;
    trigger: UseFormTrigger<FieldValues>;
    isDirty: boolean;
    handleSubmit: UseFormHandleSubmit<FieldValues, undefined>;
    isValid: boolean;
    onSubmit: (data: any) => void;
    inputRef: RefObject<HTMLInputElement>;
    isFormDisabled: boolean;
};

export const useSubscriptionPageFacade = (props: UseSubscriptionPageFacadeProps): UseSubscriptionPageFacadeResult => {
    const { isEdit } = props;

    const {
        control,
        watch,
        reset,
        setValue,
        trigger,
        handleSubmit,
        formState: { isDirty, isValid },
    } = useForm({
        mode: 'onChange',
    });

    const { subscriptionId } = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const inputRef = useRef<HTMLInputElement>(null);

    const onSubmit = async (data: SubscriptionInfo) => {
        try {
            if (!isEdit) {
                await createSubscription({
                    ...data,
                    startDate: data.startDate && format(data.startDate, 'yyyy-MM-dd'),
                    endDate: data.endDate && format(data.endDate, 'yyyy-MM-dd'),
                    userId: data.userGuid,
                }).then((res) => {
                    dispatch(addCreationSuccessToast());
                    if (res?.data?.data?.id) {
                      navigate(`/subscriptions/${res.data.data.id}`);
                    }
                });
            } else {
                if (subscriptionId) {
                    await updateSubscription({
                        ...data,
                        subscriptionId,
                        startDate: data.startDate && format(data.startDate, 'yyyy-MM-dd'),
                        endDate: data.endDate && format(data.endDate, 'yyyy-MM-dd'),
                    }).then(() => {
                        dispatch(addUpdateSuccessToast());
                      }
                    );
                }
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch(addErrorToast((error as { message: string }).message));
        }
    };

    const [products, setProducts] = useState<Product[]>();
    const [tariffs, setTariffs] = useState<DatashopTariff[]>();

    const fetchTariffs = async (productGuid: string) => {
        try {
            const data = await getTariffs({ productGuid, pageable: {} });
            setTariffs(data.data.data.rows);
        } catch (error) {
            console.log('error: ', error);
        }
    };

    useEffect(() => {
        const fetchSubscription = async () => {
            try {
                if (subscriptionId && isEdit) {
                    const data = await getSubscriptionById(subscriptionId);

                    reset(
                        omitNull({
                            ...data.data.data,
                            startDate: data.data.data.startDate ? parseISO(data.data.data.startDate) : null,
                            endDate: data.data.data.endDate ? parseISO(data.data.data.endDate) : null,
                        }),
                    );
                    fetchTariffs(data.data.data.productGuid);
                }
            } catch (error) {
                console.log('error: ', error);
            }
        };
        const fetchProducts = async () => {
            try {
                const data = await getProducts({ pageable: {} });
                setProducts(data.data.data.rows);
            } catch (error) {
                console.log('error: ', error);
            }
        };

        fetchSubscription();
        fetchProducts();
    }, []);

    return {
        control,
        products,
        tariffs,
        fetchTariffs,
        setValue,
        watch,
        onSubmit,
        handleSubmit,
        trigger,
        isDirty,
        isValid,
        inputRef,
        isFormDisabled: watch('flagManual') === false,
    };
};
