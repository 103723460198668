import { type Pageable, type Status, type Validation } from '@modules/AdminPage/services/types';

export const refundFields = {
  id: 'Id Возврата  внутренний',
  externalRefundId: 'Id Возврата внешний',
  productGuid: 'Id Продукта',
  invoiceId: 'Id Инвойса в Монета',
  status: 'Статус',
  amount: 'Сумма возврата',
  bankId: 'ID Банка',
  bankName: 'Название банка',
  createdDate: 'Дата создания',
  lastModifiedDate: 'Время последнего обновления',
};

export type RefundInfo = {
  id: string,
  externalRefundId: string,
  productGuid: string,
  invoiceId: string,
  status: string,
  amount: string,
  bankId: string,
  bankName: string,
  createdDate: string,
  lastModifiedDate: string,
  productName: string,
};

export type RefundByIdRequestType = {
    createdDate: string;
    data: RefundInfo;
    status: Status;
    validation: Validation;
}

export type RefundRowType = {
    data: RefundInfo;
}

export type RefundResType = {
    totalRows: number;
    totalPages: number;
    size: number;
    pageNumber: number;
    rows: RefundRowType[];
}

export type RefundsRequestType = {
  createdDate: string;
  status: Status;
  validation: Validation;
  data: RefundResType;
}

export type GetRefundsRequestType = {
  orderId?: string,
  pageable: Pageable;
};
