import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';

import { type GetOrdersRequestType, type OrderByIdRequestType, type OrdersRequestType } from './types';

export const ORDERS_URL = '/moex-datashop-orderservice/api/admin/v1/orders';

export const getOrders = async (requestData: GetOrdersRequestType): Promise<AxiosResponse<OrdersRequestType>> =>
    await httpClient.post(`${ORDERS_URL}/search`, requestData);

export const getOrderById = async (orderGuid: string): Promise<AxiosResponse<OrderByIdRequestType>> =>
    await httpClient.get(`${ORDERS_URL}/${orderGuid}`);

export const exportOrders = async (requestData: GetOrdersRequestType): Promise<AxiosResponse<Blob>> =>
    await httpClient.post(`${ORDERS_URL}/export`, { responseType: 'blob', ...requestData });

export const postOrderEvent = async (orderId: string, eventCode: string): Promise<AxiosResponse> =>
    await httpClient.post(`${ORDERS_URL}/${orderId}/events`, { event: eventCode });
