import { useSelector } from 'react-redux';
import type { RootState } from '@store/store';
import { type PropsWithChildren } from 'react';

type HasAccessProps = {
    permissions: string[];
    isExistEachPermission?: boolean;
    returnAsDiv?: boolean;
};

export const HasAccess = ({
    permissions,
    isExistEachPermission,
    returnAsDiv,
    children,
}: PropsWithChildren<HasAccessProps>) => {
    const adminPermissions = useSelector((state: RootState) => state.user.permissions);
    const permissionsSet = new Set<string>(adminPermissions);
    const checkPermissionCb = (permission: string) => permissionsSet.has(permission);
    const hasAccess = isExistEachPermission
        ? permissions.every(checkPermissionCb)
        : permissions.some(checkPermissionCb);

    if (!hasAccess) {
        if (returnAsDiv) {
            return <div />;
        }

        return <></>;
    }

    return <>{children}</>;
};
