import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { exportFileWithCSV } from '@libs/utils';
import { SortOrder, type DataTableRowClickEvent } from 'primereact/datatable';

import { NotificationsFiltersParams, type NotificationInfo } from '../services/types';
import { exportNotifications, getNotifications } from '../services';

type UseNotificationsListFacadeResult = {
  handleCreateClick: () => void;
  fetchExport: () => void;
  notifications?: NotificationInfo[];
  handleTableRowClick: (e: DataTableRowClickEvent) => void;
  totalPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setNotificationsFilters: React.Dispatch<React.SetStateAction<NotificationsFiltersParams | undefined>>;
  notificationsFilters?: NotificationsFiltersParams;
  sortField: string;
  sortOrder: SortOrder;
  handleSort: (e: any) => void;
}

export const useNotificationsListFacade = (): UseNotificationsListFacadeResult => {

    const navigate = useNavigate();

    const [notifications, setNotifications] = useState<NotificationInfo[]>();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState(0);
    const [notificationsFilters, setNotificationsFilters] = useState<NotificationsFiltersParams>();

    const [sortField, setSortField] = useState<string>('id');
    const [sortOrder, setSortOrder] = useState<SortOrder>(1);

    const fetchNotifications = async () => {
        try {
            const data = await getNotifications({
                ...notificationsFilters,
                pageable: { 
                  pageNumber: currentPage, 
                  pageSize: 25,
                  sortField: sortField,
                  sortOrder: sortOrder === 1 ? 'desc' : 'asc',
                },
            });
            setNotifications(data.data.data.rows);

            setTotalPages(data.data.data.totalPages);
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const handleSort = (e: any) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    };

    useEffect(() => {
        fetchNotifications();
    }, [notificationsFilters, currentPage, sortField, sortOrder]);

    const handleCreateClick = () => {
        navigate('/notifications/create');
    };

    const handleTableRowClick = (e: DataTableRowClickEvent) => {
        navigate(`/notifications/${e.data.id}`);
    };

    const fetchExport = async () => {
        try {
            const data = await exportNotifications({
                ...notificationsFilters,
                pageable: { 
                  pageNumber: currentPage, 
                  pageSize: 25,
                  sortField: sortField,
                  sortOrder: sortOrder === 1 ? 'desc' : 'asc',
                },
            });
            const blob = new Blob([data.data], { type: 'text/csv;charset=utf-8;' });
            exportFileWithCSV(blob, 'notifications');
        } catch (error) {
            console.log('error: ', error);
        }
    };

  return {
    handleCreateClick,
    fetchExport,
    notifications,
    handleTableRowClick,
    totalPages,
    currentPage,
    setCurrentPage,
    setNotificationsFilters,
    notificationsFilters,
    sortField,
    sortOrder,
    handleSort,
  };
}
