import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column, type ColumnBodyOptions } from 'primereact/column';
import { ConfirmModal, PaginationController } from '@libs/components';
import { userColumns } from '@modules/AdminPage/Users/const';
import { useUsersList } from '@modules/AdminPage/Users/UsersList/useUsersList';
import { format } from 'date-fns';
import { type UserInfo, UserStatus } from '@modules/AdminPage/Users/services/type';
import CustomAutoComplete from '@libs/components/data/CustomAutoComplete';
import { InputText } from 'primereact/inputtext';

import styles from './styles.module.scss';

const bodyTemplate = (data: UserInfo, options: ColumnBodyOptions) => {
    switch (options.field) {
        case 'createdAt':
            return data.createdAt && format(new Date(data.createdAt), 'dd.MM.yyyy');
        case 'updatedAt':
            return data.updatedAt && format(new Date(data.updatedAt), 'dd.MM.yyyy');
        default:
            return data[options.field as keyof UserInfo];
    }
};

const statusOptions = Object.keys(UserStatus).map((status) => ({
    label: status,
    value: status,
}));

const actions = [
    {
        label: 'Активировать',
        code: 'ACTIVE',
    },
    {
        label: 'Деактивировать',
        code: 'FROZEN',
    },
];

export const UsersList = () => {
    const {
        users,
        setUsersFilter,
        fetchExport,
        usersFilter,
        currentPage,
        setCurrentPage,
        totalPages,
        handleTableRowClick,
        selectedUsers,
        setSelectedUsers,
        selectedStatus,
        setSelectedStatus,
        isOpen,
        openModal,
        closeModal,
        updateUserStatusHandler,
        handleSelectUserFilter,
        isRowsDisabled,
        sortField,
        sortOrder,
        handleSort,
        handleInputChange,
        userInput,
    } = useUsersList();

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>Пользователи</span>
                    <div className={classNames('flex', 'align-items-center', styles.btnGroup)}>
                        <Dropdown
                            onChange={(e) => setUsersFilter((prevState) => ({
                                    ...prevState,
                                    status: e.value,
                                }))}
                            options={statusOptions}
                            optionLabel="label"
                            placeholder="Статус"
                            optionValue="value"
                            value={usersFilter?.status}
                            className={classNames(styles.filter)}
                            showClear
                        />
                        <div className={classNames(styles.userFilter)}>
                            <InputText
                                placeholder="Поиск пользователя"
                                value={userInput}
                                onChange={handleInputChange}
                                className={classNames(styles.userFilter)}
                            />
                        </div>

                        <Dropdown
                            onChange={(e) => {
                                setSelectedStatus(actions.find((item) => e.value === item.code));
                                openModal();
                            }}
                            options={actions}
                            optionLabel="label"
                            placeholder="Выбрать действие"
                            optionValue="code"
                            className={classNames(styles.filter)}
                            disabled={!selectedUsers.length}
                        />

                        <Button
                            severity="secondary"
                            outlined
                            className={classNames(styles.button)}
                            onClick={fetchExport}
                        >
                            Экспорт
                        </Button>
                    </div>
                </div>
                <DataTable
                    value={users}
                    rows={25}
                    className={classNames(styles.table)}
                    onRowClick={handleTableRowClick}
                    emptyMessage={'Пользователи отсутствуют'}
                    selectionMode="multiple"
                    selection={selectedUsers}
                    onSelectionChange={(e) => {
                        setSelectedUsers(e.value);
                    }}
                    dataKey="customerId"
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={handleSort}
                >
                    <Column selectionMode={isRowsDisabled ? undefined : 'multiple'} />
                    {userColumns.map((column) => (
                        <Column
                            key={column.field}
                            field={column.field}
                            header={column.header}
                            alignHeader="center"
                            className={classNames('text-center')}
                            body={bodyTemplate}
                            sortable
                        />
                    ))}
                </DataTable>

                <div className={classNames('flex', 'justify-content-center')}>
                    <PaginationController
                        total={totalPages}
                        current={currentPage}
                        setAsyncCurrent={(e) => setCurrentPage(e)}
                    />
                </div>
            </div>

            <ConfirmModal
                header={'Подтвердить действие'}
                confirmButtonText={'Подтвердить'}
                onConfirm={updateUserStatusHandler}
                onClose={closeModal}
                isVisible={isOpen}
                children={<p>Вы уверены что хотите перевести пользователей в статус {selectedStatus?.code}?</p>}
            />
        </div>
    );
};
