import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Content = {
    label: string;
    onClick: () => void;
    className?: string;
    active?: boolean;
    disabled?: boolean;
};

export const RegularButton: React.FC<Content> = ({ label, className, onClick, active, disabled }) => {
    return (
        <button
            className={classNames(styles.regular_button, className, { [styles.active_button]: active })}
            onClick={onClick}
            disabled={disabled}
        >
            {label}
        </button>
    );
};
