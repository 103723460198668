import { type Control, useFieldArray, Controller } from 'react-hook-form';
import { type DatashopTariff } from '@libs/types';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { ReactComponent as TrashIcon } from '@shared/images/personalAccount/svg/trash.svg';
import { Button } from 'primereact/button';
import { usePermissions } from '@libs/hooks';
import { HasAccess } from '@libs/components/access';

import styles from './styles.module.scss';

type TariffPlanProps = {
    billingTablesIndex: number;
    tariffPlansIndex: number;
    control: Control<DatashopTariff, any>;
};

export const TariffOptionsForm = (props: TariffPlanProps) => {
    const { control, billingTablesIndex, tariffPlansIndex } = props;
    const { isAdmin, rootAdminPermissions } = usePermissions();

    const { fields, append, remove } = useFieldArray({
        control,
        name: `billingTables.${billingTablesIndex}.tariffPlans.${tariffPlansIndex}.dopInfoOptions`,
    });

    return (
        <>
            {fields.map((el, planIndex) => (
                <Controller
                    key={el.id}
                    control={control}
                    name={`billingTables.${billingTablesIndex}.tariffPlans.${tariffPlansIndex}.dopInfoOptions.${planIndex}.name`}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="name">{`Опция ${planIndex + 1}`}</label>
                            <div className={classNames('flex', 'align-items-center')}>
                                <HasAccess permissions={rootAdminPermissions}>
                                    <TrashIcon className={styles.icon} onClick={() => remove(planIndex)} />
                                </HasAccess>
                                <InputText
                                    {...field}
                                    className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                    value={field.value}
                                    onChange={field.onChange}
                                    disabled={!isAdmin}
                                />
                            </div>
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                            )}
                        </div>
                    )}
                />
            ))}
            <HasAccess permissions={rootAdminPermissions}>
                <Button
                    disabled={!isAdmin}
                    severity="secondary"
                    className={styles.button}
                    outlined
                    onClick={() => append({ name: '' })}
                >
                    Добавить опцию
                </Button>
            </HasAccess>
        </>
    );
};
