import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmModal } from '@libs/components';
import { Button } from 'primereact/button';
import { BackArrow } from '@libs/components/pageBlocks/Navigation/BackArrow';

import styles from './styles.module.scss';
import { useOrderPageFacade } from './useOrderPageFacade';
import { orderFields, orderItemFields } from '../services/types';

export const tableColumns = [
    { field: 'paramName', header: 'Параметр' },
    { field: 'valueName', header: 'Значение' },
];

const actions = [
    {
        label: 'Чек отправлен вручную',
        code: 'RECEIPT_SENT_MANUALLY',
    },
    {
        label: 'Приостановить печать чека',
        code: 'PAUSE_RECEIPT_SENDING',
    },
    {
        label: 'Возобновить печать чека',
        code: 'RESUME_RECEIPT_SENDING',
    },
];

export const OrderPage = () => {
    const { order, setSelectedAction, isOpen, openModal, closeModal, eventHandler, handleRefund } =
        useOrderPageFacade();

    return (
        <div className={classNames(styles.form, 'flex', 'flex-column')}>
            <div className={classNames('flex', 'flex-column', styles.formFields)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>
                        <BackArrow />
                        Заказ
                    </span>
                    <div className={classNames('flex', 'align-items-center', styles.btnGroup)}>
                        <Dropdown
                            onChange={(e) => {
                                setSelectedAction(actions.find((item) => e.value === item.code));
                                openModal();
                            }}
                            options={actions}
                            optionLabel="label"
                            placeholder="Выбрать действие"
                            optionValue="code"
                            className={classNames(styles.filter)}
                        />
                        <Button severity="secondary" outlined onClick={handleRefund}>
                            Возвраты
                        </Button>
                    </div>
                </div>
                {order &&
                    Object.entries(order).map(([key, value]) => {
                        const keyName = key as keyof typeof orderFields;

                        if (orderFields[keyName]) {
                            return (
                                <div className={styles.field} key={keyName}>
                                    <label htmlFor={orderFields[keyName]}>{orderFields[keyName] || ''}</label>
                                    <div>
                                        <InputText
                                            style={{ width: 400 }}
                                            id={orderFields[keyName]}
                                            disabled
                                            value={value as string}
                                        />
                                    </div>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                <span className={classNames(styles.title)}>Продукты заказа</span>
                {order?.items.map((item) => {
                    return (
                        <div
                            className={classNames('flex', 'flex-column', styles.productFormFields)}
                            key={`${item.tariffGuid}-${item.productName}`}
                        >
                            {item &&
                                Object.entries(item).map(([key, value]) => {
                                    const keyName = key as keyof typeof orderItemFields;
                                    console.log(value);

                                    if (orderItemFields[keyName]) {
                                        return (
                                            <div className={styles.field} key={keyName}>
                                                <label htmlFor={orderItemFields[keyName]}>
                                                    {orderItemFields[keyName] || ''}
                                                </label>
                                                <div>
                                                    <InputText
                                                        style={{ width: 400 }}
                                                        id={orderItemFields[keyName]}
                                                        disabled
                                                        value={value as string}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            <label htmlFor={item.productName}>Параметры</label>
                            <DataTable
                                value={item.tariffParams}
                                className={classNames(styles.table)}
                                emptyMessage={'Параметры отсутствуют'}
                            >
                                {tableColumns.map((column) => (
                                    <Column
                                        key={column.field}
                                        field={column.field}
                                        header={column.header}
                                        alignHeader="center"
                                        className={classNames('text-center')}
                                    />
                                ))}
                            </DataTable>
                        </div>
                    );
                })}
            </div>

            <ConfirmModal
                header={'Подтвердить действие'}
                confirmButtonText={'Подтвердить'}
                onConfirm={eventHandler}
                onClose={closeModal}
                isVisible={isOpen}
                children={<p>Вы уверены?</p>}
            />
        </div>
    );
};
