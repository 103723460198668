import { type ReactNode, useCallback, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as Arrow } from '@shared/images/personalAccount/svg/arrow-top.svg';

import styles from './styles.module.scss';

type DropdownWrapperProps = {
    title: string;
    children: ReactNode;
};

export const DropdownWrapper = ({ title, children }: DropdownWrapperProps) => {
    const [isOpen, setOpen] = useState<boolean>(true);
    const toggleOpen = useCallback(() => setOpen((prev) => !prev), [isOpen]);

    return (
        <div className={classNames('flex', 'flex-column')}>
            <div onClick={toggleOpen} className={classNames(styles.titleWrapper, 'flex')}>
                <Arrow className={classNames(!isOpen && styles.hide)} />

                <p>{title}</p>
            </div>
            {isOpen && <div className={classNames(styles.contentContainer)}>{children}</div>}
            <div className={styles.greyLine} />
        </div>
    );
};
