import { Button } from 'primereact/button';
import callIcon from '@shared/images/personalAccount/svg/call_icon.svg';
import dataIcon from '@shared/images/personalAccount/svg/data_icon.svg';
import dataIconWhite from '@shared/images/personalAccount/svg/data_icon_white.svg';
import emptyAvatar from '@shared/images/personalAccount/svg/empty_avatar.svg';
import emailIcon from '@shared/images/personalAccount/svg/email_icon.svg';
import classNames from 'classnames';
import React, { useState } from 'react';
import { ModalWindow } from '@libs/components';
import { ReactComponent as ChangeAvatarIcon } from '@shared/images/personalAccount/svg/add_avatar_icon.svg';
import { useModal } from '@libs/utils';
import { ChangeEmailComponent } from '@modules/PersonalAccount/components/MainContent/components/AccountCard/components/ChangeEmailComponent';
import { GetUserDataForm } from '@modules/PersonalAccount/components/MainContent/components/AccountCard/components/GetUserDataForm';
import { ChangeAvatarForm } from '@modules/PersonalAccount/components/MainContent/components/AccountCard/components/ChangeAvatarForm';
import { RegistrationForm } from '@modules/PersonalAccount/components/MainContent/components/AccountCard/components/RegistrationForm';

import styles from './styles.module.scss';

type AccountCardProps = {
    imageStr?: string;
    fullName: string;
    phoneNumber?: string;
    isEmailVerified?: boolean;
    email?: string;
    personalDataProvided: boolean;
};

export const AccountCard = ({
    imageStr,
    fullName,
    phoneNumber,
    isEmailVerified,
    email,
    personalDataProvided,
}: AccountCardProps) => {
    const [isOpenEditModal, setOpenEditModal] = useState<boolean>(false);
    const hideModal = () => setOpenEditModal(false);

    // change avatar modal
    const { isOpen, openModal, closeModal } = useModal();

    //  registration modal
    const {
        isOpen: isRegistrationOpen,
        openModal: openRegistrationModal,
        closeModal: closeRegistrationModal,
    } = useModal();

    return (
        <div className={classNames(styles.accountCardWrapper)}>
            <div className={classNames(styles.blueTop)} />
            <div className={classNames(styles.cardInnerWrapper, 'flex', 'flex-column', 'align-items-center')}>
                <div role={'button'} onClick={openModal} className={classNames(styles.imgWrapper)}>
                    <img src={imageStr || emptyAvatar} />
                    <ChangeAvatarIcon />
                </div>
                <p>{fullName}</p>
                <div className={styles.greyLine} />
                <div className={classNames('inline-flex', styles.infoWrapper)}>
                    <img src={callIcon} />
                    <p>{phoneNumber || '-'}</p>
                </div>
                {email && isEmailVerified
? (
                    <div className={classNames('inline-flex', styles.infoWrapper)}>
                        <img src={emailIcon} />
                        <p>{email}</p>
                    </div>
                )
: (
                    <ChangeEmailComponent isEmailVerified={isEmailVerified} email={email || ''} />
                )}
                {personalDataProvided
? (
                    <Button
                        className={classNames(
                            'inline-flex',
                            'align-items-center',
                            'justify-content-center',
                            styles.editButton,
                        )}
                        onClick={() => setOpenEditModal(true)}
                    >
                        <img src={dataIcon} />
                        <span>Личные данные</span>
                    </Button>
                )
: (
                    <Button
                        className={classNames(
                            'inline-flex',
                            'align-items-center',
                            'justify-content-center',
                            styles.editButton,
                            styles.redBtn,
                        )}
                        onClick={openRegistrationModal}
                    >
                        <img src={dataIconWhite} />
                        <span>Указать данные</span>
                    </Button>
                )}
            </div>
            <ModalWindow className={styles.getInfoModal} closeModal={hideModal} isVisible={isOpenEditModal}>
                <GetUserDataForm closeOuterModal={hideModal} />
            </ModalWindow>
            <ModalWindow closeModal={closeModal} isVisible={isOpen} className={styles.modalWrapper}>
                <ChangeAvatarForm closeModal={closeModal} />
            </ModalWindow>
            <ModalWindow
                closeModal={closeRegistrationModal}
                isVisible={isRegistrationOpen}
                className={styles.modalWrapper}
            >
                <RegistrationForm closeModal={closeRegistrationModal} />
            </ModalWindow>
        </div>
    );
};
