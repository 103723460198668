import { type AxiosResponse } from 'axios';
import { GetUserArgs, UpdateUserStatusReq, UsersInfoResponse } from '@modules/AdminPage/Users/services/type';
import { httpClient } from '@configs/httpclient.config';

export const PROFILE_URL = '/moex-datashop-profileservice/api/admin/userprofile/v1';

export const getUsers = (args: GetUserArgs): Promise<AxiosResponse<UsersInfoResponse>> =>
    httpClient.post(`${PROFILE_URL}/search`, args);

export const getUser = (args: string): Promise<AxiosResponse<any>> =>
    httpClient.get(`${PROFILE_URL}/${args}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        data: {},
    });

export const exportUsers = (requestData: GetUserArgs): Promise<AxiosResponse<Blob>> =>
    httpClient.post(`${PROFILE_URL}/export`, { responseType: 'blob', ...requestData });

export const updateUserStatus = (body: UpdateUserStatusReq): Promise<AxiosResponse<any>> =>
    httpClient.post(`${PROFILE_URL}/update-status`, body);
