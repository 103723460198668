import classNames from 'classnames';
import { Button } from 'primereact/button';
import { BackArrow } from '@libs/components/pageBlocks/Navigation/BackArrow';

import { createNotificationPageFields } from './constants';
import { type FormControlType } from '../services/types';
import styles from './../NotificationEditPage/styles.module.scss';
import { useNotificationsСreateFacade } from './useNotificationCreateFacade';

export const NotificationСreatePage = () => {
    const { renderItem, handleSubmit, onSubmit, isValid, isSubmitting } = useNotificationsСreateFacade();

    return (
        <div className={classNames(styles.form, 'flex', 'flex-column')}>
            <div className={classNames('flex', 'flex-column', styles.formFields)}>
                <span className={classNames(styles.title)}>
                    <BackArrow />
                    Создание уведомления
                </span>
                {createNotificationPageFields.map((field: FormControlType) => renderItem(field))}
                <div className={classNames('flex', 'align-item-center', styles.buttonsContainer)}>
                    <Button severity="secondary" outlined onClick={() => window.location.reload()}>
                        Отмена
                    </Button>
                    <Button
                        severity="secondary"
                        outlined
                        onClick={handleSubmit(onSubmit)}
                        disabled={!isValid || isSubmitting}
                    >
                        Создать
                    </Button>
                </div>
            </div>
        </div>
    );
};
