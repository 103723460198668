import { ProgressSpinner } from 'primereact/progressspinner';

import styles from './styles.module.scss';

export const PageLoader = () => {
    return (
        <div className={styles.wrapper}>
            <ProgressSpinner />
        </div>
    );
};
