import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';
import { CreateReportRequestType, GetReportsRequestType, ReportByIdRequestType, ReportsRequestType } from './types';

export const REPORTS_URL = '/moex-datashop-reportservice/api/admin/v1/reports';

export const getReports = async (
  requestData: GetReportsRequestType
): Promise<AxiosResponse<ReportsRequestType>> => await httpClient.post(`${REPORTS_URL}/search`, requestData);

export const createReport = async (
  requestData: FormData
): Promise<AxiosResponse<CreateReportRequestType>> => await httpClient.post(`${REPORTS_URL}`, requestData);

export const getReportById = async (
  refundId: string,
): Promise<AxiosResponse<ReportByIdRequestType>> => await httpClient.get(`${REPORTS_URL}/${refundId}`);

export const exportReport = async (
  reportId: string
): Promise<AxiosResponse<Blob>> => await httpClient.get(`${REPORTS_URL}/${reportId}/content `, { responseType: 'blob' });
