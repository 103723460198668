import { sha256 } from 'js-sha256';
import base64Js from 'base64-js';

export const sha256hash = (message: string) => {
    const hashBytes = new Uint8Array(sha256.arrayBuffer(message));
    const encodedHash = base64Js.fromByteArray(hashBytes).replace(/\+/g, '-').replace(/\//g, '_').replace(/\/=/g, '');

    return encodedHash;
};

export const generateCramlLink = (
    url: string | undefined,
    realm: string | undefined,
    provider: string | undefined,
    client_id: string | undefined,
    redirect_uri: string,
    session_state: string,
    clientId: string | undefined,
): string => {
    const nonce = crypto.randomUUID();

    return `${url}/${realm}/broker/${provider}/link?client_id=${client_id}&redirect_uri=${redirect_uri}&nonce=${nonce}&hash=${sha256hash(`${nonce}${session_state}${clientId}${provider}`)}`;
};
