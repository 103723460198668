import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { RegularButton } from '../components/RegularButton/Button';
import styles from './styles.module.scss';
import general from '../styles/general.module.scss';

type ColorPickerModalProps = {
    isVisible: boolean;
    onClose: () => void;
    onSelectColor: (color: string) => void;
};

const ColorPickerModal: React.FC<ColorPickerModalProps> = ({ isVisible, onClose, onSelectColor }) => {
    const { t } = useTranslation();
    const [selectedColor, setSelectedColor] = useState<string>('#000000');

    const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedColor(event.target.value);
    };

    const handleHexChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        if (value === '#' || /^#[0-9A-Fa-f]{0,6}$/i.test(value)) {
            setSelectedColor(value);
        }
    };

    const handleSelectColor = () => {
        onSelectColor(selectedColor);
        onClose();
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className={general.modal}>
            <div className={classNames(general.modal_content, styles.color_picker_content)}>
                <span className={general.close_button} onClick={onClose}>
                    &times;
                </span>
                <h2>{t('editor.colorPicker.title')}</h2>
                <div className={styles.inputs}>
                    <input
className={styles.color} type="color" value={selectedColor}
onChange={handleColorChange} />
                    <input
                        type="text"
                        value={selectedColor}
                        onChange={handleHexChange}
                        maxLength={7}
                        className={general.hex_input}
                    />
                </div>
                <div className={styles.color_picker_buttons}>
                    <RegularButton label={t('editor.buttons.cancel')} onClick={onClose} />
                    <RegularButton label={t('editor.buttons.select')} onClick={handleSelectColor} />
                </div>
            </div>
        </div>
    );
};

export default ColorPickerModal;
