import { Status, TariffParams, Validation } from "@modules/AdminPage/services/types";

export enum SubscriptionStatus {
    ACTIVE = 'ACTIVE',
    CREATED = 'CREATED',
    CANCELLED = 'CANCELLED',
    EXPIRED = 'EXPIRED',
}

export type SubscriptionInfo = {
    id: number;
    productGuid: string;
    productName: string;
    startDate: string;
    endDate: string;
    status: SubscriptionStatus;
    cost: number;
    vatCost: number;
    comment: string;
    userGuid: string;
    userName: string;
    userPhones: string;
    createdDate: string;
    orderId: string;
    orderNumber: number;
    tariffId: string;
    renewal: boolean;
    flagManual: boolean;
    tariffParams: TariffParams[];
};

export type SubscriptionResponse = {
    data: SubscriptionInfo[];
    totalRecords: number;
};

export type SubscriptionsFiltersForm = {
    filters: Array<{
        field: string;
        value?: string | boolean | Date[];
    }>;
}

export type SubscriptionСreateFormInfo = {
    userGuid: string;
    productGuid: string;
}


export type GetSubscriptionsRequestType = {
    subscriptionId?: number;
    userId?: string;
    productGuid?: string;
    orderId?: string;
    externalOrderId?: string;
    tariffId?: string;
    paramId?: string;
    valueId?: string;
    statuses?: string[];
    startDateBeg?: string;
    startDateEnd?: string;
    endDateBeg?: string;
    endDateEnd?: string;
    flagManual?: boolean;
    sortField?: string;
    sortOrder?: 'asc' | 'desc';
    pageSize: number;
    pageNumber: number;
}

export type SubscriptionsResType = {
    totalRows: number;
    totalPages: number;
    size: number;
    pageNumber: number;
    rows: SubscriptionInfo[];
}

export type SubscriptionsRequestType = {
    createdDate: string;
    status: Status;
    validation: Validation;
    data: SubscriptionsResType;
}

export type SubscriptionItemRequestType = {
    createdDate: string;
    status: Status;
    validation: Validation;
    data: SubscriptionInfo;
}

export type PostSubscriptionRequestType = {
    subscriptionId?: string;
    userId?: string;
    productGuid?: string;
    tariffId?: string;
    tariffParams?: Array<{
        paramId: string;
        valueId: string;
    }>;
    status?: string;
    startDate?: string;
    endDate?: string;
    endDateEnd?: string;
    comment?: string;
}

export type ChangeSubscriptionRequestType = Omit<PostSubscriptionRequestType, 'userId'>

export type ProfileRequestType = {
    customerId: string;
    firstName: string;
    lastName: string;
    middleName: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    createdAt: string;
    updatedAt: string;
}

export type ProfileResType = {
    totalRows: number;
    totalPages: number;
    size: number;
    pageNumber: number;
    rows: ProfileRequestType[];
};
