import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Dropdown, type DropdownChangeEvent } from 'primereact/dropdown';
import { DataTable, type SortOrder } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { getArticles, getCategories } from '@modules/AdminPage/services';
import { PaginationController } from '@libs/components';
import { HasAccess } from '@libs/components/access';
import { usePermissions } from '@libs/hooks';

import { type Article, type Category } from '../../services/types';
import styles from '../styles/styles.module.scss';

type FiltersParams = {
    category?: string;
    status?: string;
};

export const Articles = () => {
    const navigate = useNavigate();
    const { referenceId } = useParams<{ referenceId: string }>();
    const [articles, setArticles] = useState<Article[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [loading, setLoading] = useState(true);
    const { rootAdminAndMarket } = usePermissions();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState(0);
    const [filters, setFilters] = useState<FiltersParams>();
    const [sortField, setSortField] = useState<string>('id');
    const [sortOrder, setSortOrder] = useState<SortOrder>(1);

    const statuses = [
        { name: 'Черновик', value: 'DRAFT' },
        { name: 'Опубликовано', value: 'PUBLISHED' },
        { name: 'Архив', value: 'ARCHIVED' },
    ];

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await getCategories();
                setCategories(response.data.categories);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    const fetchArticles = async () => {
        setLoading(true);
        try {
            const response = await getArticles({
                status: filters?.status,
                category: filters?.category,
                referenceId: Number(referenceId) || undefined,
                sortField: referenceId ? 'priority' : undefined,
                pageable: {
                    pageNumber: currentPage,
                    pageSize: 25,
                    sortField,
                    sortOrder: sortOrder === 1 ? 'desc' : 'asc',
                },
            });

            setTotalPages(response.data.data.totalPages);
            setArticles(response.data.data.rows);
        } catch (error) {
            console.error('Error fetching articles:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleSort = (e: any) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    };

    useEffect(() => {
        fetchArticles();
    }, [filters, currentPage, sortField, sortOrder]);

    const goToArticle = (id: string) => {
        navigate(`${id}`);
    };

    const goToCreateArticle = () => {
        navigate('create');
    };

    const handleChangeFilter = (value: string, key: string) => {
        setFilters((prev) => ({ ...prev, [key]: value }));
    };

    const categoriesOptions = categories.map((category) => ({ name: category.name, value: category.name }));

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>Статьи{referenceId && ', связанные со справкой'}</span>
                    <div className={classNames('flex', 'align-items-center', styles.btnGroup)}>
                        <Dropdown
                            value={filters?.status}
                            options={statuses}
                            onChange={(e: DropdownChangeEvent) => handleChangeFilter(e.value, 'status')}
                            optionLabel="name"
                            placeholder="Статус"
                            className={styles.dropdown}
                        />
                        <Dropdown
                            value={filters?.category}
                            options={categoriesOptions}
                            onChange={(e: DropdownChangeEvent) => handleChangeFilter(e.value, 'category')}
                            optionLabel="name"
                            placeholder="Категория"
                            className={styles.dropdown}
                        />
                        <HasAccess permissions={rootAdminAndMarket}>
                            <Button
label="Создать" severity="secondary" outlined
onClick={goToCreateArticle} />
                        </HasAccess>
                    </div>
                </div>
                <DataTable
                    value={articles}
                    rows={25}
                    loading={loading}
                    className={classNames(styles.table)}
                    onRowClick={(e) => goToArticle(e.data.id)}
                    emptyMessage={'Статьи отсутствуют'}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={handleSort}
                    lazy
                >
                    <Column field="id" header="ID" sortable />
                    <Column field="title" header="Заголовок" sortable />
                    <Column field="category" header="Категория" sortable />
                    <Column field="status" header="Статус" sortable />
                    <Column
                        field="titleDate"
                        header="Дата публикации"
                        sortable
                        body={(row) => new Date(row.titleDate).toLocaleDateString()}
                    />
                    {referenceId && <Column field="priority" header="Приоритет" sortable />}
                </DataTable>
                <div className={classNames('flex', 'justify-content-center')}>
                    <PaginationController
                        total={totalPages}
                        current={currentPage}
                        setAsyncCurrent={(e) => setCurrentPage(e)}
                    />
                </div>
            </div>
        </div>
    );
};
