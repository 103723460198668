import { ReactComponent as TelegramIcon } from '@shared/images/svg/Telegram.svg';
import { ReactComponent as VkIcon } from '@shared/images/svg/Vk.svg';
import { ReactComponent as YouTubeIcon } from '@shared/images/svg/YouTube.svg';
import { ReactComponent as Support } from '@shared/images/svg/Support.svg';
import { ReactComponent as FooterLogoIcon } from '@shared/images/svg/FooterLogo.svg';
import { Button } from 'primereact/button';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const Footer = () => {
    return (
        <section className={styles.footerAdititonalContainer}>
            <div className={styles.footerWrapper}>
                <div className={styles.footerHeader}>
                    <div className={styles.headerInfo}>
                        <a target={'_blank'} rel="noreferrer" href="https://www.moex.com/s249">
                            <p>Раскрытие информации</p>
                        </a>
                        <a href={'/docs/Пользовательское соглашение.docx'} download>
                            <p>Пользовательское соглашение</p>
                        </a>
                        <a
                            target={'_blank'}
                            rel="noreferrer"
                            href="https://assets.finuslugi.ru/mp-assets/privacy-policy.pdf"
                        >
                            <p>Политика обработки персональных данных</p>
                        </a>
                    </div>

                    {/* <div className={styles.socialWrapper}>
          <a href={'/'} className={styles.disabled}>
            <Button style={{ paddingRight: '3px' }}>
              <TelegramIcon />
            </Button>
          </a>

          <a href={'/'} className={styles.disabled}>
            <Button>
              <VkIcon />
            </Button>
          </a>

          <a href={'/'} className={styles.disabled}>
            <Button>
              <YouTubeIcon />
            </Button>
          </a>
        </div> */}
                </div>

                <div className={styles.logoWrapper}>
                    <FooterLogoIcon />
                </div>

                <div className={styles.infoWrapper}>
                    <p>Datashop, 2024 г. — сервис ПАО Московская биржа</p>
                    <p>
                        Все права на информацию и аналитические материалы, размещенные на настоящем сайте Биржи,
                        защищены в соответствии с российским законодательством. Прежде чем приступить к использованию
                        сайта предлагаем ознакомиться с Пользовательским соглашением. Воспроизведение, распространение и
                        иное использование информации, размещенной на сайте Биржи, или ее части допускается только с
                        предварительного письменного согласия Биржи.
                    </p>
                </div>
                <div className={styles.socialWrapper}>
                    <a className={classNames(styles.socialWrapper_icon, styles.socialWrapper_icon__centered)}>
                        <TelegramIcon />
                    </a>
                    <a className={styles.socialWrapper_icon}>
                        <VkIcon />
                    </a>
                </div>
                <div className={styles.supportWrapper}>
                    <div className={styles.support_icon}>
                        <Support />
                    </div>
                    <div>
                        <p className={styles.support_call}>Поддержка: +7 495 786-38-90</p>
                        <p className={styles.support_schedule}>Будни: с 7:00 до 23:00 мск</p>
                    </div>
                </div>
            </div>
        </section>
    );
};
