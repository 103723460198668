import { ProgressSpinner } from 'primereact/progressspinner';
import classNames from 'classnames';
import { useEffect } from 'react';

import style from './style.module.scss';

type Props = {
    isLoading: boolean;
};

export const Loader = ({ isLoading }: Props) => {
    useEffect(() => {
        if (isLoading) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isLoading]);

    return isLoading
? (
        <div className={classNames(style.loadingWrapper, 'flex', 'align-items-center', 'justify-content-center')}>
            <ProgressSpinner />
        </div>
    )
: null;
};
