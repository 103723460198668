import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RegularButton } from '../components/RegularButton/Button';
import styles from './styles.module.scss';
import general from '../styles/general.module.scss';

type LinkModalProps = {
    isVisible: boolean;
    onClose: () => void;
    onSetLink: (url: string) => void;
    previousUrl: string;
};

const LinkModal: React.FC<LinkModalProps> = ({ isVisible, onClose, onSetLink, previousUrl }) => {
    const { t } = useTranslation();
    const [url, setUrl] = useState<string>(previousUrl);

    const handleSetLink = () => {
        onSetLink(url);
        handleClose();
    };

    const handleRemoveLink = () => {
        onSetLink('');
        handleClose();
    };

    const handleClose = () => {
        setUrl('');
        onClose();
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className={general.modal}>
            <div className={styles.link_modal_content}>
                <span className={general.close_button} onClick={handleClose}>
                    &times;
                </span>
                <h2>{t('editor.linkModal.title')}</h2>
                <input
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder={t('editor.linkModal.placeholder')}
                    className="link-input"
                />
                <div className={styles.link_modal_buttons}>
                    <RegularButton label={t('editor.buttons.cancel')} onClick={handleClose} />
                    <RegularButton label={t('editor.buttons.delete')} onClick={handleRemoveLink} />
                    <RegularButton label={t('editor.buttons.add')} onClick={handleSetLink} />
                </div>
            </div>
        </div>
    );
};

export default LinkModal;
