import { useEffect, useState } from 'react';
import { useForm, type Control, type UseFormHandleSubmit } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getPermissionById, createPermission, updatePermission, deletePermission } from '../services/index';
import { showLoader, hideLoader } from '@store/store/slices/loading.slice';
import { getProducts } from '@modules/AdminPage/services';
import { useModal } from '@libs/utils';
import { addCreationSuccessToast, addErrorToast, addToast, addUpdateSuccessToast } from '@store/store/slices/toast.slice';

type PermissionFormData = {
    productGuid: string;
    code: string;
    title: string;
};

type ProductOption = {
  label: string;
  value: string;
};

type UsePermissionPageFacadeResult = {
    control: Control<PermissionFormData>;
    isDirty: boolean;
    isValid: boolean;
    handleSubmit: UseFormHandleSubmit<PermissionFormData>;
    onSubmit: (data: PermissionFormData) => void;
    isEditing: boolean;
    products?: ProductOption[];
    isOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
    eventHandler: () => Promise<void>;
};

export const usePermissionPageFacade = (): UsePermissionPageFacadeResult => {
    const { permissionId } = useParams();
    const [products, setProducts] = useState<ProductOption[]>();
    const navigate = useNavigate();
    const dispatch = useDispatch();  
    const { isOpen, openModal, closeModal } = useModal();

    useEffect(() => {
      const fetchProducts = async () => {
        const response = await getProducts({
          pageable: {
            pageNumber: 0,
            pageSize: 100,
            sortField: 'id',
            sortOrder: 'desc',
          },
        });
        const productOptions = response.data.data.rows.map((product: any) => ({
          label: `${product.name} (${product.productGuid})`,
          value: product.productGuid,
        }));
        setProducts(productOptions);
      };
  
      fetchProducts();
    }, []);

    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty, isValid },
    } = useForm<PermissionFormData>({
        mode: 'onBlur',
    });

    const isEditing = Boolean(permissionId);

    useEffect(() => {
        if (isEditing) {
            const fetchPermission = async () => {
                try {
                    dispatch(showLoader());
                    const response = await getPermissionById(permissionId!);

                    const permissionFormData: PermissionFormData = {
                      productGuid: response.data.data.productGuid,
                      code: response.data.data.code,
                      title: response.data.data.title,
                    };
                    
                    reset(permissionFormData);
                } catch (error) {
                    console.error('Error fetching permission data:', error);
                } finally {
                    dispatch(hideLoader());
                }
            };

            fetchPermission();
        }
    }, [isEditing, permissionId, dispatch, reset]);

    const onSubmit = async (data: PermissionFormData) => {
        try {
            dispatch(showLoader());
            if (isEditing) {
                await updatePermission(permissionId!, data);
                dispatch(addUpdateSuccessToast());
            } else {
                const res = await createPermission(data);
                dispatch(addCreationSuccessToast());
                if (res?.data?.data?.id) {
                  navigate(`/permissions/${res.data.data.id}`);
                };
            }
        } catch (error) {
            console.error('Error submitting permission data:', error);
            dispatch(addErrorToast((error as { message: string }).message));
        } finally {
            dispatch(hideLoader());
        }
    };

    const eventHandler = async () => {
      if (permissionId) {
        try {
            dispatch(showLoader());
            await deletePermission(permissionId);
            dispatch(
                addToast({
                    severity: 'success',
                    summary: 'Объект успешно удален!',
                    detail: '',
                }),
            );
            navigate('/permissions');
        } catch (error) {
            console.error('Error deleting permission:', error);
            dispatch(addErrorToast((error as { message: string }).message));
        } finally {
            dispatch(hideLoader());
        };
      }

      closeModal();
    };

    return {
        control,
        isDirty,
        isValid,
        handleSubmit,
        onSubmit,
        isEditing,
        products,
        isOpen,
        openModal,
        closeModal,
        eventHandler,
    };
};
