import { type RefObject, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { exportFileWithCSV } from '@libs/utils';
import { SortOrder, type DataTableRowClickEvent } from 'primereact/datatable';
import { type Control, type FieldArrayWithId, useFieldArray, useForm } from 'react-hook-form';
import { type DatashopTariff, type Product } from '@libs/types';
import { type MultiSelectChangeEvent } from 'primereact/multiselect';
import { getProducts, getTariffs } from '@modules/AdminPage/services';
import { type ColumnBodyOptions } from 'primereact/column';
import { format } from 'date-fns';

import { transformArrayToObject } from './utils';
import { exportSubscriptions, getSubscriptions } from '../services';
import {
    type GetSubscriptionsRequestType,
    type SubscriptionInfo,
    type SubscriptionsFiltersForm,
} from '../services/types';

type UseSubscriptionsListFacadeResult = {
    fields: Array<FieldArrayWithId<SubscriptionsFiltersForm, 'filters', 'id'>>;
    control: Control<SubscriptionsFiltersForm, any>;
    handleSelectFilter: (e: any, field: string) => void;
    handleSelectUserFilter: (e: any) => void;
    products?: Product[];
    tariffs?: DatashopTariff[];
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    subscriptions?: SubscriptionInfo[];
    inputRef: RefObject<HTMLInputElement>;
    handleChangeFilter: (e: MultiSelectChangeEvent) => void;
    handleCreateClick: () => void;
    handleExportClick: () => void;
    handleTableRowClick: (e: DataTableRowClickEvent) => void;
    totalPages?: number;
    bodyTemplate: (data: SubscriptionInfo, options: ColumnBodyOptions) => any;
    fetchTariffs: (productGuid?: string) => void;
    sortField: string;
    sortOrder: SortOrder;
    handleSort: (e: any) => void;
};

const normalizeAppendValue = (fieldName: string) => {
    switch (fieldName) {
        case 'flagManual':
            return false;
        case 'orderId':
            return '';
        default:
            return undefined;
    }
};

export const useSubscriptionsListFacade = (): UseSubscriptionsListFacadeResult => {
    const navigate = useNavigate();

    const inputRef = useRef<HTMLInputElement>(null);

    const { control, reset } = useForm<SubscriptionsFiltersForm>();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'filters',
    });

    const [products, setProducts] = useState<Product[]>();
    const [tariffs, setTariffs] = useState<DatashopTariff[]>();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [subscriptions, setSubscriptions] = useState<SubscriptionInfo[]>();
    const [totalPages, setTotalPages] = useState(0);
    const [sortField, setSortField] = useState<string>('orderId');
    const [sortOrder, setSortOrder] = useState<SortOrder>(1);

    const handleChangeFilter = (e: MultiSelectChangeEvent) => {
        if (!fields.find((el) => el.field === e.selectedOption.code)) {
            append({
                field: e.selectedOption.code,
                value: normalizeAppendValue(e.selectedOption.code),
            });

            if (e.selectedOption.code === 'userId') {
                append({
                    field: 'userName',
                    value: undefined,
                });
            }
        } else {
            if (e.selectedOption.code === 'productGuid') {
                remove(fields.findIndex((el) => el.field === 'tariffId'));
                remove(fields.findIndex((el) => el.field === 'productGuid'));
                reset({ filters: fields.filter((el) => el.field !== 'tariffId' && el.field !== 'productGuid') });

                return;
            }

            remove(fields.findIndex((el) => el.field === e.selectedOption.code));
            reset({ filters: fields.filter((el) => el.field !== e.selectedOption.code) });
        }
    };

    const handleExportClick = async () => {
        const params = transformArrayToObject(fields) as GetSubscriptionsRequestType;

        let flagManual = params.flagManual;
        if (flagManual && typeof flagManual === 'object') {
          flagManual = false;
        }
        
        let sortedField = sortField;

        if (sortField === 'userName') {
            sortedField = 'userGuid';
        } else if (sortField === 'productName') {
            sortedField = 'productGuid';
        }

        try {
            const data = await exportSubscriptions({
              ...params,
              flagManual, 
              pageSize: 25, 
              pageNumber: currentPage,
              sortField: sortedField,
              sortOrder: sortOrder === 1 ? 'desc' : 'asc',
          }
            );
            const blob = new Blob([data.data], { type: 'text/csv;charset=utf-8;' });
            exportFileWithCSV(blob, 'subscriptions');
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const handleCreateClick = () => {
        navigate('/subscriptions/create');
    };

    const handleSelectFilter = (e: any, field: string) => {
        reset({
            filters: fields.map((el) => {
                return el.field !== field
                    ? {
                          field: el.field,
                          value: el.value,
                      }
                    : {
                          field,
                          value: field === 'statuses' || field === 'orderNumbers' ? [e.value] : e.value,
                      };
            }),
        });
    };

    const handleSelectUserFilter = (e: any) => {
        reset({
            filters: fields.map((el) => {
                return el.field !== 'userId' && el.field !== 'userName'
                    ? {
                          field: el.field,
                          value: el.value,
                      }
                    : {
                          field: el.field,
                          value: el.field === 'userId' ? e.code : e.name,
                      };
            }),
        });
    };

    const handleTableRowClick = (e: DataTableRowClickEvent) => {
        navigate(`/subscriptions/${e.data.id}`);
    };

    useEffect(() => {
        if (fields.find((el) => el.field === 'productGuid')?.field && !products?.length) {
            const fetchProducts = async () => {
                try {
                    const data = await getProducts({ pageable: {} });
                    setProducts(data.data.data.rows);
                } catch (error) {
                    console.log('error: ', error);
                }
            };
            fetchProducts();
        }
    }, [fields.find((el) => el.field === 'productGuid')?.field]);

    const fetchTariffs = async (productGuid?: string) => {
        try {
            const data = await getTariffs({ productGuid: productGuid, pageable: {} });
            setTariffs(data.data.data.rows);
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const fetchSubscriptions = async (params: GetSubscriptionsRequestType) => {
        let flagManual = params.flagManual;
        if (flagManual && typeof flagManual === 'object') {
            flagManual = false;
        }
        
        let sortedField = sortField;

        if (sortField === 'userName') {
            sortedField = 'userGuid';
        } else if (sortField === 'productName') {
            sortedField = 'productGuid';
        }

        try {
            const data = await getSubscriptions({ 
              ...params, 
              flagManual, 
              pageSize: 25, 
              pageNumber: currentPage,
              sortField: sortedField,
              sortOrder: sortOrder === 1 ? 'desc' : 'asc',
          },);
            setSubscriptions(data.data.data.rows);

            setTotalPages(data.data.data.totalPages);
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const handleSort = (e: any) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    };

    useEffect(() => {
        fetchTariffs();
    }, []);

    const values = JSON.stringify(
        fields
            .filter((item) => item.field !== 'userName')
            ?.map((item) => item.value)
            .filter((value) => value !== undefined),
    );

    useEffect(() => {
        fetchSubscriptions(transformArrayToObject(fields) as GetSubscriptionsRequestType);
    }, [currentPage, values, sortField, sortOrder]);

    const bodyTemplate = (data: SubscriptionInfo, options: ColumnBodyOptions) => {
        switch (options.field) {
            case 'renewal':
                return data.renewal ? 'Да' : 'Нет';
            case 'flagManual':
                return data.flagManual ? 'Да' : 'Нет';
            case 'startDate':
                return data.startDate && format(new Date(data.startDate), 'dd.MM.yyyy');
            case 'endDate':
                return data.endDate && format(new Date(data.endDate), 'dd.MM.yyyy');
            case 'createdDate':
                return data.createdDate && format(new Date(data.createdDate), 'dd.MM.yyyy');
            case 'tariffId':
                return tariffs?.length ? tariffs?.find((tariff) => tariff.guid === data.tariffId)?.name : data.tariffId;
            case 'userName':
                return data.userName ?? `${data.userPhones ?? ''} (${data.userGuid})`;
            default:
                return data[options.field as keyof Omit<SubscriptionInfo, 'tariffParams'>];
        }
    };

    return {
        fields,
        control,
        handleSelectFilter,
        products,
        tariffs,
        subscriptions,
        inputRef,
        handleChangeFilter,
        handleCreateClick,
        handleExportClick,
        handleTableRowClick,
        bodyTemplate,
        totalPages,
        currentPage,
        setCurrentPage,
        fetchTariffs,
        handleSelectUserFilter,
        sortField,
        sortOrder,
        handleSort,
    };
};
