import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { PaginationController } from '@libs/components';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import { usePermissionsListFacade } from './usePermissionsListFacade';
import styles from './styles.module.scss';

const tableColumns = [
    { field: 'id', header: 'id' },
    { field: 'productGuid', header: 'Продукт' },
    { field: 'title', header: 'Название разрешение' },
    { field: 'code', header: 'Мнемокод разрешения' },
];

export const PermissionsList = () => {
    const {
        handleCreateClick,
        permissions,
        handleTableRowClick,
        sortField,
        sortOrder,
        handleSort,
        totalPages,
        currentPage,
        setCurrentPage,
        setPermissionsFilters,
        permissionsFilters,
        products,
    } = usePermissionsListFacade();

    const productsOptions =
        products?.map((product) => ({
            label: `${product.name} (${product.productGuid})`,
            value: product.productGuid,
        })) ?? [];

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>Разрешения</span>
                    <div className={classNames('flex', 'align-items-center', styles.btnGroup)}>
                        <Dropdown
                            onChange={(e) => setPermissionsFilters({ productGuid: e.value })}
                            options={productsOptions}
                            optionLabel="label"
                            placeholder="Продукт"
                            optionValue="value"
                            value={permissionsFilters?.productGuid}
                            className={classNames(styles.filter)}
                            showClear
                        />
                        <Button
                            severity="secondary"
                            outlined
                            className={classNames(styles.button)}
                            onClick={handleCreateClick}
                        >
                            Создать
                        </Button>
                    </div>
                </div>
                <DataTable
                    value={permissions}
                    rows={25}
                    className={classNames(styles.table)}
                    onRowClick={handleTableRowClick}
                    emptyMessage={'Отчеты отсутствуют'}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={handleSort}
                    lazy
                >
                    {tableColumns.map((column) => (
                        <Column
                            key={column.field}
                            field={column.field}
                            header={column.header}
                            alignHeader="center"
                            className={classNames('text-center')}
                            sortable
                        />
                    ))}
                </DataTable>

                <div className={classNames('flex', 'justify-content-center')}>
                    <PaginationController
                        total={totalPages}
                        current={currentPage}
                        setAsyncCurrent={(e) => setCurrentPage(e)}
                    />
                </div>
            </div>
        </div>
    );
};
