import { PageableType } from '@libs/types';

export const reportFields = {
  report_id: 'Идентификатор отчета',
  type: 'Тип',
  status: 'Статус',
  recipient: 'Получатель(эл.почта)',
  messageId: 'Идентификатор ошибки',
  errorMessage: 'Описание ошибки',
  sendDate: 'Дата отправки отчета',
  createdDate: 'Дата создания',
  updateDate: 'Дата обновления',
};

export enum ReportType {
  DAILY_PURCHASED = 'Ежедневный отчет по оплатам за услуги Datashop',
  MONTHLY_PURCHASED = 'Ежемесячный отчет по оплатам за услуги Datashop',
  MONTHLY_SOLD = 'Данные по начислениям за услуги Datashop',
}

export enum ReportStatus {
  SUCCESS = 'Успешно',
  FAILED = 'Провал',
  IN_PROGRESS = 'В процессе',
}

export type ReportInfo = {
  id: string;
  type: ReportType;
  status: ReportStatus;
  recipient: string;
  sendDate: string;
  createdDate: string;
}

export type ReportsFiltersParams = {
  type?: ReportType;
  status?: ReportStatus;
  createdDate?: string;
};

export interface Status {
  errorCode: string;
  errorMessage: string;
}

export interface Line {
  errorCode: string;
  field: string;
  rejectedValue: string;
  errorMessage: string;
}

export interface Validation {
  lines: Line[];
}

export type GetReportsRequestType = {
  type?: ReportType;
  status?: ReportStatus;
  createdDate?: string;
  pageable?: PageableType;
};

export type ReportsResType = {
  totalRows: number;
  totalPages: number;
  size: number;
  pageNumber: number;
  rows: ReportInfo[];
};

export type ReportsRequestType = {
  createdDate: string;
  status: Status;
  validation: Validation;
  data: ReportsResType;
};

export type CreateReportRequestType = {
  createdDate: string;
  data: ReportInfo;
};

export type ReportCard = {
  report_id: string;
  type: ReportType;
  status: ReportStatus;
  recipient?: string;
  messageId?: string;
  errorMessage: string;
  sendDate?: string;
  createdDate: string;
  updateDate: string;
}

export type ReportByIdRequestType = {
    createdDate: string;
    data: ReportCard;
    status: Status;
    validation: Validation;
}
