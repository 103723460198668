import classNames from 'classnames';
import { ReactComponent as DotsMenuIcon } from '@shared/images/personalAccount/svg/dots_menu_icon.svg';
import { ReactComponent as StarIcon } from '@shared/images/personalAccount/svg/star_blue.svg';
import { ReactComponent as TrashIcon } from '@shared/images/personalAccount/svg/trash.svg';
import { type SavedCard } from '@libs/types';
import MasterCard from '@shared/images/svg/cardIcons/MasterCard.svg';
import Mir from '@shared/images/svg/cardIcons/Mir.svg';
import UnionPay from '@shared/images/svg/cardIcons/UnionPay.svg';
import Visa from '@shared/images/svg/cardIcons/Visa.svg';
import Maestro from '@shared/images/svg/cardIcons/Maestro.svg';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '@store/store';
import { deleteSavedCardThunk, getSavedCardsThunk, setDefaultSavedCardThunk } from '@store/store/thunk/card.thunk';

import styles from './styles.module.scss';

const icons: Record<string, string> = {
    MASTER_CARD: MasterCard,
    MIR: Mir,
    UNION_PAY: UnionPay,
    VISA: Visa,
    MAESTRO: Maestro,
};

const paymentSystemName: Record<string, string> = {
    VISA: 'Visa',
    MASTER_CARD: 'MasterCard',
    MAESTRO: 'Maestro',
    MIR: 'Mir',
    UNION_PAY: 'UnionPay',
    UNKNOWN: '',
};

export const CardComponent = ({ id, cardNumber, paymentSystem, isDefault }: SavedCard) => {
    const dispatch = useDispatch<AppDispatch>();
    const menuRef = useRef<OverlayPanel>(null);

    const setDefaultCard = () => {
        dispatch(setDefaultSavedCardThunk(id))
            .then(() => {
                dispatch(getSavedCardsThunk());
            })
            .catch((e) => console.error(e));
    };

    const deleteSavedCard = () => {
        dispatch(deleteSavedCardThunk(id))
            .then(() => {
                dispatch(getSavedCardsThunk());
            })
            .catch((e) => console.error(e));
    };

    return (
        <div className={classNames(styles.cardWrapper, 'flex', 'flex-column')}>
            <div
                className={classNames(
                    styles.cardInfoWrapper,
                    'flex',
                    'justify-content-between',
                    'align-items-center',
                    'flex-nowrap',
                    'md:flex-wrap',
                )}
            >
                <img src={icons[paymentSystem]} />
                <div className={classNames('flex', 'flex-column', 'justify-content-between')}>
                    <span>{paymentSystemName[paymentSystem]}</span>
                    <span>{cardNumber}</span>
                </div>
                <Button
                    className={styles.menuBtn}
                    icon={<DotsMenuIcon />}
                    onClick={(e) => menuRef.current && menuRef.current.toggle(e)}
                />
                <OverlayPanel ref={menuRef}>
                    <div className={classNames(styles.menuPanel, 'flex', 'flex-column')}>
                        {!isDefault && (
                            <Button
                                label={'Использовать по умолчанию'}
                                icon={<StarIcon />}
                                onClick={setDefaultCard}
                                className={classNames(styles.defaultBtn, 'flex')}
                            />
                        )}
                        <Button
                            label={'Удалить карту'}
                            icon={<TrashIcon />}
                            onClick={deleteSavedCard}
                            className={classNames(styles.deleteBtn, 'flex')}
                        />
                    </div>
                </OverlayPanel>
            </div>
            {isDefault && <p className={styles.defaultCartDescription}>Оплата по умолчанию</p>}
        </div>
    );
};
