import { createSlice } from '@reduxjs/toolkit';

type IState = {
  isLoading: boolean;
}

const initialState: IState = {
  isLoading: false,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    showLoader(state) {
      state.isLoading = true;
    },
    hideLoader(state) {
      state.isLoading = false;
    },
  },
});
export const loadingReducer = loadingSlice.reducer;
export const { showLoader, hideLoader } = loadingSlice.actions;
