import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import BulletList from '@tiptap/extension-bullet-list'
import Document from '@tiptap/extension-document'
import ListItem from '@tiptap/extension-list-item'
import Heading, { Level } from '@tiptap/extension-heading';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import CodeBlock from '@tiptap/extension-code-block';
import Code from '@tiptap/extension-code';
import { Carousel } from '../Carousel/Carousel';
import { BigCarousel } from '../BigCarousel/BigCarousel';
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import { Button } from '../Button/Button';
import { ExtendedImage } from './ExtendedImage';
import Table from '@tiptap/extension-table';


export const extensions = [
  StarterKit,
  Link,
  Placeholder.configure({
    placeholder: 'Write something …',
  }),
  Carousel,
  BigCarousel,
  Heading.configure({
    levels: [1, 2, 3, 4, 5, 6],
  }),
  TextStyle,
  Color,
  BulletList,
  Document,
  ListItem,
  Code,
  CodeBlock,
  Table.configure({
    resizable: true
  }),
  TableRow,
  TableCell,
  TableHeader,
  Button,
  ExtendedImage,
];
