import { type Pageable, type Status, type Validation } from '@modules/AdminPage/services/types';

export enum OrderStatus {
    PENDING = 'PENDING',
    AWAITING_PAYMENT = 'AWAITING_PAYMENT',
    PAID = 'PAID',
    AWAITING_RECEIPT_SENDING = 'AWAITING_RECEIPT_SENDING',
    RECEIPT_SENT = 'RECEIPT_SENT',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
    AWAITING_REFUND = 'AWAITING_REFUND',
    REFUNDED = 'REFUNDED',
    AWAITING_REFUND_RECEIPT_SENDING = 'AWAITING_REFUND_RECEIPT_SENDING',
    REFUND_RECEIPT_SENT = 'REFUND_RECEIPT_SENT',
    PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
    TECHNICAL_ERROR = 'TECHNICAL_ERROR',
    REFUND_COMPLETED = 'REFUND_COMPLETED',
}

export const orderFields = {
    userId: 'Пользователь',
    orderNumber: '№ заказа',
    status: 'Статус',
    price: 'Цена',
    vat: 'НДС',
};

export const orderItemFields = {
    productName: 'Наименование продукта',
    price: 'Цена',
    vat: 'НДС',
    tariffGuid: 'Id тарифа',
};

export type GetOrdersRequestType = {
    dateFrom?: string;
    dateTo?: string;
    statuses?: string[];
    orderExternalId?: string;
    orderNumber?: string;
    orderId?: string;
    userId?: string;
    pageable: Pageable;
};

export type OrderItemType = {
    productGuid: string;
    productName: string;
    tariffGuid: string;
    price: number;
    vat: number;
};

export type OrderInfo = {
    id: string;
    orderExternalId: string;
    userId: string;
    userName: string;
    orderNumber: string;
    status: string;
    createdDate: string;
    price: number;
    vat: number;
    items: OrderItemType[];
};

export type OrdersFiltersForm = {
    filters: Array<{
        field: string;
        value?: string | boolean | Date[];
    }>;
};

export type OrderRowType = {
    data: OrderInfo;
};

export type OrdersResType = {
    totalRows: number;
    totalPages: number;
    size: number;
    pageNumber: number;
    rows: OrderRowType[];
};

export type OrdersRequestType = {
    createdDate: string;
    status: Status;
    validation: Validation;
    data: OrdersResType;
};

export type OrderTariffParam = {
    paramId: string;
    paramName: string;
    valueId: string;
    valueName: string;
};

export type OrderByIdItem = {
    productName: string;
    price: number;
    vat: number;
    tariffGuid: string;
    tariffParams: OrderTariffParam[];
};

export type OrderByIdInfo = Omit<OrderInfo, 'items'> & {
    items: OrderByIdItem[];
};

export type OrderByIdResInfo = {
    data: OrderByIdInfo;
};

export type OrderByIdResType = {
    totalRows: number;
    totalPages: number;
    size: number;
    pageNumber: number;
    rows: OrderByIdResInfo[];
};

export type OrderByIdRequestType = {
    createdDate: string;
    status: Status;
    validation: Validation;
    data: OrderByIdResType;
};
