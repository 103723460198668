import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { useDispatch } from 'react-redux';
import { downloadFileThunk } from '@store/store/thunk/attachment.thunk';
import type { AppDispatch } from '@store/store';
import { unwrapResult } from '@reduxjs/toolkit';
import { type ImageObject } from '@libs/types';
import { usePermissions } from '@libs/hooks';
import { HasAccess } from '@libs/components/access';
import { addErrorToast, addToast, addUpdateSuccessToast } from '@store/store/slices/toast.slice';
import { BackArrow } from '@libs/components/pageBlocks/Navigation/BackArrow';

import styles from '../styles/styles.module.scss';
import { type Category } from '../../services/types';
import { getCategories, getArticle, updateArticle } from '../../services';

type FormData = {
    id: number;
    status: 'DRAFT' | 'PUBLISHED' | 'ARCHIVED';
    category?: Category | null;
    title: string;
    subtitle?: string;
    titleDate?: Date;
    priority?: number;
    image?: FileList;
};

export const ArticleCard = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams<{ id: string }>();
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<FormData>();
    const navigate = useNavigate();
    const { referenceId } = useParams<{ referenceId: string }>();
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | null>(null);
    const { isAdminOrMarket, rootAdminAndMarket } = usePermissions();

    useEffect(() => {
        const fetchImage = async ({ bucket, fileId }: ImageObject) => {
            try {
                dispatch(
                    downloadFileThunk({
                        bucket,
                        fileId,
                    }),
                ).then((response) => {
                    const imageUrl = unwrapResult(response);

                    if (imageUrl) {
                        setPreview(imageUrl);
                    }
                });
            } catch (error) {}
        };

        const fetchCategories = async () => {
            try {
                const response = await getCategories();
                setCategories(response.data.categories);

                return response.data.categories;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        const fetchArticle = async () => {
            try {
                const categoryData = await fetchCategories();
                const response = await getArticle(id!);
                const articleData = response.data;
                setValue('id', articleData.id);
                setValue('status', articleData.status);

                if (!referenceId) {
                    setValue(
                        'category',
                        categoryData?.find((category) => category.id === articleData.categoryId) ?? null,
                    );
                    setValue('subtitle', articleData.subtitle);
                    setValue('titleDate', new Date(articleData.titleDate));

                    if (articleData.image) {
                        await fetchImage(articleData.image);
                    }
                }
                setValue('priority', articleData.priority);
                setValue('title', articleData.title);
            } catch (error) {
                console.error('Error fetching article:', error);
            }
        };

        // fetchCategories();
        fetchArticle();
    }, [id, setValue, referenceId]);

    const onSubmit = async (data: FormData) => {
        const formData = new window.FormData();
        formData.append('status', data.status);
        formData.append('title', data.title);

        if (referenceId) {
            if (data.priority) formData.append('priority', data.priority.toString());
        } else {
            if (data.category?.id) formData.append('categoryId', data.category?.id?.toString() || '');

            if (data.subtitle) formData.append('subtitle', data.subtitle);

            if (data.titleDate) formData.append('titleDate', data.titleDate.toISOString());

            if (selectedFile) formData.append('image', selectedFile);
        }

        try {
            await updateArticle(id!, formData);

            dispatch(addUpdateSuccessToast());
        } catch (error) {
            console.error('Error updating article:', error);
            dispatch(addErrorToast((error as { message: string }).message));
        }
    };

    const handleFileUpload = (event: { files: File[] }) => {
        const file = event.files[0];
        setSelectedFile(file);
    };

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={styles.formWrapper}>
                    <span className={classNames(styles.editTitle)}>
                        <BackArrow />
                        Карточка статьи
                    </span>
                    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        <div className={styles.row}>
                            <Controller
                                name="id"
                                control={control}
                                defaultValue={0}
                                disabled={!isAdminOrMarket}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2 flex-1">
                                        <label htmlFor="id">ID</label>
                                        <InputText value={`${field.value}`} placeholder="id" readOnly />
                                    </div>
                                )}
                            />
                        </div>

                        <div className={styles.row}>
                            <Controller
                                name="status"
                                control={control}
                                defaultValue="DRAFT"
                                rules={{ required: 'Статус обязателен' }}
                                disabled={!isAdminOrMarket}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2 flex-1">
                                        <label htmlFor="status">Статус</label>
                                        <Dropdown
                                            {...field}
                                            options={[
                                                { label: 'Черновик', value: 'DRAFT' },
                                                { label: 'Опубликовано', value: 'PUBLISHED' },
                                                { label: 'Архив', value: 'ARCHIVED' },
                                            ]}
                                            placeholder="Выберите статус"
                                            optionLabel={'label'}
                                            optionValue={'value'}
                                            className={errors.status ? 'p-invalid' : ''}
                                        />
                                    </div>
                                )}
                            />
                            {errors.status && <small className="p-error">{errors.status.message}</small>}
                        </div>

                        {!referenceId && (
                            <div className={styles.row}>
                                <Controller
                                    name="category"
                                    control={control}
                                    disabled={!isAdminOrMarket}
                                    render={({ field }) => (
                                        <div className="flex flex-column gap-2 flex-1">
                                            <label htmlFor="category">Категория</label>
                                            <Dropdown
                                                {...field}
                                                options={categories}
                                                optionLabel="name"
                                                placeholder="Выберите категорию"
                                                className={errors.category ? 'p-invalid' : ''}
                                            />
                                        </div>
                                    )}
                                />
                                {errors.category && <small className="p-error">{errors.category.message}</small>}
                            </div>
                        )}

                        <div className={styles.row}>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue=""
                                disabled={!isAdminOrMarket}
                                rules={{
                                    required: 'Заголовок обязателен',
                                    maxLength: { value: 120, message: 'Максимальная длина 120 символов' },
                                }}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2 flex-1">
                                        <label htmlFor="title">Заголовок</label>
                                        <InputText
                                            {...field}
                                            placeholder="Введите заголовок"
                                            className={errors.title ? 'p-invalid' : ''}
                                        />
                                    </div>
                                )}
                            />
                            {errors.title && <small className="p-error">{errors.title.message}</small>}
                        </div>

                        {!referenceId && (
                            <>
                                <div className={styles.row}>
                                    <Controller
                                        name="subtitle"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            maxLength: { value: 250, message: 'Максимальная длина 250 символов' },
                                        }}
                                        render={({ field }) => (
                                            <div className="flex flex-column gap-2 flex-1">
                                                <label htmlFor="subtitle">Подзаголовок</label>
                                                <InputText
                                                    {...field}
                                                    placeholder="Введите подзаголовок"
                                                    className={errors.subtitle ? 'p-invalid' : ''}
                                                    disabled={!isAdminOrMarket}
                                                />
                                            </div>
                                        )}
                                    />
                                    {errors.subtitle && <small className="p-error">{errors.subtitle.message}</small>}
                                </div>

                                <div className={styles.row}>
                                    <Controller
                                        name="titleDate"
                                        control={control}
                                        render={({ field }) => (
                                            <div className="flex flex-column gap-2 flex-1">
                                                <label htmlFor="titleDate">Отображаемая дата публикации</label>
                                                <Calendar
                                                    {...field}
                                                    dateFormat="dd.mm.yy"
                                                    locale="ru"
                                                    disabled={!isAdminOrMarket}
                                                    placeholder="Выберите дату и время"
                                                />
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className={styles.row}>
                                    <Controller
                                        name="image"
                                        control={control}
                                        render={({ field }) => (
                                            <div className="flex flex-column gap-2 flex-1">
                                                <label htmlFor="image">Изображение</label>
                                                <div className={styles.fileUpload}>
                                                    <FileUpload
                                                        {...field}
                                                        disabled={!isAdminOrMarket}
                                                        mode="advanced"
                                                        name="image"
                                                        accept="image/*"
                                                        customUpload
                                                        uploadHandler={handleFileUpload}
                                                        auto
                                                        chooseLabel="Загрузить изображение"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    />
                                    {preview && !selectedFile && (
                                        <div>
                                            <img className={styles.image} src={preview} alt={''} />
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        {referenceId && (
                            <div className={styles.row}>
                                <Controller
                                    name="priority"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="flex flex-column gap-2 flex-1">
                                            <label htmlFor="priority">Приоритет</label>
                                            <InputNumber
                                                {...field}
                                                disabled={!isAdminOrMarket}
                                                placeholder="Введите приоритет"
                                                useGrouping={false}
                                                className={errors.priority ? 'p-invalid' : ''}
                                                onChange={(e) => field.onChange(e.value)}
                                                value={field.value}
                                            />
                                        </div>
                                    )}
                                />
                                {errors.priority && <small className="p-error">{errors.priority.message}</small>}
                            </div>
                        )}

                        <div className={styles.row}>
                            <HasAccess permissions={rootAdminAndMarket}>
                                <Button
type="submit" label="Сохранить" severity="secondary"
outlined />
                            </HasAccess>
                            <Button
                                type="button"
                                label="Редактировать"
                                severity="secondary"
                                outlined
                                onClick={() => navigate('edit')}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
