import { type RefObject, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SortOrder, type DataTable, type DataTableRowClickEvent } from 'primereact/datatable';
import { type MultiSelectChangeEvent } from 'primereact/multiselect';
import { type ColumnBodyOptions } from 'primereact/column';
import { format } from 'date-fns';
import { store } from '@store/store';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';
import { type SubscriptionsFiltersForm } from '@modules/AdminPage/Subscriptions/services/types';
import { type Control, type FieldArrayWithId, useFieldArray, useForm } from 'react-hook-form';

import { exportOrders, getOrders } from '../services';
import { type OrderInfo } from '../services/types';
import { transformArrayToObject } from './utils';
import { exportFileWithCSV, setTimeInISOString } from '@libs/utils';

type UseOrdersListFacadeResult = {
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    orders?: OrderInfo[];
    orderIdInputRef: RefObject<HTMLInputElement>;
    orderExternalIdInputRef: RefObject<HTMLInputElement>;
    orderNumberInputRef: RefObject<HTMLInputElement>;
    handleExportClick: () => void;
    handleTableRowClick: (e: DataTableRowClickEvent) => void;
    totalPages?: number;
    bodyTemplate: (data: OrderInfo, options: ColumnBodyOptions) => any;
    handleSelectFilter: (e: any, field: string) => void;
    handleSelectUserFilter: (e: any) => void;
    handleChangeFilter: (e: MultiSelectChangeEvent) => void;
    fields: Array<FieldArrayWithId<SubscriptionsFiltersForm, 'filters', 'id'>>;
    control: Control<SubscriptionsFiltersForm, any>;
    sortField: string;
    sortOrder: SortOrder;
    handleSort: (e: any) => void;
};

const normalizeAppendValue = (fieldName: string) => {
    switch (fieldName) {
        case 'orderExternalId':
        case 'orderId':
        case 'orderNumber':
            return '';
        default:
            return undefined;
    }
};

export const useOrdersListFacade = (): UseOrdersListFacadeResult => {
    const navigate = useNavigate();

    const [sortField, setSortField] = useState<string>('guid');
    const [sortOrder, setSortOrder] = useState<SortOrder>(1);

    const orderIdInputRef = useRef<HTMLInputElement>(null);
    const orderExternalIdInputRef = useRef<HTMLInputElement>(null);
    const orderNumberInputRef = useRef<HTMLInputElement>(null);

    const { control, reset } = useForm<SubscriptionsFiltersForm>();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'filters',
    });

    const handleChangeFilter = (e: MultiSelectChangeEvent) => {
        if (!fields.find((el) => el.field === e.selectedOption.code)) {
            append({
                field: e.selectedOption.code,
                value: normalizeAppendValue(e.selectedOption.code),
            });

            if (e.selectedOption.code === 'userGuid') {
                append({
                    field: 'userName',
                    value: undefined,
                });
            }
        } else {
            remove(fields.findIndex((el) => el.field === e.selectedOption.code));
            reset({ filters: fields.filter((el) => el.field !== e.selectedOption.code) });
        }
    };

    const handleSelectFilter = (e: any, field: string) => {
        const statusValue = e.value ? [e.value] : [];
        reset({
            filters: fields.map((el) => {
                return el.field !== field
                    ? {
                          field: el.field,
                          value: el.value,
                      }
                    : {
                          field,
                          value: field === 'statuses' ? statusValue : e.value,
                      };
            }),
        });
    };

    const handleSelectUserFilter = (e: any) => {
        reset({
            filters: fields.map((el) => {
                return el.field !== 'userGuid' && el.field !== 'userName'
                    ? {
                          field: el.field,
                          value: el.value,
                      }
                    : {
                          field: el.field,
                          value: el.field === 'userGuid' ? e.code : e.name,
                      };
            }),
        });
    };

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [orders, setOrders] = useState<OrderInfo[]>();
    const [totalPages, setTotalPages] = useState(0);

    const handleExportClick = async () => {
        const params = transformArrayToObject(fields);

        let sortedField = sortField;

        if (sortField === 'userName') {
            sortedField = 'personId';
        } else if (sortField === 'orderExternalId') {
            sortedField = 'guid';
        } else if (sortField === 'orderNumber') {
            sortedField = 'number';
        }

        try {
            const data = await exportOrders({
                ...params,
                pageable: {
                    pageNumber: currentPage + 1,
                    pageSize: 25,
                    sortField: sortedField,
                    sortOrder: sortOrder === 1 ? 'desc' : 'asc',
                },
                statuses: params.statuses ?? [],
                dateFrom: params.dateFrom && setTimeInISOString(params.dateFrom?.toISOString(), 0, 0, 0),
                dateTo: params.dateTo && setTimeInISOString(params.dateTo?.toISOString(), 23, 59, 59),
            });
            const blob = new Blob([data.data], { type: 'text/csv;charset=utf-8;' });
            exportFileWithCSV(blob, 'orders');
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const handleTableRowClick = (e: DataTableRowClickEvent) => {
        navigate(`/orders/${e.data.id}`);
    };

    const fetchOrders = async (params: any) => {

        let sortedField = sortField;

        if (sortField === 'userName') {
            sortedField = 'personId';
        } else if (sortField === 'orderExternalId') {
            sortedField = 'guid';
        } else if (sortField === 'orderNumber') {
            sortedField = 'number';
        }

        try {
            store.dispatch(showLoader());
            const data = await getOrders({
                ...params,
                pageable: {
                    pageNumber: currentPage + 1,
                    pageSize: 25,
                    sortField: sortedField,
                    sortOrder: sortOrder === 1 ? 'desc' : 'asc',
                },
                statuses: params.statuses ?? [],
                dateFrom: params.dateFrom && setTimeInISOString(params.dateFrom?.toISOString(), 0, 0, 0),
                dateTo: params.dateTo && setTimeInISOString(params.dateTo?.toISOString(), 23, 59, 59),
            });
            setOrders(data.data.data.rows.map((el) => el.data));
            setTotalPages(data.data.data.totalPages);
        } catch (error) {
            console.log('error: ', error);
        } finally {
            store.dispatch(hideLoader());
        }
    };

    const handleSort = (e: any) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    };

    const values = JSON.stringify(
        fields
            .filter((item) => item.field !== 'userName')
            ?.map((item) => item.value)
            .filter((value) => value !== undefined),
    );

    useEffect(() => {
        fetchOrders(transformArrayToObject(fields));
    }, [currentPage, values, sortField, sortOrder]);

    const bodyTemplate = (data: OrderInfo, options: ColumnBodyOptions) => {
        switch (options.field) {
            case 'createdDate':
                return data.createdDate && format(new Date(data.createdDate), 'dd.MM.yyyy');
            case 'vat':
                return data.items.length;
            default:
                return data[options.field as keyof Omit<OrderInfo, 'tariffParams'>];
        }
    };

    return {
        handleExportClick,
        handleChangeFilter,
        handleTableRowClick,
        bodyTemplate,
        totalPages,
        currentPage,
        setCurrentPage,
        orders,
        orderExternalIdInputRef,
        orderIdInputRef,
        orderNumberInputRef,
        handleSelectFilter,
        handleSelectUserFilter,
        fields,
        control,
        sortField,
        sortOrder,
        handleSort,
    };
};
