import { ProductMnemoCodes } from './types';

export const productRoute: { [key: string]: string } = {
    [ProductMnemoCodes.TPC_PRODUKT_ALGO_PACK]: '/products/algopack',
    [ProductMnemoCodes.MOEX_DATA]: process.env.REACT_APP_MOEXDATA_LINK || '',
    [ProductMnemoCodes.INVESTOR_PRO]: '/products/investorpro',
    [ProductMnemoCodes.MARKET_DIVE]: '/products/marketDive',
    [ProductMnemoCodes.COMPLIENCE_TOOL]: '/products/complianceTool',
    [ProductMnemoCodes.TPC_PRICE_CENTER]: '/products/priceCenter',
    [ProductMnemoCodes.PRO_PACK]: '/products/propack',
    [ProductMnemoCodes.CKI]: '',
    [ProductMnemoCodes.TRADE_RADAR]: process.env.REACT_APP_TRADE_RADAR || ''
};

export const isAbsoluteLink: string[] = [ProductMnemoCodes.MOEX_DATA, ProductMnemoCodes.TRADE_RADAR];
