import { DataTableRowClickEvent, SortOrder } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RefundInfo } from '../services/types';
import { store } from '@store/store';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';
import { getRefunds } from '../services';

export const useRefundsListFacade = () => {
    const navigate = useNavigate();

    const { orderId } = useParams();

    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [refunds, setRefunds] = useState<RefundInfo[]>();
    
    const [sortField, setSortField] = useState<string>('id');
    const [sortOrder, setSortOrder] = useState<SortOrder>(1);

    const handleTableRowClick = (e: DataTableRowClickEvent) => {
      navigate(`${e.data.id}`);
    };

    const handleSort = (e: any) => {
      setSortField(e.sortField);
      setSortOrder(e.sortOrder);
    };

    const fetchRefunds = async () => {
      try {
          store.dispatch(showLoader());
          const data = await getRefunds({
              orderId,
              pageable: {
                  pageNumber: currentPage + 1,
                  pageSize: 25,
                  sortField: sortField,
                  sortOrder: sortOrder === 1 ? 'asc' : 'desc',
              },
          });
          setRefunds(data.data.data.rows.map((el) => el.data));

          store.dispatch(hideLoader());

          setTotalPages(data.data.data.totalPages);
      } catch (error) {
          console.log('error: ', error);
      }
  };

    useEffect(() => {
      fetchRefunds();
    }, [currentPage, sortField, sortOrder]);

    return {
      refunds,
      handleTableRowClick,
      totalPages,
      currentPage,
      setCurrentPage,
      sortField,
      sortOrder,
      handleSort,
    };
};
