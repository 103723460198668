import { ClientCategory, ProductStatus, TariffStatus } from '@modules/AdminPage/services/types';

export const tableColumns = [
    { field: 'id', header: 'id' },
    { field: 'guid', header: 'ID ТПК' },
    { field: 'name', header: 'Название' },
    { field: 'mnemoCode', header: 'Мнемокод' },
    { field: 'clientCategory', header: 'Категория клиента' },
    { field: 'status', header: 'Статус' },
    { field: 'lastUpdateDate', header: 'Дата изменения статуса' },
    { field: 'createdDate', header: 'Дата синхронизации' },
];

export const statusOptions = Object.values(TariffStatus).map((el) => {
    return {
        name: el,
        code: el,
    };
});

export const categoryOptions = Object.values(ClientCategory).map((el) => {
    return {
        name: el,
        code: el,
    };
});

export const productStatusOptions = Object.values(ProductStatus).map((el) => {
    return {
        name: el,
        code: el,
    };
});
