import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import CustomAutoComplete from '@libs/components/data/CustomAutoComplete';
import { HasAccess } from '@libs/components/access';
import { usePermissions } from '@libs/hooks';
import { isAfter, isSameDay } from 'date-fns';
import { BackArrow } from '@libs/components/pageBlocks/Navigation/BackArrow';

import styles from './styles.module.scss';
import { SubscriptionStatus } from '../services/types';
import { useSubscriptionPageFacade } from './useSubscriptionPageFacade';

const makeFirstLetterLowerCase = (str: string) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
};

export const statusesMocks = [
    SubscriptionStatus.ACTIVE,
    SubscriptionStatus.CREATED,
    SubscriptionStatus.CANCELLED,
    SubscriptionStatus.EXPIRED,
];

export const SubscriptionPage = ({ isEdit }: { isEdit: boolean }) => {
    const {
        control,
        products,
        fetchTariffs,
        setValue,
        tariffs,
        watch,
        trigger,
        inputRef,
        isDirty,
        isValid,
        onSubmit,
        handleSubmit,
        isFormDisabled,
    } = useSubscriptionPageFacade({
        isEdit,
    });

    const { rootAdminPermissions, isAdmin } = usePermissions();

    return (
        <div className={classNames(styles.form, 'flex', 'flex-column')}>
            <div className={classNames('flex', 'flex-column', styles.formFields)}>
                <span className={classNames(styles.title)}>
                    <BackArrow />
                    {isEdit ? 'Редактирование подписки' : 'Создание подписки'}
                </span>
                <Controller
                    control={control}
                    name="userGuid"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className={classNames('flex flex-column gap-2', styles.userFilter)}>
                            <label htmlFor="userId">Пользователь</label>
                            <CustomAutoComplete
                                value={
                                    field.value
                                        ? {
                                              code: field.value,
                                              name: watch('userName'),
                                          }
                                        : undefined
                                }
                                onChange={(e) => {
                                    setValue('userGuid', e.code);
                                    setValue('userName', e.name);
                                }}
                                disabled={!isAdmin || isFormDisabled}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                            )}
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="productGuid"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="productGuid">Наименование продукта</label>
                            <Dropdown
                                {...field}
                                optionLabel="label"
                                options={
                                    products
                                        ?.filter((el) => !!el.name)
                                        .map((el) => {
                                            return {
                                                label: el.name,
                                                value: el.productGuid,
                                            };
                                        }) ?? []
                                }
                                placeholder="Выберите продукт"
                                className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                onChange={(e) => {
                                    field.onChange(e.value);
                                    setValue('tariffId', '');
                                    setValue('tariffParams', []);
                                    fetchTariffs(e.value);
                                }}
                                disabled={!isAdmin || isFormDisabled}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                            )}
                        </div>
                    )}
                />
                {watch('productGuid') && isDirty && !tariffs?.length && (
                    <div className={classNames(styles.errorTitle)}>
                        К сожалению, у выбранного продукта отсутствуют тарифы
                    </div>
                )}
                {watch('productGuid') && !!tariffs?.length && (
                    <Controller
                        control={control}
                        name="tariffId"
                        rules={!isEdit ? { required: true } : {}}
                        render={({ field, fieldState }) => (
                            <div className="flex flex-column gap-2">
                                <label htmlFor="tariffId">Тариф</label>
                                <Dropdown
                                    {...field}
                                    options={
                                        tariffs
                                            ?.filter((el) => !!el.name)
                                            .filter((el) => el.status === 'ACTIVE')
                                            .map((el) => {
                                                return {
                                                    label: el.name,
                                                    value: el.guid,
                                                };
                                            }) ?? []
                                    }
                                    disabled={!isAdmin || isFormDisabled}
                                    optionLabel="label"
                                    placeholder="Выберите тариф"
                                    className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                />
                                {fieldState.error && (
                                    <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                                )}
                            </div>
                        )}
                    />
                )}
                {watch().tariffId &&
                    tariffs
                        ?.find((tariff) => tariff?.guid === watch().tariffId)
                        ?.billingTables.flatMap((table) => table.tariffParams)
                        .map((el, index) => {
                            // Найти тарифные планы с isEnabled: true
                            const enabledTariffPlans = tariffs
                                ?.find((tariff) => tariff?.guid === watch().tariffId)
                                ?.billingTables.flatMap((table) => table.tariffPlans)
                                .filter((plan) => plan.isEnabled);

                            // Получить valueGuid из параметров, включенных тарифных планов
                            const enabledValueGuids = enabledTariffPlans
                                ?.flatMap((plan) => plan.tariffParams)
                                .filter((param) => param.paramGuid === el.tariffParamGuid)
                                .map((param) => param.valueGuid);

                            // Фильтровать values, оставляя только те, у которых guid есть в enabledValueGuids
                            const filteredValues = el.values
                                .filter((value) => enabledValueGuids?.includes(value.guid))
                                .sort((a, b) => a.valueOrder - b.valueOrder);

                            return (
                                <div
                                    key={el.tariffParamGuid}
                                    className={classNames(styles.tariffParams, 'flex', 'flex-column', 'gap-2')}
                                >
                                    <Controller
                                        control={control}
                                        name={`tariffParams.${watch().tariffParams.findIndex(
                                            (elem: { paramId: string }) => el.tariffParamGuid === elem.paramId,
                                        )}.valueId`}
                                        rules={{ required: true }}
                                        render={({ field, fieldState }) => (
                                            <div className="flex flex-column gap-2">
                                                <label htmlFor="tariffId">{el.name}</label>
                                                <Dropdown
                                                    {...field}
                                                    options={filteredValues}
                                                    optionLabel="value"
                                                    optionValue="guid"
                                                    placeholder={`Выберите ${makeFirstLetterLowerCase(el.name ?? '')}`}
                                                    disabled={!isAdmin || isFormDisabled}
                                                    className={classNames(
                                                        styles.dropdownInput,
                                                        fieldState.error && styles.redBorder,
                                                    )}
                                                    onChange={(e) => {
                                                        field.onChange(e.value);
                                                        setValue(`tariffParams.${index}`, {
                                                            paramId: el.tariffParamGuid,
                                                            valueId: e.target.value,
                                                        });
                                                    }}
                                                />
                                                {fieldState.error && (
                                                    <span className={classNames(styles.errorHint)}>
                                                        Пожалуйста, заполните поле
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                            );
                        })}
                <Controller
                    control={control}
                    name="startDate"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="startDate">Действует с</label>
                            <Calendar
                                onChange={(e) => {
                                    field.onChange(e.value);
                                    setValue('startDate', e.value, { shouldValidate: true });
                                    trigger('startDate');
                                }}
                                dateFormat="dd.mm.yy"
                                locale="ru"
                                value={field.value}
                                placeholder="Выберите дату"
                                className={classNames(styles.calendar, fieldState.error && styles.redBorder)}
                                disabled={!isAdmin || isFormDisabled}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                            )}
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="endDate"
                    rules={{
                        required: true,
                        validate: (val) => isAfter(new Date(val), new Date(watch('startDate'))) ||
                            isSameDay(new Date(val), new Date(watch('startDate'))) ||
                            'Дата окончания не может быть раньше даты начала',
                    }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="endDate">Действует по</label>
                            <Calendar
                                onChange={(e) => {
                                    field.onChange(e.value);
                                    setValue('endDate', e.value, { shouldValidate: true });
                                    trigger('endDate');
                                }}
                                value={field.value}
                                dateFormat="dd.mm.yy"
                                locale="ru"
                                placeholder="Выберите дату"
                                className={classNames(styles.calendar, fieldState.error && styles.redBorder)}
                                disabled={!isAdmin || isFormDisabled}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>{fieldState.error.message}</span>
                            )}
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="status"
                    rules={!isEdit ? { required: true } : {}}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="status">Статус</label>
                            <Dropdown
                                {...field}
                                options={
                                    statusesMocks?.map((el) => {
                                        return {
                                            label: el,
                                            value: el,
                                        };
                                    }) ?? []
                                }
                                optionLabel="label"
                                placeholder="Выберите статус"
                                className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                onChange={(e) => {
                                    field.onChange(e.value);
                                }}
                                disabled={!isAdmin || isFormDisabled}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                            )}
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="comment"
                    rules={!isEdit ? { required: true } : {}}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="comment">Комментарий</label>
                            <InputText
                                {...field}
                                placeholder="Введите номер заказа"
                                value={field.value as string}
                                onBlur={() => setValue('comment', inputRef.current?.value)}
                                className={classNames(styles.dropdownInput)}
                                ref={inputRef}
                                disabled={!isAdmin || isFormDisabled}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                            )}
                        </div>
                    )}
                />
                <HasAccess permissions={rootAdminPermissions}>
                    <div className={classNames('flex', 'align-item-center', styles.buttonsContainer)}>
                        <Button severity="secondary" outlined onClick={() => window.location.reload()}>
                            Отмена
                        </Button>
                        <Button
                            severity="secondary"
                            outlined
                            onClick={handleSubmit(onSubmit)}
                            disabled={!isDirty || !isValid || !watch('tariffId')}
                        >
                            {isEdit ? 'Сохранить' : 'Создать'}
                        </Button>
                    </div>
                </HasAccess>
            </div>
        </div>
    );
};
