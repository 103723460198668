import { httpClient } from '@configs/httpclient.config';
import { AxiosResponse } from 'axios';
import { ResponseInfo, SavedCard } from '@libs/types';

export const CARD_URL = '/moex-datashop-orderservice/api/datashop/order-service/v1/saved-cards';

export const getSavedCards = (): Promise<AxiosResponse<ResponseInfo<SavedCard[]>>> => httpClient.get(CARD_URL);

export const deleteSavedCard = (cardId: string): Promise<AxiosResponse<any>> => httpClient.delete(`${CARD_URL}/${cardId}`);

export const setDefaultSavedCard = (cardId: string) => httpClient.post(`${CARD_URL}/${cardId}/set-default`);
