import { Button } from 'primereact/button';
import { Slide1, Slide1Small, CarouselBg } from '@shared';
import { useAuthorize, useResize } from '@libs/utils';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';

import { mockData } from './mockData';
import styles from './styles.module.scss';

type DatashopTitleProps = {
    accessDenied: boolean;
};

export const DatashopTitle = ({ accessDenied }: DatashopTitleProps) => {
    const { isScreenSm } = useResize();
    const { loginHandler, isAuthorized } = useAuthorize();
    const { title, description, authButtonText, chooseProductButtonText } = mockData;

    const navigate = useNavigate();
    const productsRef = useRef(null);

    const goToProducts = () => {
        navigate('', { state: { goToProducts: true, productsRef } });
    };

    return (
        <div
            className={styles.carouselWrapper}
            style={{
                background: `${
                    !isScreenSm ? `url(${CarouselBg}) top left / 930px auto no-repeat,` : ''
                } url("${isScreenSm ? Slide1Small : Slide1}") center / cover no-repeat`,
            }}
        >
            <div className={styles.contentWrapper}>
                <h1>Панель администратора Даташоп</h1>
                <p />
                {accessDenied && <h2>Доступ запрещен</h2>}
            </div>
        </div>
    );
};
