import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PermissionsFiltersParams, PermissionInfo } from '../services/types';
import { DataTableRowClickEvent, SortOrder } from 'primereact/datatable';
import { getPermissions } from '../services';
import { store } from '@store/store';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';
import { Product } from '@libs/types';
import { getProducts } from '@modules/AdminPage/services';

type usePermissionsListFacadeResult = {
  handleCreateClick: () => void;
  permissions?: PermissionInfo[];
  handleTableRowClick: (e: DataTableRowClickEvent) => void;
  sortField: string;
  sortOrder: SortOrder;
  handleSort: (e: any) => void;
  totalPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setPermissionsFilters: React.Dispatch<React.SetStateAction<PermissionsFiltersParams | undefined>>;
  products?: Product[];
  permissionsFilters?: PermissionsFiltersParams;
}

export const usePermissionsListFacade = (): usePermissionsListFacadeResult => {
  const [permissions, setPermissions] = useState<PermissionInfo[]>();
  const [products, setProducts] = useState<Product[]>();
  const [isProductsLoaded, setIsProductsLoaded] = useState(false);
  const [sortField, setSortField] = useState<string>('id');
  const [sortOrder, setSortOrder] = useState<SortOrder>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [permissionsFilters, setPermissionsFilters] = useState<PermissionsFiltersParams>();

  const navigate = useNavigate();

  const handleCreateClick = () => {
    navigate('/permissions/create');
  };

  const handleTableRowClick = (e: DataTableRowClickEvent) => {
    navigate(`/permissions/${e.data.id}`);
  };

  const handleSort = (e: any) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };
  
  const fetchProducts = async () => {
    try {
      store.dispatch(showLoader());
      const response = await getProducts({
        pageable: { 
          pageNumber: 0, 
          pageSize: 100,
          sortField: 'id',
          sortOrder: 'desc',
        },
      });
      setProducts(response.data.data.rows);
      setIsProductsLoaded(true);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      store.dispatch(hideLoader());
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);
  
  const fetchPermissions = async () => {
    if (isProductsLoaded) {
      try {
        store.dispatch(showLoader());
        const response = await getPermissions({
          ...permissionsFilters,
          pageable: { 
            pageNumber: currentPage + 1, 
            pageSize: 25,
            sortField: sortField,
            sortOrder: sortOrder === 1 ? 'desc' : 'asc',
          },
        });
        
        const enrichedPermissions = response.data.rows.map(permission => {
          const product = products?.find(prod => prod.productGuid === permission.productGuid);
          return {
            ...permission,
            product: product ? `${product.name} (${permission.productGuid})` : permission.productGuid, 
          };
        });
  
        setPermissions(enrichedPermissions);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.log('error: ', error);
      } finally {
        store.dispatch(hideLoader());
      }
    }
  };

  useEffect(() => {
    fetchPermissions();
  }, [permissionsFilters, currentPage, sortField, sortOrder, isProductsLoaded]);

  return {
    handleCreateClick,
    permissions,
    handleTableRowClick,
    sortField,
    sortOrder,
    handleSort,
    totalPages,
    currentPage,
    setCurrentPage,
    setPermissionsFilters,
    permissionsFilters,
    products,
  };
}
