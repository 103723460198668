import { v4 as uuidv4 } from 'uuid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ToastState {
  toasts: {
    id: string;
    severity: 'error' | 'success';
    summary: string;
    detail: string;
  }[];
}

const initialState: ToastState = {
  toasts: [],
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,

  reducers: {
    addToast: (state, action: PayloadAction<Omit<ToastState['toasts'][0], 'id'>>) => {
      state.toasts.push({ id: uuidv4(), ...action.payload });
    },

    removeToast: (state, action: PayloadAction<string>) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== action.payload);
    },

    addCreationSuccessToast: (state) => {
      state.toasts.push({
        id: uuidv4(),
        severity: 'success',
        summary: '',
        detail: 'Объект успешно создан!',
      });
    },

    addUpdateSuccessToast: (state) => {
      state.toasts.push({
        id: uuidv4(),
        severity: 'success',
        summary: '',
        detail: 'Изменения успешно сохранены!',
      });
    },

    addErrorToast: (state, action: PayloadAction<string>) => {
      state.toasts.push({
        id: uuidv4(),
        severity: 'error',
        summary: 'Ошибка',
        detail: action.payload,
      });
    },
  },
});

export const {
  addToast,
  removeToast,
  addCreationSuccessToast,
  addUpdateSuccessToast,
  addErrorToast,
} = toastSlice.actions;

export const toastReducer = toastSlice.reducer;
