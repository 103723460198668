import { type DatashopTariff, ImageObject, type PageableType, type Product } from '@libs/types';

export enum ProductStatus {
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    SOON_AVAILABLE = 'SOON_AVAILABLE',
    NEW = 'NEW',
}

export enum TariffStatus {
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    NEW = 'NEW',
}

export enum ClientCategory {
    COMPANY = 'COMPANY',
    INDIVIDUAL = 'INDIVIDUAL',
    BOTH = 'BOTH',
}

export type TariffsFiltersParams = {
    status?: TariffStatus;
    clientCategory?: ClientCategory;
};

export type TariffParams = {
    paramId: string;
    valueId: string;
    valueName: string;
    tariffName: string;
};

export type GetProductsRequestType = {
    pageable?: PageableType;
    status?: string;
};

export type GetTariffsRequestType = {
    productGuid?: string;
} & TariffsFiltersParams &
    GetProductsRequestType;

export type PostProductRequestType = Pick<
    Product,
    'name' | 'status' | 'detail' | 'image' | 'cartImage' | 'landingPage' | 'referencePage'
>;

export const productPostfields = ['name', 'status', 'detail', 'image', 'cartImage', 'landingPage', 'referencePage', 'isIdentityRequired']

export type Status = {
    errorCode: string;
    errorMessage: string;
};

export interface Line {
    errorCode: string;
    field: string;
    rejectedValue: string;
    errorMessage: string;
}

export type Validation = {
    lines: Line[];
};

export type getTariffsRequestType = {
    productGuid: string;
} & GetProductsRequestType;

export type Article = {
    id: number;
    title: string;
    categoryId: number;
    status: 'DRAFT' | 'PUBLISHED' | 'ARCHIVED';
    titleDate: string;
    subtitle: string;
    image: ImageObject;
    priority?: number;
};

export type Pageable = {
    pageNumber?: number;
    pageSize?: number;
    sortField?: string;
    sortOrder?: string;
};

export type GetArticlesRequest = {
    status?: string;
    category?: string;
    referenceId?: number;
    sortField?: string;
    pageable: Pageable;
};

export type Category = {
    id: number;
    name: string;
};

export type CategoryResponse = {
    categories: Category[];
};

export type Content = {
    content: string;
};

export type UpdateReferenceArgs = {
    productGuid?: string | null;
    title: string;
    priority?: number;
    status: 'DRAFT' | 'PUBLISHED' | 'ARCHIVED';
};

export type GetReferencesRequest = {
    status?: string;
    productGuid?: string;
    pageable: Pageable;
};

export type Reference = {
    id: number;
    title: string;
    productGuid: string;
    priority: number;
    status: string;
    createdDate: string;
};

export type ProductsResType = {
    totalRows: number;
    totalPages: number;
    size: number;
    pageNumber: number;
    rows: Product[];
};

export type GetProductRequestType = {
    createdDate: string;
    status: Status;
    validation: Validation;
    data: ProductsResType;
};

export type TariffsResType = {
    totalRows: number;
    totalPages: number;
    size: number;
    pageNumber: number;
    rows: DatashopTariff[];
};

export type GetTariffRequestType = {
    createdDate: string;
    status: Status;
    validation: Validation;
    data: TariffsResType;
};

export type GetPermissionsType = {
    tariffGuid: string;
    productGuid: string;
};

export type PersmissionsRequestType = {
    createdDate: string;
    status: Status;
    validation: Validation;
    data: PermissionResType[];
};

export type PermissionResType = {
    permissionId: string;
    title: string;
    code: string;
    isEnabled: boolean;
};

export type UpdatePermissionsType = {
    permissions: Array<{
        permissionId: string;
        isEnabled: boolean;
    }>;
};

export type UpdateProductType = {
    productId: string;
    formData: FormData;
    token: string;
}
