import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { ReactComponent as LoadGosuslugi } from '@shared/images/personalAccount/svg/load_gosuslugi.svg';
import { ReactComponent as LoadPhone } from '@shared/images/personalAccount/svg/load_phone_icon.svg';
import { generateCramlLink } from '@libs/utils';
import { AuthContext, type IAuthContext } from 'react-oauth2-code-pkce';

import styles from './styles.module.scss';

type Props = {
    closeModal: () => void;
};

export const RegistrationForm = ({ closeModal }: Props) => {
    const [isPolicyCheckboxTrue, setPolicyCheckboxTrue] = useState<boolean>(false);
    const [isPolicyErrorShow, setPolicyErrorShow] = useState<boolean>(false);

    const { tokenData } = useContext<IAuthContext>(AuthContext);

    const changeCheckbox = () => {
        if (isPolicyErrorShow) {
            setPolicyErrorShow(false);
        }
        setPolicyCheckboxTrue((checked) => !checked);
    };

    const buttonHandler = (code: string) => {
        if (!isPolicyCheckboxTrue) {
            setPolicyErrorShow(true);

            return;
        }

        const link = generateCramlLink(
            process.env.REACT_APP_AUTH_BASE + 'realms',
            process.env.REACT_APP_REALM,
            code === 'esia' ? process.env.REACT_APP_PROVIDER_ESIA : process.env.REACT_APP_PROVIDER_MOBILEID,
            process.env.REACT_APP_CLIENT_ID,
            encodeURI(window.location.href.split('?')[0]),
            tokenData?.session_state,
            process.env.REACT_APP_CLIENT_ID,
        );

        console.log(link);
        // return;
        window.location.href = link;
    };

    const ErrorBlock = ({ text }: { text: string }) => {
        return <div className={styles.errorBlock}>{text}</div>;
    };

    return (
        <div className={classNames(styles.form, styles.selectRegistrationForm)}>
            <h2>Персональные данные</h2>
            <p>Регистрация через Mobile ID или Госуслуги сэкономит вам время. Это быстро и безопасно.</p>
            {isPolicyErrorShow && (
                <ErrorBlock
                    text={
                        'Без согласия на обработку персональных данных мы не сможем предоставить вам полные возможности сервиса. Как финансовая организация мы ограничены нормами законодательства и требованиями государственных регуляторов'
                    }
                />
            )}
            <Button onClick={() => buttonHandler('mobileid')}>
                <LoadPhone />
                <span>Загрузить данные по номеру телефона</span>
            </Button>
            <Button onClick={() => buttonHandler('esia')}>
                <LoadGosuslugi />
                <span>Загрузить данные из госуслуг</span>
            </Button>
            <div className={classNames('flex', 'align-items-center')}>
                <Checkbox
                    checked={isPolicyCheckboxTrue}
                    value={isPolicyCheckboxTrue}
                    onChange={changeCheckbox}
                    className={classNames(styles.checkbox, isPolicyErrorShow && 'p-invalid')}
                />
                <span>
                    <a href={'/docs/Согласие на обработку ПДН при использовании Mobile ID.docx'} download>
                        Согласие
                    </a>{' '}
                    на обработку персональных данных
                </span>
            </div>
        </div>
    );
};
