import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column, type ColumnBodyOptions } from 'primereact/column';
import { PaginationController } from '@libs/components';
import { Button } from 'primereact/button';
import { formatISO } from 'date-fns';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { convertDateToMoscowDateString } from '@libs/utils';

import { tableColumns } from './constants';
import styles from './styles.module.scss';
import { useReportsListFacade } from './useReportsListFacade';
import { ReportStatus, ReportType } from '../services/types';

const typeOptions = Object.keys(ReportType).map((key) => {
    return {
        name: ReportType[key as keyof typeof ReportType],
        code: key as keyof typeof ReportType,
    };
});

const statusOptions = Object.keys(ReportStatus).map((key) => {
    return {
        name: ReportStatus[key as keyof typeof ReportStatus],
        code: key as keyof typeof ReportStatus,
    };
});

export const ReportsList = () => {
    const {
        handleCreateClick,
        reports,
        handleTableRowClick,
        sortField,
        sortOrder,
        handleSort,
        totalPages,
        currentPage,
        setCurrentPage,
        setReportsFilters,
        reportsFilters,
        formatDate,
    } = useReportsListFacade();

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>Отчеты</span>
                    <div className={classNames('flex', 'align-items-center', styles.btnGroup)}>
                        <Dropdown
                            onChange={(e) => setReportsFilters((prevState) => ({
                                    ...prevState,
                                    type: e.value,
                                }))}
                            options={typeOptions}
                            optionLabel="name"
                            placeholder="Тип"
                            optionValue="code"
                            value={reportsFilters?.type}
                            className={classNames(styles.filter)}
                            showClear
                        />
                        <Dropdown
                            onChange={(e) => setReportsFilters((prevState) => ({
                                    ...prevState,
                                    status: e.value,
                                }))}
                            options={statusOptions}
                            optionLabel="name"
                            placeholder="Статус"
                            optionValue="code"
                            value={reportsFilters?.status}
                            className={classNames(styles.filter)}
                            showClear
                        />
                        <Calendar
                            onChange={(e) => setReportsFilters((prevState) => {
                                    console.log(e.value);

                                    return {
                                        ...prevState,
                                        createdDate: e.value ? convertDateToMoscowDateString(e.value) + 'Z' : undefined,
                                    };
                                })}
                            value={reportsFilters?.createdDate ? new Date(reportsFilters.createdDate) : null}
                            dateFormat="dd.mm.yy"
                            locale="ru"
                            selectionMode="single"
                            placeholder="Дата создания"
                            className={classNames(styles.filter)}
                            hideOnRangeSelection
                            onFocus={(e) => e.preventDefault()}
                        />
                        <Button
                            severity="secondary"
                            outlined
                            className={classNames(styles.button)}
                            onClick={handleCreateClick}
                        >
                            Создать
                        </Button>
                    </div>
                </div>
                <DataTable
                    value={reports}
                    rows={25}
                    className={classNames(styles.table)}
                    onRowClick={handleTableRowClick}
                    emptyMessage={'Отчеты отсутствуют'}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={handleSort}
                    lazy
                >
                    {tableColumns.map((column) => (
                        <Column
                            key={column.field}
                            field={column.field}
                            header={column.header}
                            alignHeader="center"
                            className={classNames('text-center')}
                            sortable
                            body={(rowData: any) => {
                                const value = rowData[column.field];

                                if (column.field === 'sendDate' || column.field === 'createdDate') {
                                    if (value !== null) {
                                        return formatDate(rowData[column.field]);
                                    } else {
                                        return '';
                                    }
                                }

                                return value;
                            }}
                        />
                    ))}
                </DataTable>

                <div className={classNames('flex', 'justify-content-center')}>
                    <PaginationController
                        total={totalPages}
                        current={currentPage}
                        setAsyncCurrent={(e) => setCurrentPage(e)}
                    />
                </div>
            </div>
        </div>
    );
};
