import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';
import { CreateNotificationsRequestType, GetNotificationsRequestType, GetTargetGroupsRequestType, NotificationInfo, NotificationsRequestType } from './types';

export const NOTIFICATIONS_URL = '/moex-datashop-notificationservice/api/admin/v1/notifications';

export const TARGET_GROUPS_URL = '/moex-datashop-notificationservice/api/admin/v1/target-groups';

export const getNotifications = async (requestData: GetNotificationsRequestType): Promise<AxiosResponse<NotificationsRequestType>> => await httpClient.post(`${NOTIFICATIONS_URL}/search`, requestData);

export const getNotificationById = async (notificationId: string): Promise<AxiosResponse<NotificationInfo>> => await httpClient.get(`${NOTIFICATIONS_URL}/${notificationId}`);

export const exportNotifications = async (requestData: GetNotificationsRequestType): Promise<AxiosResponse<Blob>> => await httpClient.post(`${NOTIFICATIONS_URL}/export`, { responseType: 'blob', ...requestData });

export const createNotification = async (requestData: FormData): Promise<AxiosResponse<CreateNotificationsRequestType>> => await httpClient.post(`${NOTIFICATIONS_URL}`, requestData, {
    headers: { 'Content-Type': 'multipart/form-data' },
});

export const getTargetGroups = async (): Promise<AxiosResponse<GetTargetGroupsRequestType>> => await httpClient.get(`${TARGET_GROUPS_URL}`);

export const updateNotification = async (id: string, requestData: FormData): Promise<AxiosResponse<CreateNotificationsRequestType>> => await httpClient.post(`${NOTIFICATIONS_URL}/${id}`, requestData, {
    headers: { 'Content-Type': 'multipart/form-data' },
});
