import classNames from 'classnames';
import { DataTable, type SortOrder, type DataTableRowClickEvent } from 'primereact/datatable';
import { Column, type ColumnBodyOptions } from 'primereact/column';
import { format } from 'date-fns';
import { Dropdown, type DropdownChangeEvent } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { type Product } from '@libs/types';
import {
    exportProducts,
    exportTariffs,
    getProducts,
    updateProductsCatalog,
    updateTariffsCatalog,
} from '@modules/AdminPage/services';
import { exportFileWithCSV } from '@libs/utils';
import { ProductStatus } from '@modules/AdminPage/services/types';
import { PaginationController } from '@libs/components';
import { usePermissions } from '@libs/hooks';
import { HasAccess } from '@libs/components/access';

import styles from './styles.module.scss';

const exportOptions = [
    { name: 'Продукты', code: 'products' },
    { name: 'Тарифы', code: 'tariffs' },
];

const tableColumns = [
    { field: 'id', header: 'id' },
    { field: 'productGuid', header: 'ID ТПК' },
    { field: 'name', header: 'Название' },
    { field: 'mnemoCode', header: 'Мнемокод' },
    { field: 'status', header: 'Статус' },
    { field: 'lastUpdateDate', header: 'Дата изменения статуса' },
    { field: 'createdDate', header: 'Дата синхронизации' },
];

const bodyTemplate = (data: Product, options: ColumnBodyOptions) => {
    switch (options.field) {
        case 'lastUpdateDate':
        case 'createdDate':
            return data[options.field] && format(new Date(data[options.field]), 'dd.MM.yyyy');
        default:
            return data[options.field as keyof Omit<Product, 'mainPageIcons' | 'image' | 'cartImage'>];
    }
};

const statusOptions = Object.values(ProductStatus).map((el) => {
    return {
        name: el,
        code: el,
    };
});

export const ProductsList = () => {
    const navigate = useNavigate();

    const [products, setProducts] = useState<Product[]>();
    const [status, setStatus] = useState();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState(0);
    const { rootAdminPermissions } = usePermissions();
    const [sortField, setSortField] = useState<string>('id');
    const [sortOrder, setSortOrder] = useState<SortOrder>(1);

    const handleTableRowClick = (e: DataTableRowClickEvent) => {
        navigate(`/products/${e.data.id}`);
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const data = await getProducts({
                    pageable: {
                        pageNumber: currentPage,
                        pageSize: 25,
                        sortField,
                        sortOrder: sortOrder === 1 ? 'desc' : 'asc',
                    },
                    status,
                });
                setProducts(data.data.data.rows);

                setTotalPages(data.data.data.totalPages);
            } catch (error) {
                console.log('error: ', error);
            }
        };
        fetchProducts();
    }, [status, currentPage, sortField, sortOrder]);

    const handleSort = (e: any) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    };

    const fetchExport = async (type: string) => {
        try {
            const data =
                type === 'products'
                    ? await exportProducts({
                          pageable: {
                              pageNumber: currentPage,
                              pageSize: 25,
                              sortField,
                              sortOrder: sortOrder === 1 ? 'desc' : 'asc',
                          },
                          status,
                      })
                    : await exportTariffs({
                          pageable: {
                              pageNumber: currentPage,
                              pageSize: 25,
                              sortField,
                              sortOrder: sortOrder === 1 ? 'desc' : 'asc',
                          },
                          status,
                      });
            const blob = new Blob([data.data], { type: 'text/csv;charset=utf-8;' });
            exportFileWithCSV(blob, 'products');
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const fetchUpdateCatalogs = async (type: string) => {
        try {
            type === 'products' ? await updateProductsCatalog() : await updateTariffsCatalog();
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const handleExport = (e: DropdownChangeEvent) => {
        fetchExport(e.value.code);
    };

    const handleUpdateCatalogs = (e: DropdownChangeEvent) => {
        fetchUpdateCatalogs(e.value.code);
    };

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>Продукты</span>
                    <div className={classNames('flex', 'align-items-center', styles.btnGroup)}>
                        <Dropdown
                            onChange={(e) => setStatus(e.value)}
                            options={statusOptions}
                            optionLabel="name"
                            placeholder="Статус"
                            optionValue="code"
                            value={status}
                            className={classNames(styles.filter)}
                            showClear
                        />
                        <Dropdown
                            onChange={handleExport}
                            options={exportOptions}
                            optionLabel="name"
                            placeholder="Экспорт"
                            className={classNames(styles.dropdownInput)}
                        />
                        <HasAccess permissions={rootAdminPermissions}>
                            <Dropdown
                                onChange={handleUpdateCatalogs}
                                options={exportOptions}
                                optionLabel="name"
                                placeholder="Синхронизировать"
                                className={classNames(styles.dropdownInput)}
                            />
                        </HasAccess>
                    </div>
                </div>
                <DataTable
                    value={products}
                    className={classNames(styles.table)}
                    onRowClick={handleTableRowClick}
                    emptyMessage={'Продукты отсутствуют'}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={handleSort}
                    lazy
                >
                    {tableColumns.map((column) => (
                        <Column
                            key={column.field}
                            field={column.field}
                            header={column.header}
                            alignHeader="center"
                            className={classNames('text-center')}
                            body={bodyTemplate}
                            sortable
                        />
                    ))}
                </DataTable>

                <div className={classNames('flex', 'justify-content-center')}>
                    <PaginationController
                        total={totalPages}
                        current={currentPage}
                        setAsyncCurrent={(e) => setCurrentPage(e)}
                    />
                </div>
            </div>
        </div>
    );
};
