import { PageableType } from '@libs/types';

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    FROZEN = 'FROZEN',
}

export type UserInfo = {
    customerId: string;
    fullName: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    status: UserStatus;
    createdAt: string;
    updatedAt: string;
};

export type GetUserArgs = {
    customerIds?: string[];
    status?: UserStatus;
    fullName?: string;
    pageable?: PageableType;
};

export type UsersFilter = Omit<GetUserArgs, 'pageable' | 'customerIds'>;

export type UsersListFilter = {
    status?: UserStatus;
    userGuid?: string;
    userName?: string;
}

export type UsersInfoResponse = {
    totalRows: number;
    totalPages: number;
    size: number;
    pageNumber: number;
    rows: UserInfo[];
};

export type UpdateUserStatusReq = {
    customerId: string[];
    status: UserStatus;
};
