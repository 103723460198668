import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { BackArrow } from '@libs/components/pageBlocks/Navigation/BackArrow';

import { useReportCardFacade } from './useReportCardFacade';
import { reportFields, ReportStatus, ReportType } from '../services/types';
import styles from './styles.module.scss';

export const ReportCard = () => {
    const { report, fetchExport, getReportTypeLabel, getReportStatusLabel, formatDate } = useReportCardFacade();

    return (
        <div className={classNames(styles.form, 'flex', 'flex-column')}>
            <div className={classNames('flex', 'flex-column', styles.formFields)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>
                        <BackArrow />
                        Отчет
                    </span>
                    <div className={classNames('flex', 'align-items-center', styles.btnGroup)}>
                        <Button
                            severity="secondary"
                            outlined
                            className={classNames(styles.button)}
                            onClick={fetchExport}
                        >
                            Скачать отчет
                        </Button>
                    </div>
                </div>
                {report &&
                    Object.entries(report).map(([key, value]) => {
                        const keyName = key as keyof typeof reportFields;

                        if (reportFields[keyName]) {
                            let displayValue = value;

                            if (
                                (key === 'sendDate' || key === 'createdDate' || key === 'updateDate') &&
                                value !== null
                            ) {
                                displayValue = formatDate(value);
                            } else if (value === null) {
                                displayValue = '';
                            }

                            return (
                                <div className={styles.field} key={keyName}>
                                    <label htmlFor={reportFields[keyName]}>{reportFields[keyName] || ''}</label>
                                    <div>
                                        <InputText
                                            style={{ width: 400 }}
                                            id={reportFields[keyName]}
                                            disabled
                                            value={displayValue}
                                        />
                                    </div>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
            </div>
        </div>
    );
};
