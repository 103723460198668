import { ReactComponent as RingIcon } from '@shared/images/svg/Ring.svg';
import { ReactComponent as HeaderLogoIcon } from '@shared/images/svg/HeaderLogo.svg';
// import { ReactComponent as BurgerMenuIcon } from '@shared/images/svg/BurgerMenu.svg';
import { ReactComponent as CrossIcon } from '@shared/images/svg/CrossIcon.svg';
import { ReactComponent as UserAvatar } from '@shared/images/svg/UserAvatar.svg';
import { ReactComponent as ShoppingCart } from '@shared/images/svg/ShoppingCartEmpty.svg';
import { ReactComponent as ShoppingCartRed } from '@shared/images/svg/ShoppingCart.svg';
import { type ReactNode, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { useAuthorize, useResize } from '@libs/utils';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, avatarDownloadThunk, getUserThunk, type RootState } from '@store/store';
import emptyAvatar from '@shared/images/personalAccount/svg/empty_avatar.svg';
import { SCREEN_MD } from '@libs/constants';

import { DropdownUserPanel } from './DropdownUserPanel';
import { AvatarButton } from './AvatarButton';
import { BurgerMenu } from './BurgerMenu';
import { Search } from './Search';
import styles from './styles.module.scss';

type Props = {
    children?: ReactNode;
    className?: string;
    menuShowLessWidth?: number;
    withUnderLine?: boolean;
};

export const Navigation = ({ children, className, menuShowLessWidth = SCREEN_MD, withUnderLine = true }: Props) => {
    const { loginHandler, isAuthorized, logoutHandler } = useAuthorize();
    const imageAvatarSelector = useSelector((state: RootState) => state.user.userAvatar);
    const userSelector = useSelector((state: RootState) => state.user.user);
    const dispatch = useDispatch<AppDispatch>();
    const [withMenuBurger, setWithMenuBurgerStatus] = useState<boolean>(false);
    const logoutWithUnsub = () => {
        logoutHandler();
    };

    const navigations = useMemo(() => {
        const list = [
            {
                text: 'Управление',
                link: '/notifications',
            },
            // {
            //     text: 'Статьи',
            //     link: '/articles',
            // },
            // {
            //     text: 'Партнерам',
            //     link: '',
            // },
            // {
            //     text: 'Справочный центр',
            //     link: '/help-center',
            // },
        ];

        if (!isAuthorized) {
            const blackList = ['Управление'];

            return list.filter((item) => !blackList.includes(item.text));
        } else {
            return list;
        }
    }, [isAuthorized]);

    useEffect(() => {
        (async () => {
            if (isAuthorized) {
                try {
                    // await dispatch(getUserThunk());
                } catch (e) {
                    console.error(e);
                }
            }
        })();
    }, [isAuthorized]);

    useEffect(() => {
        (async () => {
            if (userSelector?.photoUrl) {
                try {
                    await dispatch(
                        avatarDownloadThunk({
                            bucket: userSelector.photoUrl.bucket,
                            ticket: userSelector.photoUrl.fileId,
                        }),
                    );
                } catch (e) {
                    console.error(e);
                }
            }
        })();
    }, [userSelector]);

    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
    const [isHideIcons, setIsHideIcons] = useState<boolean>(false);
    const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

    const { isScreenXl, isScreenSm, width } = useResize();
    const op = useRef(null);

    useEffect(() => {
        if (width < menuShowLessWidth) {
            setWithMenuBurgerStatus(true);
        } else {
            setWithMenuBurgerStatus(false);
        }
    }, [width, menuShowLessWidth]);

    // if screen wider than isScreenXl should close burger menu
    useEffect(() => {
        if (isScreenXl) {
            setMenuOpen(false);
        }
    }, [isScreenXl]);

    useEffect(() => {
        const res = isSearchOpen && isScreenSm;
        setIsHideIcons(res);
        setScroll(!res);
    }, [isSearchOpen, isScreenSm]);

    const toggleMenuCallback = useCallback(() => {
        setScroll(isMenuOpen);
        setMenuOpen((prev) => !prev);
    }, [isMenuOpen]);

    const setScroll = (showScroll: boolean) => {
        if (showScroll) {
            document.body.style.overflowY = 'auto';
        } else {
            document.body.style.overflowY = 'hidden';
        }
    };

    return (
        <div className={classNames(styles.navigationWrapper, className)}>
            <div className={classNames(styles.navigationPanelWrapper, withMenuBurger && styles.withHiddenNavigation)}>
                {children || (
                    <div className={styles.navigationLInksBlock}>
                        {!isHideIcons && (
                            <Link to={'/'}>
                                <HeaderLogoIcon />
                            </Link>
                        )}
                    </div>
                )}

                <div className={styles.navigationIcons}>
                    <div style={{ display: 'none' }}>
                        {' '}
                        {/* todo: removed from april release */}
                        <Search isOpen={isSearchOpen} setIsOpen={(value: boolean) => setIsSearchOpen(value)} />
                    </div>

                    {/* <RingIcon cursor={'pointer'} /> */}
                    {!isHideIcons && (
                        <>
                            {/* todo: removed from april release */}
                            {/*                            {isAuthorized && (
                                <>
                                    {width > 1359 && (
                                        <Link to={'/cart'}>{cartCount ? <ShoppingCartRed /> : <ShoppingCart />}</Link>
                                    )}
                                </>
                            )} */}

                            {isMenuOpen
? (
                                <CrossIcon
                                    className={classNames(styles.burgerMenuButton, !withMenuBurger && styles.hide)}
                                    onClick={toggleMenuCallback}
                                />
                            )
: !isAuthorized
? (
                                <UserAvatar
                                    className={classNames(styles.burgerMenuButton, !withMenuBurger && styles.hide)}
                                    onClick={toggleMenuCallback}
                                />
                            )
: (
                                <img
                                    src={imageAvatarSelector || emptyAvatar}
                                    className={classNames(
                                        styles.burgerMenuButton,
                                        styles.userAvatar,
                                        !withMenuBurger && styles.hide,
                                    )}
                                    onClick={toggleMenuCallback}
                                />
                            )}
                        </>
                    )}
                </div>

                <div className={classNames(styles.navigationButtons, withMenuBurger && styles.hide)}>
                    {!isAuthorized
? (
                        <>
                            <Button className={styles.redBtn} label={'Вход'} onClick={() => loginHandler()} />
                        </>
                    )
: (
                        <>
                            <AvatarButton
                                img={imageAvatarSelector}
                                avatarSize={36}
                                onClick={(e: any) => (op.current as any).toggle(e)}
                            />
                            <OverlayPanel className={styles.overlayUserPanel} ref={op}>
                                <DropdownUserPanel />
                            </OverlayPanel>
                        </>
                    )}
                </div>
            </div>
            {withMenuBurger && withUnderLine && <hr className={styles.headerUnderline} />}
            {isMenuOpen && (
                <BurgerMenu loginHandler={loginHandler} logoutHandler={logoutWithUnsub} isAuthorized={isAuthorized} />
            )}
        </div>
    );
};
