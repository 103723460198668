import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';
import { GetRefundsRequestType, RefundByIdRequestType, RefundsRequestType } from './types';

export const REFUND_URL = '/moex-datashop-orderservice/api/admin/v1/refunds';

export const getRefunds = async (requestData: GetRefundsRequestType): Promise<AxiosResponse<RefundsRequestType>> =>
    await httpClient.post(`${REFUND_URL}/search`, requestData);

export const getRefundById = async (refundId: string): Promise<AxiosResponse<RefundByIdRequestType>> =>
    await httpClient.get(`${REFUND_URL}/${refundId}`);

export const postRefundEvent = async (refundId: string, refundCode: string): Promise<AxiosResponse> =>
    await httpClient.post(`${REFUND_URL}/${refundId}/events`, { event: refundCode });
