import React from 'react';
import { type CheckboxProps, Checkbox as PrimeReactCheckbox } from 'primereact/checkbox';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const Checkbox = React.memo((props: CheckboxProps) => {
    const { checked, className } = props;

    return (
        <PrimeReactCheckbox {...props} className={classNames(className, styles.checkbox, checked && styles.checked)} />
    );
});
