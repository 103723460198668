import classNames from 'classnames';
import { Footer, Navigation } from '@libs/components';
import { Button } from 'primereact/button';
import { useAuthorize } from '@libs/utils';

import styles from './styles.module.scss';

export const SessionExpiredPage = () => {
    const { loginHandler } = useAuthorize();

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <div className={classNames('flex', 'flex-column', styles.sessionExpiredPageWrapper)}>
                        <div className={styles.pageContent}>
                            <div className={styles.textBlock}>
                                <h1>сеанс работы завершен</h1>
                                <span>Вас долго не было. Мы завершили сеанс вашей работы.</span>
                            </div>
                            <Button
                                className={classNames(styles.redBtn)}
                                label={'Вернуться'}
                                onClick={() => loginHandler()}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
