import React, { useEffect } from 'react';
import { Toast as ToastPrime } from 'primereact/toast';
import { useSelector, useDispatch } from 'react-redux';
import { type RootState } from '@store/store';
import { removeToast } from '@store/store/slices/toast.slice';

import './styles.scss';

const LIFE_TIME = 3000;

export const Toast = () => {
    const toasts = useSelector((state: RootState) => state.toast.toasts);
    const dispatch = useDispatch();
    const toastRef = React.createRef<ToastPrime>();

    useEffect(() => {
        toasts.forEach((toast) => {
            toastRef.current?.show({ ...toast, life: LIFE_TIME });
            dispatch(removeToast(toast.id));
        });
    }, [toasts]);

    return toasts.length ? <ToastPrime ref={toastRef} className="ds-toast" /> : null;
};
