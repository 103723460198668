import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReportCard, ReportStatus, ReportType } from '../services/types';
import { exportReport, getReportById } from '../services';
import { exportFileWithCSV } from '@libs/utils';
import { store } from '@store/store';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';
import { format } from 'date-fns';

type useReportCardFacadeResult = {
  report?: ReportCard;
  fetchExport: () => void;
  getReportTypeLabel: (type: ReportType) => string;
  getReportStatusLabel: (status: ReportStatus) => string;
  formatDate: (date: string) => string;
}

export const useReportCardFacade = (): useReportCardFacadeResult => {
  const [report, setReport] = useState<ReportCard>();
  const { reportId } = useParams();
  
  const fetchReport = async () => {
    try {
        if (reportId) {
            store.dispatch(showLoader());
            const response = await getReportById(reportId);
            setReport(response.data.data);
        }
    } catch (error) {
        console.log('error: ', error);
    } finally {
      store.dispatch(hideLoader());
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  const fetchExport = async () => {
    if (reportId) {
      try {
          const data = await exportReport(reportId);
          const blob = new Blob([data.data], { type: 'text/csv;charset=utf-8;' });
          exportFileWithCSV(blob, 'report');
      } catch (error) {
          console.log('error: ', error);
      }
    }
  };


 const getReportTypeLabel = (type: ReportType): string => {
    switch (type) {
      case ReportType.DAILY_PURCHASED:
        return 'Ежедневный отчет по оплатам за купленные услуги';
      case ReportType.MONTHLY_PURCHASED:
        return 'Ежемесячный отчет по количеству проданных услуг на определенный продукт';
      case ReportType.MONTHLY_SOLD:
        return 'Ежемесячный отчет по оплатам за купленные услуги с учетом суммы';
      default:
        return '';
    }
  };

  const getReportStatusLabel = (status: ReportStatus): string => {
    switch (status) {
      case ReportStatus.SUCCESS:
        return 'Успешно';
      case ReportStatus.FAILED:
        return 'Провал';
      case ReportStatus.IN_PROGRESS:
        return 'В процессе';
      default:
        return '';
    }
  };

  const formatDate = (date: string) => format(new Date(date), 'dd.MM.yyyy HH:mm');

  return {
    report,
    fetchExport,
    getReportTypeLabel,
    getReportStatusLabel,
    formatDate,
  };
}
