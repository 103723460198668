import Image from '@tiptap/extension-image';

export interface ExtendedImageAttributes {
  src: string;
  alt?: string;
  title?: string;
  width?: string;
  height?: string;
}

export const ExtendedImage = Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: '30vw',
        parseHTML: element => element.style.width || '30vw',
        renderHTML: attributes => {
          return {
            style: `width: ${attributes.width};`,
          };
        },
      },
      height: {
        default: '30vw',
        parseHTML: element => element.style.height || '30vw',
        renderHTML: attributes => {
          return {
            style: `height: ${attributes.height};`,
          };
        },
      },
    };
  },
});
