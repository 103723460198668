import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react';
import React from 'react';

import styles from './Button.module.scss';

type ButtonComponentProps = {
    node: {
        attrs: {
            buttonText: string;
            buttonLink: string;
            backgroundColor: string;
            textColor: string;
        };
    };
};

export const Button = Node.create({
    name: 'button',
    group: 'block',
    content: 'block*',
    selectable: true,
    draggable: true,

    addAttributes() {
        return {
            buttonText: {
                default: 'Button Text',
            },
            buttonLink: {
                default: 'https://example.com',
            },
            backgroundColor: {
                default: '#007bff',
            },
            textColor: {
                default: '#ffffff',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'button',
                getAttrs: (element) => ({
                    buttonText: element.textContent || '',
                    buttonLink: element.getAttribute('data-button-link') || '',
                    backgroundColor: element.style.backgroundColor || '#007bff',
                    textColor: element.style.color || '#ffffff',
                }),
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['button', HTMLAttributes, 0];
    },

    addNodeView() {
        return ReactNodeViewRenderer(ButtonComponent);
    },
});

const ButtonComponent: React.FC<ButtonComponentProps> = ({ node }) => {
    const { buttonText, buttonLink, backgroundColor, textColor } = node.attrs;

    return (
        <NodeViewWrapper contentEditable={false} draggable data-drag-handle>
            <a
                className={styles.custom_button}
                href={buttonLink}
                style={{ backgroundColor, color: textColor }}
                target="_blank"
                rel="noreferrer"
            >
                {buttonText}
            </a>
        </NodeViewWrapper>
    );
};
