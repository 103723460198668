import { Outlet } from 'react-router-dom';
import { Navigation } from '@libs/components/pageBlocks/Navigation';

import styles from './styles.module.scss';
import { SideBar } from './Sidebar';

export const AdminLayout = () => {
    return (
        <>
            <Navigation />
            <div className={styles.wrapper}>
                {
                    <>
                        <SideBar />
                        <Outlet />
                    </>
                }
            </div>
        </>
    );
};
