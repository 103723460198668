import { type AnyObject } from '@libs/utils';
import { addDays } from 'date-fns';

export const transformArrayToObject = (arr: AnyObject[]): AnyObject => {
    return arr.reduce((acc: AnyObject, { field, value }) => {
        if (value && field !== 'periodStart' && field !== 'periodEnd' && field !== 'userName') {
            acc[field] = value;
        }

        if (value && field === 'periodStart') {
            if (value[0]) {
                acc['startDateBeg'] = addDays(new Date(value[0]),1);
            }

            if (value[1]) {
                acc['startDateEnd'] = addDays(new Date(value[1]),1);
            }
        }

        if (value && field === 'periodEnd') {
            if (value[0]) {
                acc['endDateBeg'] = addDays(new Date(value[0]),1);
            }

            if (value[1]) {
                acc['endDateEnd'] = addDays(new Date(value[1]),1);
            }
        }

        return acc;
    }, {});
};
