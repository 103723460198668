import classNames from 'classnames';
import { Button } from 'primereact/button';
import { PaginationController } from '@libs/components';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Controller } from 'react-hook-form';
import CustomAutoComplete from '@libs/components/data/CustomAutoComplete';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';

import { OrderStatus } from '../services/types';
import { useOrdersListFacade } from './useOrdersListFacade';
import { filterOptions, tableColumns, UNSORTABLE_COLUMNS } from './constants';
import styles from './styles.module.scss';

const statusOptions = Object.values(OrderStatus).map((el) => {
    return {
        label: el,
        value: el,
    };
});

export const OrdersList = () => {
    const {
        currentPage,
        setCurrentPage,
        orders,
        handleExportClick,
        handleTableRowClick,
        totalPages,
        bodyTemplate,
        fields,
        control,
        handleSelectUserFilter,
        handleSelectFilter,
        orderExternalIdInputRef,
        orderIdInputRef,
        orderNumberInputRef,
        handleChangeFilter,
        sortField,
        sortOrder,
        handleSort,
    } = useOrdersListFacade();

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    {!fields.length && <span className={classNames(styles.title)}>Заказы</span>}
                    <div className={classNames('flex', 'align-items-center', styles.filtersGroup)}>
                        {fields.find((el) => el.field === 'userGuid') && (
                            <Controller
                                control={control}
                                key={fields.find((el) => el.field === 'userGuid')?.id}
                                name={`filters.${fields?.findIndex((el) => el.field === 'userGuid')}.value`}
                                render={({ field: { value } }) => (
                                    <div className={classNames('flex flex-column gap-2', styles.userFilter)}>
                                        <label htmlFor="userGuid">Пользователь</label>
                                        <CustomAutoComplete
                                            value={
                                                value
                                                    ? {
                                                          code: value as string,
                                                          name: fields.find((el) => el.field === 'userName')
                                                              ?.value as string,
                                                      }
                                                    : undefined
                                            }
                                            onChange={handleSelectUserFilter}
                                        />
                                    </div>
                                )}
                            />
                        )}
                        {fields.find((el) => el.field === 'period') && (
                            <div className="flex flex-column gap-2">
                                <label htmlFor="period">Дата заказа</label>
                                <Calendar
                                    onChange={(e) => handleSelectFilter(e, 'period')}
                                    value={fields.find((el) => el.field === 'period')?.value as Date[]}
                                    dateFormat="dd.mm.yy"
                                    locale="ru"
                                    selectionMode="range"
                                    placeholder="Выберите период"
                                    className={classNames(styles.filter)}
                                    hideOnRangeSelection
                                    onFocus={(e) => e.preventDefault()}
                                />
                            </div>
                        )}
                        {fields.find((el) => el.field === 'statuses') && (
                            <Controller
                                key={fields.find((el) => el.field === 'statuses')?.id}
                                control={control}
                                name={`filters.${fields?.findIndex((el) => el.field === 'statuses')}.value.0`}
                                render={({ field: { value } }) => (
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="statuses">Статус</label>
                                        <Dropdown
                                            onChange={(e) => handleSelectFilter(e, 'statuses')}
                                            value={value}
                                            options={statusOptions}
                                            optionLabel="label"
                                            placeholder="Выберите статус"
                                            className={classNames(styles.filter)}
                                        />
                                    </div>
                                )}
                            />
                        )}
                        {fields.find((el) => el.field === 'orderId') && (
                            <Controller
                                control={control}
                                name={`filters.${fields?.findIndex((el) => el.field === 'orderId')}.value`}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="orderId">Id заказа внутренний</label>
                                        <InputText
                                            {...field}
                                            placeholder="Введите Id заказа внутренний"
                                            value={field.value as string}
                                            onBlur={() => handleSelectFilter(orderIdInputRef.current, 'orderId')}
                                            className={classNames(styles.filter)}
                                            ref={orderIdInputRef}
                                        />
                                    </div>
                                )}
                            />
                        )}
                        {fields.find((el) => el.field === 'orderExternalId') && (
                            <Controller
                                control={control}
                                name={`filters.${fields?.findIndex((el) => el.field === 'orderExternalId')}.value`}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="orderExternalId">Id заказа внешний</label>
                                        <InputText
                                            {...field}
                                            placeholder="Введите Id заказа внешний"
                                            value={field.value as string}
                                            onBlur={() => handleSelectFilter(orderExternalIdInputRef.current, 'orderExternalId')}
                                            className={classNames(styles.filter)}
                                            ref={orderExternalIdInputRef}
                                        />
                                    </div>
                                )}
                            />
                        )}
                        {fields.find((el) => el.field === 'orderNumber') && (
                            <Controller
                                control={control}
                                name={`filters.${fields?.findIndex((el) => el.field === 'orderNumber')}.value`}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="orderNumber">№ заказа</label>
                                        <InputText
                                            {...field}
                                            placeholder="Введите № заказа"
                                            value={field.value as string}
                                            onBlur={() => handleSelectFilter(orderNumberInputRef.current, 'orderNumber')}
                                            className={classNames(styles.filter)}
                                            ref={orderNumberInputRef}
                                        />
                                    </div>
                                )}
                            />
                        )}
                    </div>
                    <div className={classNames('flex', 'align-items-center', styles.btnGroup)}>
                        <div className="flex flex-column gap-2">
                            <label htmlFor="filters">Фильтры</label>
                            <MultiSelect
                                onChange={handleChangeFilter}
                                value={fields.filter((el) => el.field !== 'userName').map((el) => el.field) ?? []}
                                options={filterOptions}
                                optionLabel="name"
                                optionValue="code"
                                placeholder="Добавить фильтр"
                                pt={{
                                    header: { style: { display: 'none' } },
                                    item: { className: styles.multiSelectItem },
                                    root: { className: styles.multi },
                                    wrapper: { className: styles.multi },
                                }}
                            />
                        </div>
                        <Button
                            severity="secondary"
                            outlined
                            className={classNames(styles.button)}
                            onClick={handleExportClick}
                        >
                            Экспорт
                        </Button>
                    </div>
                </div>
                <DataTable
                    value={orders}
                    rows={25}
                    className={classNames(styles.table)}
                    onRowClick={handleTableRowClick}
                    emptyMessage={'Заказы отсутствуют'}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={handleSort}
                    lazy
                >
                    {tableColumns.map((column) => (
                        <Column
                            key={column.field}
                            field={column.field}
                            header={column.header}
                            alignHeader="center"
                            className={classNames('text-center')}
                            body={bodyTemplate}
                            sortable={!UNSORTABLE_COLUMNS.includes(column.field)}
                        />
                    ))}
                </DataTable>

                <div className={classNames('flex', 'justify-content-center')}>
                    <PaginationController
                        total={totalPages ?? 0}
                        current={currentPage}
                        setAsyncCurrent={(e) => setCurrentPage(e)}
                    />
                </div>
            </div>
        </div>
    );
};
