import { useRef } from 'react';
import { FileUpload, type FileUploadSelectEvent } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { ReactComponent as TrashIcon } from '@shared/images/personalAccount/svg/trash.svg';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { AVAILABLE_IMAGE_FORMATS } from '@libs/constants';

import styles from './styles.module.scss';

type CustomFileUploadProps = {
    image?: string;
    onUpload?: (e: FileUploadSelectEvent) => void;
    onClear?: () => void;
    disabled?: boolean;
};

const CustomFileUpload = (props: CustomFileUploadProps) => {
    const { image, onUpload, onClear, disabled } = props;

    const fileUploadRef = useRef<FileUpload>(null);

    const handleSelect = () => {
        if (!image) {
            if (fileUploadRef.current) {
                fileUploadRef.current.getInput().click();
            }
        } else {
            fileUploadRef.current?.clear();
            debounce(() => {
                fileUploadRef.current?.getInput().click();
            }, 100)();
        }
    };

    const handleClear = () => {
        if (image) {
            fileUploadRef.current?.clear();
            onClear?.();
        }
    };

    return (
        <div className={classNames('flex', 'flex-column', styles.image)}>
            {image && <Image
src={image} alt="Image" width="300"
preview />}
            <FileUpload
                accept={AVAILABLE_IMAGE_FORMATS}
                customUpload
                mode="basic"
                auto
                style={{ display: 'none' }}
                ref={fileUploadRef}
                onSelect={onUpload}
            />
            <div className={classNames('flex', 'align-items-center', 'gap-2')}>
                {!disabled && image && <TrashIcon onClick={handleClear} />}
                <Button
                    disabled={disabled}
                    severity="secondary"
                    outlined
                    onClick={handleSelect}
                    className={classNames(styles.button)}
                >
                    {image ? 'Изменить' : 'Добавить'}
                </Button>
            </div>
        </div>
    );
};

export default CustomFileUpload;
