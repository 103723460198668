import { useEffect, useState } from 'react';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';
import { store } from '@store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { useModal } from '@libs/utils';

import { getOrderById, postOrderEvent } from '../services';
import { type OrderByIdInfo } from '../services/types';

type UseOrderPageFacadeResult = {
    order?: OrderByIdInfo;
    setSelectedAction: React.Dispatch<React.SetStateAction<{ label: string; code: string } | undefined>>;
    isOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
    eventHandler: () => Promise<void>;
    handleRefund: () => void;
};

export const useOrderPageFacade = (): UseOrderPageFacadeResult => {
    const navigate = useNavigate();

    const { orderId } = useParams();

    const [order, setOrder] = useState<OrderByIdInfo>();
    const [selectedAction, setSelectedAction] = useState<{ label: string; code: string } | undefined>();
    const { isOpen, openModal, closeModal } = useModal();

    const fetchOrder = async () => {
        try {
            if (orderId) {
                store.dispatch(showLoader());
                const data = await getOrderById(orderId);
                setOrder(data.data.data.rows[0].data);
                store.dispatch(hideLoader());
            }
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const eventHandler = async () => {
        if (orderId && selectedAction?.code) await postOrderEvent(orderId, selectedAction.code);
        closeModal();
    };

    useEffect(() => {
        fetchOrder();
    }, []);

    const handleRefund = () => {
        navigate('refunds');
    };

    return {
        order,
        setSelectedAction,
        isOpen,
        openModal,
        closeModal,
        eventHandler,
        handleRefund,
    };
};
