export const filterOptions = [
    { name: 'Пользователь', code: 'userId' },
    { name: 'Наименование продукта', code: 'productGuid' },
    { name: 'Тариф', code: 'tariffId' },
    { name: 'Cоздана с', code: 'periodStart' },
    { name: 'Признак ручной правки', code: 'flagManual' },
    { name: 'Статус', code: 'statuses' },
    { name: 'Номер заказа', code: 'orderNumbers' },
];

export const flagManualOptions = [
    { label: 'Да', value: true },
    { label: 'Нет', value: false },
];

export const tableColumns = [
    { field: 'userName', header: 'Пользователь' },
    { field: 'productName', header: 'Наименование продукта' },
    { field: 'tariffId', header: 'Тариф' },
    { field: 'status', header: 'Статус' },
    { field: 'renewal', header: 'Признак пролонгации' },
    { field: 'startDate', header: 'Действует с' },
    { field: 'endDate', header: 'Действует по' },
    { field: 'createdDate', header: 'Создана' },
    { field: 'cost', header: 'Цена' },
    { field: 'vatCost', header: 'НДС' },
    { field: 'orderNumber', header: 'Номер заказа' },
    { field: 'flagManual', header: 'Признак ручной правки' },
];
