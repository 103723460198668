import React, { useEffect, useLayoutEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AuthProvider, type TAuthConfig, type TRefreshTokenExpiredEvent } from 'react-oauth2-code-pkce';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { getAdminPermissions, loadScript } from '@libs/services';
import { generateRandomString, generateState } from '@libs/utils';
import { YandexMetrica } from '@libs/ym/YandexMetrica';

import { store } from './store/store';
import { router } from './routes';

function App() {
    useLayoutEffect(() => {
        window.browserIdValue = crypto.randomUUID();
        loadScript('browser-id.js', '', false);
    }, []);

    const onRefreshTokenExpire = () => {
        window.location.replace('/session-expired');
    };

    const redirectUri = () => {
        if (window.location.href.includes('session-expired')) {
            return window.location.href.split('session-expired')[0];
        } else if (window.location.href.includes('personal-account')) {
            return window.location.href.split('personal-account')[0];
        }

        return window.location.href.split('?')[0];
    };

    const authConfig: TAuthConfig = {
        clientId: process.env.REACT_APP_CLIENT_ID || '',
        authorizationEndpoint: process.env.REACT_APP_AUTH_ENDPOINT || '',
        tokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT || '',
        logoutEndpoint: process.env.REACT_APP_LOGOUT_ENDPOINT || '',
        redirectUri: redirectUri(),
        scope: process.env.REACT_APP_SCOPE || '',
        logoutRedirect: process.env.REACT_APP_DATASHOP_ADMIN_URL,
        onRefreshTokenExpire,
        autoLogin: false,
        state: generateState(),
        extraAuthParameters: {
            nonce: crypto.randomUUID(),
            response_mode: 'query',
            response_type: 'code',
            'ssl-required': 'external',
            resource: 'datashop_admin_console',
            'public-client': true,
            'confidential-port': 0,
        },
    };

    // console.log('authConfig.redirectUri', authConfig.redirectUri);

    return (
        <Provider store={store}>
            <AuthProvider authConfig={authConfig}>
                <RouterProvider router={router} />
            </AuthProvider>
        </Provider>
    );
}

export default App;
