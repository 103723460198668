import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReportsFiltersParams, ReportInfo, ReportType, ReportStatus } from '../services/types';
import { DataTableRowClickEvent, SortOrder } from 'primereact/datatable';
import { getReports } from '../services';
import { format } from 'date-fns';
import { store } from '@store/store';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';

type UseReportsListFacadeResult = {
  handleCreateClick: () => void;
  reports?: ReportInfo[];
  handleTableRowClick: (e: DataTableRowClickEvent) => void;
  sortField: string;
  sortOrder: SortOrder;
  handleSort: (e: any) => void;
  totalPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setReportsFilters: React.Dispatch<React.SetStateAction<ReportsFiltersParams | undefined>>;
  reportsFilters?: ReportsFiltersParams;
  formatDate: (date: string) => string;
}

export const useReportsListFacade = (): UseReportsListFacadeResult => {
  const [reports, setReports] = useState<ReportInfo[]>();
  const [sortField, setSortField] = useState<string>('id');
  const [sortOrder, setSortOrder] = useState<SortOrder>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [reportsFilters, setReportsFilters] = useState<ReportsFiltersParams>();

  const navigate = useNavigate();

  const handleCreateClick = () => {
    navigate('/reports/create');
  };

  const handleTableRowClick = (e: DataTableRowClickEvent) => {
    navigate(`/reports/${e.data.id}`);
  };

  const handleSort = (e: any) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };
  
  const fetchReports = async () => {
    try {
      store.dispatch(showLoader());
      const response = await getReports({
        ...reportsFilters,
        pageable: { 
          pageNumber: currentPage, 
          pageSize: 25,
          sortField: sortField,
          sortOrder: sortOrder === 1 ? 'desc' : 'asc',
        },
      });
      setReports(response.data.data.rows);
      setTotalPages(response.data.data.totalPages);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      store.dispatch(hideLoader());
    }
  };

  useEffect(() => {
    fetchReports();
  }, [reportsFilters, currentPage, sortField, sortOrder]);

  const formatDate = (date: string) => format(new Date(date), 'dd.MM.yyyy HH:mm');

  return {
    handleCreateClick,
    reports,
    handleTableRowClick,
    sortField,
    sortOrder,
    handleSort,
    totalPages,
    currentPage,
    setCurrentPage,
    setReportsFilters,
    reportsFilters,
    formatDate,
  };
}
