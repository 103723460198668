import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    type Control,
    useForm,
    type FieldValues,
    type UseFormSetValue,
    type UseFormWatch,
    type UseFormTrigger,
    type UseFormHandleSubmit,
} from 'react-hook-form';
import { type Discount } from '@libs/types';
import { createDiscount, getDiscountById, updateDiscountById } from '@modules/AdminPage/services';
import { formatISO, parseISO } from 'date-fns';
import { convertDateToMoscowDateString } from '@libs/utils';
import { addCreationSuccessToast, addErrorToast, addToast, addUpdateSuccessToast } from '@store/store/slices/toast.slice';
import { useDispatch } from 'react-redux';

type UseDiscountPageFacadeProps = {
    isEdit: boolean;
};

type UseDiscountPageFacadeResult = {
    control: Control<FieldValues, any>;
    setValue: UseFormSetValue<FieldValues>;
    watch: UseFormWatch<FieldValues>;
    trigger: UseFormTrigger<FieldValues>;
    isDirty: boolean;
    handleSubmit: UseFormHandleSubmit<FieldValues, undefined>;
    isValid: boolean;
    onSubmit: (data: any) => void;
    errorMessage: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
};

export const useDiscountPageFacade = (props: UseDiscountPageFacadeProps): UseDiscountPageFacadeResult => {
    const { isEdit } = props;

    const {
        control,
        watch,
        reset,
        setValue,
        trigger,
        handleSubmit,
        formState: { isDirty, isValid },
    } = useForm({
        mode: 'onChange',
    });
    const dispatch = useDispatch();

    const { discountId, tariffPlanId } = useParams();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    const onSubmit = async (data: Discount) => {
        try {
            const dateStart = convertDateToMoscowDateString(new Date(formatISO(new Date(data.dateStart))));
            const dateEnd = convertDateToMoscowDateString(new Date(formatISO(new Date(data.dateEnd))));

            if (!isEdit && tariffPlanId) {
                await createDiscount({
                    ...data,
                    tariffPlanId: Number(tariffPlanId),
                    dateStart,
                    dateEnd,
                }).then((res) => {
                    if (res.data.data) {
                        navigate(`/discounts/discount/${res.data.data?.id}`);

                        reset({
                            ...data,
                            id: res.data.data?.id,
                        });
                    } else {
                        setErrorMessage(res.data.status.errorMessage);
                    }
                });
                dispatch(addCreationSuccessToast());
            } else {
                if (discountId) {
                    await updateDiscountById({ ...data, dateStart, dateEnd }).then((res) => {
                        if (res.data.status.errorMessage) setErrorMessage(res.data.status.errorMessage);
                    });
                    dispatch(addUpdateSuccessToast());
                }
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch(addErrorToast((error as { message: string }).message));
        }
    };

    useEffect(() => {
        const fetchDiscount = async () => {
            try {
                if (discountId && isEdit) {
                    const data = await getDiscountById(discountId);

                    reset({
                        ...data.data,
                        id: discountId,
                        dateStart: data.data.dateStart ? parseISO(data.data.dateStart) : null,
                        dateEnd: data.data.dateEnd ? parseISO(data.data.dateEnd) : null,
                    });
                }
            } catch (error) {
                console.log('error: ', error);
            }
        };

        fetchDiscount();
    }, []);

    return {
        control,
        setValue,
        watch,
        onSubmit,
        handleSubmit,
        trigger,
        isDirty,
        isValid,
        errorMessage,
        setErrorMessage,
    };
};
