import classNames from 'classnames';
import { AutoComplete, type AutoCompleteChangeEvent, type AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { useEffect, useRef, useState } from 'react';
import { getUsers } from '@modules/AdminPage/Subscriptions/services';

import styles from './styles.module.scss';

type CustomAutoCompleteProps = {
    value?: SuggestionsType;
    onChange?: (value: SuggestionsType) => void;
    disabled?: boolean;
    displayField?: 'firstName' | 'fullName';
};

type SuggestionsType = {
    name: string;
    code: string;
};

const CustomAutoComplete = (props: CustomAutoCompleteProps) => {
    const { value, onChange, disabled, displayField = 'fullName' } = props;

    const [suggestions, setSuggestions] = useState<SuggestionsType[]>([]);
    const [inputValue, setInputValue] = useState<string>(value?.name || '');

    const inputRef = useRef<HTMLInputElement>(null);

    const fetchUsers = async (name: string) => {
        try {
            await getUsers({ fullName: name, pageable: {} }).then((res) => {
                setSuggestions(
                    res.data.rows.map((el) => {
                        return {
                            name: `${el[displayField]} (${el.customerId})`,
                            code: el.customerId,
                        };
                    }),
                );

                if (!res.data.rows.length) {
                    onChange?.({ name, code: name });
                }
            });
        } catch (error) {
            console.log('error: ', error);
        }
    };

    useEffect(() => {
        if (value) {
            setInputValue(value?.name);
            inputRef.current?.focus();
        }
    }, [value]);

    const search = (event: AutoCompleteCompleteEvent) => {
        if (event.query.length >= 3) {
            setTimeout(() => {
                fetchUsers(event.query);
            }, 250);
        }
    };

    const handleChange = (event: AutoCompleteChangeEvent) => {
        setInputValue(event.value);

        if (event.originalEvent?.type === 'click' || event.value === '') {
            onChange?.(event.value);
        }
    };

    return (
        <div className={classNames(styles.complete)}>
            <AutoComplete
                suggestions={suggestions}
                value={inputValue}
                onChange={handleChange}
                placeholder={'Выберите пользователя'}
                completeMethod={search}
                pt={{ root: { className: styles.root } }}
                field="name"
                disabled={disabled}
                inputRef={inputRef}
            />
        </div>
    );
};

export default CustomAutoComplete;
