import classNames from 'classnames';
import { PaginationController } from '@libs/components';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { tableColumns } from './constants';
import styles from './styles.module.scss';
import { useRefundsListFacade } from './useRefundsListFacade';

export const RefundsList = () => {
    const { refunds, handleTableRowClick, totalPages, currentPage, setCurrentPage, sortField, sortOrder, handleSort } =
        useRefundsListFacade();

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>Возвраты</span>
                </div>
                <DataTable
                    value={refunds}
                    rows={25}
                    className={classNames(styles.table)}
                    onRowClick={handleTableRowClick}
                    emptyMessage={'Возвраты отсутствуют'}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={handleSort}
                >
                    {tableColumns.map((column) => (
                        <Column
                            key={column.field}
                            field={column.field}
                            header={column.header}
                            alignHeader="center"
                            className={classNames('text-center')}
                            sortable
                        />
                    ))}
                </DataTable>

                <div className={classNames('flex', 'justify-content-center')}>
                    <PaginationController
                        total={totalPages ?? 0}
                        current={currentPage}
                        setAsyncCurrent={(e) => setCurrentPage(e)}
                    />
                </div>
            </div>
        </div>
    );
};
