import { createSlice } from '@reduxjs/toolkit';
import {
    avatarDownloadThunk,
    avatarRemoveThunk,
    avatarUploadThunk,
    editUserThunk,
    getAdminPermissionsThunk,
    getUserThunk,
} from '../thunk';
import { UserProfileType } from '@libs/types';

export type IState = {
    user?: UserProfileType;
    userAvatar?: string;
    isLoading: boolean;
    avatarError: any;
    error: any;
    isAvatarLoadSuccess: boolean;
    permissions: string[];
};

export const initialState: IState = {
    user: undefined,
    isLoading: true,
    avatarError: '',
    userAvatar: '',
    error: '',
    isAvatarLoadSuccess: false,
    permissions: [],
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetData(state) {
            state.avatarError = '';
            state.isAvatarLoadSuccess = false;
        },
        stopLoading(state) {
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(avatarUploadThunk.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(avatarUploadThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = { ...(state.user as UserProfileType), photoUrl: action.payload?.uploadData };
                state.userAvatar = action.payload?.imageStr as string;
                state.isAvatarLoadSuccess = true;
            })
            .addCase(avatarUploadThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.avatarError = 'Avatar upload error';
            })

            .addCase(avatarDownloadThunk.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(avatarDownloadThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userAvatar = action.payload as string;
            })
            .addCase(avatarDownloadThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            })

            .addCase(editUserThunk.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(editUserThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload?.data;
            })
            .addCase(editUserThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            })

            .addCase(getUserThunk.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getUserThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload;
            })
            .addCase(getUserThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            })

            .addCase(avatarRemoveThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(avatarRemoveThunk.fulfilled, (state) => {
                state.isLoading = false;
                state.avatarError = '';
                state.isAvatarLoadSuccess = false;
                state.user = { ...(state.user as UserProfileType), photoUrl: undefined };
                state.userAvatar = undefined;
            })
            .addCase(avatarRemoveThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            })
            .addCase(getAdminPermissionsThunk.fulfilled, (state, action) => {
                state.permissions = action.payload ?? [];
                state.isLoading = false;
            })
            .addCase(getAdminPermissionsThunk.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getAdminPermissionsThunk.rejected, (state, action) => {
                state.isLoading = false;
            });
    },
});

export const userReducer = userSlice.reducer;
export const { resetData, stopLoading } = userSlice.actions;
