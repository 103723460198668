import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column, type ColumnBodyOptions } from 'primereact/column';
import { PaginationController } from '@libs/components';
import { Button } from 'primereact/button';
import { format } from 'date-fns';
import { Dropdown } from 'primereact/dropdown';
import { usePermissions } from '@libs/hooks';
import { HasAccess } from '@libs/components/access';

import { tableColumns } from './constants';
import styles from './styles.module.scss';
import { useNotificationsListFacade } from './useNotificationsListFacade';
import { NotificationCategory, NotificationStatus, type NotificationInfo } from '../services/types';

const bodyTemplate = (data: NotificationInfo, options: ColumnBodyOptions) => {
    switch (options.field) {
        case 'publicationDate':
            return data.publicationDate && format(new Date(data.publicationDate), 'dd.MM.yyyy HH:mm');
        case 'archiveDate':
            return data.archiveDate && format(new Date(data.archiveDate), 'dd.MM.yyyy HH:mm');
        default:
            return data[options.field as keyof Omit<NotificationInfo, 'image' | 'targetGroups'>];
    }
};

const statusOptions = Object.values(NotificationStatus).map((el) => {
    return {
        name: el,
        code: el,
    };
});

const categoryOptions = Object.values(NotificationCategory).map((el) => {
    return {
        name: el,
        code: el,
    };
});

export const NotificationsList = () => {
    const {
        handleCreateClick,
        fetchExport,
        notifications,
        handleTableRowClick,
        totalPages,
        currentPage,
        setCurrentPage,
        setNotificationsFilters,
        notificationsFilters,
        sortField,
        sortOrder,
        handleSort,
    } = useNotificationsListFacade();
    const { rootAdminAndMarket } = usePermissions();

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>Уведомления</span>
                    <div className={classNames('flex', 'align-items-center', styles.btnGroup)}>
                        <Dropdown
                            onChange={(e) => setNotificationsFilters((prevState) => ({
                                    ...prevState,
                                    status: e.value,
                                }))}
                            options={statusOptions}
                            optionLabel="name"
                            placeholder="Статус"
                            optionValue="code"
                            value={notificationsFilters?.status}
                            className={classNames(styles.filter)}
                            showClear
                        />
                        <Dropdown
                            onChange={(e) => setNotificationsFilters((prevState) => ({
                                    ...prevState,
                                    category: e.value,
                                }))}
                            options={categoryOptions}
                            optionLabel="name"
                            placeholder="Категория"
                            optionValue="code"
                            value={notificationsFilters?.category}
                            className={classNames(styles.filter)}
                            showClear
                        />
                        <HasAccess permissions={rootAdminAndMarket}>
                            <Button
                                severity="secondary"
                                outlined
                                className={classNames(styles.button)}
                                onClick={handleCreateClick}
                            >
                                Создать
                            </Button>
                        </HasAccess>

                        <Button
                            severity="secondary"
                            outlined
                            className={classNames(styles.button)}
                            onClick={fetchExport}
                        >
                            Экспорт
                        </Button>
                    </div>
                </div>
                <DataTable
                    value={notifications}
                    rows={25}
                    className={classNames(styles.table)}
                    onRowClick={handleTableRowClick}
                    emptyMessage={'Уведомления отсутствуют'}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={handleSort}
                    lazy
                >
                    {tableColumns.map((column) => (
                        <Column
                            key={column.field}
                            field={column.field}
                            header={column.header}
                            alignHeader="center"
                            className={classNames('text-center')}
                            body={bodyTemplate}
                            sortable
                        />
                    ))}
                </DataTable>

                <div className={classNames('flex', 'justify-content-center')}>
                    <PaginationController
                        total={totalPages}
                        current={currentPage}
                        setAsyncCurrent={(e) => setCurrentPage(e)}
                    />
                </div>
            </div>
        </div>
    );
};
