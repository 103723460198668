import classNames from 'classnames';
import { Footer, Header, Navigation } from '@libs/components';

import styles from './styles.module.scss';

export const ErrorPage = () => {
    return (
        <>
            {/* попросили убрать */}
            {/* <Header /> */}
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <div className={classNames('flex', 'flex-column', styles.errorPageWrapper)}>
                        <div className={styles.pageContent}>
                            <h1>
                                <span>404.</span>
                                <br /> СТРАНИЦА
                                <br /> НЕ НАЙДЕНА
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
